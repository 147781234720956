import React from "react";
import {
  checkSubTotalTablesPercentage,
  formatIndianNumber,
  getValueForColumn,
  lightenColor,
} from "../../../services/reusableCodes";
import { HiMinusSm } from "react-icons/hi";
import { useLocation } from "react-router-dom";

function MyQuotationTable({
  areaTables,
  subTotalTables,
  color,
  selectedFontFamily,
  appliedColumns,
}) {
  const { pathname } = useLocation();

  return (
    <section className="quotation-table">
      {areaTables.map((table, index) => (
        <div className="quotation-editor">
          <div
            className="quotation-editor-section"
            key={index}
            style={{
              margin: "0px",
            }}
          >
            <div
              className="pdf-text-editor-space quotation-editor-table-space"
              style={{
                backgroundColor: color.background_color,
              }}
            >
              <div className="quotation-table-editor">
                <div
                  className="quotation-table-header"
                  style={{
                    background: `linear-gradient(to right,  ${lightenColor(
                      color.table_header,
                      0
                    )}, ${lightenColor(
                      color.table_header,
                      30
                    )} , ${lightenColor(color.table_header, 95)})`,
                    color: color.table_font,
                    fontFamily: selectedFontFamily + ", sans-serif",
                    opacity: index === 0 ? "1" : "0",
                    width: "100%",
                  }}
                >
                  Quotation
                </div>

                <div className="quotation-table-container">
                  <ul className="quotation-table-content">
                    <li className="quotation-table-list">
                      <div className="quotation-editor-table">
                        {/*item , sub-item inputs  */}
                        {table.area.map((areaTable) => (
                          <table
                            className="final-quotation-table-container"
                            key={areaTable.id}
                            style={{ borderColor: color.table_border }}
                          >
                            <thead className="quotation-header">
                              <tr
                                className="quotation-table-row-header"
                                style={{
                                  backgroundColor: color.table_header,
                                  color: color.table_font,
                                  border: "none",
                                }}
                              >
                                <th
                                  style={{
                                    backgroundColor: color.table_header,
                                    color: color.table_font,
                                    borderColor: color.table_border,
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                    textAlign: "start",
                                  }}
                                >
                                  {areaTable.areaValue}
                                </th>
                                <th
                                  className="quotation-finish"
                                  style={{
                                    borderColor: color.table_border,
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                  }}
                                >
                                  Finish
                                </th>
                                <th
                                  className="quotation-type"
                                  style={{
                                    borderColor: color.table_border,
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                  }}
                                >
                                  Type
                                </th>

                                {Array.from(appliedColumns).map(
                                  (column, columnIndex) => (
                                    <th
                                      className={`quotation-height ${
                                        column === "Rate" || column === "Area"
                                          ? "quotation-area"
                                          : ""
                                      }`}
                                      style={{
                                        borderColor: color.table_border,
                                        fontFamily:
                                          selectedFontFamily + ", sans-serif",
                                      }}
                                      key={columnIndex}
                                    >
                                      {column}
                                    </th>
                                  )
                                )}

                                <th
                                  className="quotation-amount"
                                  style={{
                                    borderColor: color.table_border,
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                  }}
                                >
                                  Amount
                                </th>
                              </tr>
                            </thead>

                            {/* body  */}
                            {areaTable.items.map((itemTable) => (
                              <React.Fragment key={itemTable.id}>
                                <tbody
                                  className="quotation-body"
                                  style={{
                                    border: "none",
                                  }}
                                >
                                  <tr
                                    className="quotation-items"
                                    style={{ borderColor: color.table_border }}
                                  >
                                    <td
                                      colSpan={
                                        4 + Array.from(appliedColumns).length
                                      }
                                    >
                                      <p
                                        className="quotation-type"
                                        style={{
                                          fontFamily:
                                            selectedFontFamily + ", sans-serif",
                                        }}
                                      >
                                        {itemTable.itemValue
                                          ? itemTable.itemValue
                                          : "Item"}
                                      </p>

                                      <p
                                        className="quotation-description"
                                        style={{
                                          fontFamily:
                                            selectedFontFamily + ", sans-serif",
                                        }}
                                      >
                                        {itemTable.descriptionValue
                                          ? itemTable.descriptionValue
                                          : "description"}
                                      </p>
                                    </td>
                                  </tr>

                                  {itemTable.subItems.map(
                                    (subItemTable, subIndex) => (
                                      <React.Fragment key={subItemTable.id}>
                                        <tr
                                          className="quotation-subItem-container quotation-subItem-font-container"
                                          style={{
                                            borderColor: color.table_border,
                                          }}
                                        >
                                          <td
                                            className="quotation-subItem"
                                            style={{
                                              borderColor: color.table_border,
                                              textAlign: "left",
                                              fontFamily:
                                                selectedFontFamily +
                                                ", sans-serif",
                                            }}
                                          >
                                            {subItemTable.subItemValue
                                              ? subItemTable.subItemValue
                                              : "sub-item"}
                                          </td>
                                          <td
                                            className="quotation-finish"
                                            style={{
                                              cursor: "pointer",
                                              borderColor: color.table_border,
                                              fontFamily:
                                                selectedFontFamily +
                                                ", sans-serif",
                                              textAlign: "left",
                                            }}
                                          >
                                            {subItemTable.finish}
                                          </td>
                                          <td
                                            className="quotation-type"
                                            style={{
                                              cursor: "pointer",
                                              borderColor: color.table_border,
                                              fontFamily:
                                                selectedFontFamily +
                                                ", sans-serif",
                                              textAlign: "left",
                                            }}
                                          >
                                            {subItemTable.type}
                                          </td>
                                          {Array.from(appliedColumns).map(
                                            (column, columnIndex) => (
                                              <td
                                                className={`quotation-height ${
                                                  column === "Rate" ||
                                                  column === "Area"
                                                    ? "quotation-area"
                                                    : ""
                                                }`}
                                                style={{
                                                  borderColor:
                                                    color.table_border,
                                                }}
                                                key={columnIndex}
                                              >
                                                {((column === "Height" ||
                                                  column === "Width") &&
                                                  subItemTable.unit !== "HW" &&
                                                  (column === "Height"
                                                    ? subItemTable.unit !== "H"
                                                    : subItemTable.unit !==
                                                      "W")) ||
                                                subItemTable.unit === "LSUM" ||
                                                (column === "Area" &&  (subItemTable.widthUnit === "RFT" || subItemTable.widthUnit === "QTY" ))  ? (
                                                  <HiMinusSm
                                                    style={{
                                                      marginTop: "2px",
                                                      marginBottom: "-2px",
                                                    }}
                                                  />
                                                ) : (
                                                  <p
                                                    style={{
                                                      fontFamily:
                                                        selectedFontFamily +
                                                        ", sans-serif",
                                                    }}
                                                  >
                                                    {getValueForColumn(
                                                      column,
                                                      subItemTable
                                                    )}
                                                  </p>
                                                )}
                                              </td>
                                            )
                                          )}

                                          {!color.itemAmountRow ? (
                                            subIndex === 0 && (
                                              <td
                                                className="final-quotation-amount quotation-amount-number"
                                                style={{
                                                  borderTopColor:
                                                    color.table_border,
                                                  borderRight: "none",
                                                  fontFamily:
                                                    selectedFontFamily +
                                                    ", sans-serif",
                                                  backgroundColor:
                                                    color.table_amount,
                                                  color: color.amount_font,
                                                }}
                                                rowSpan={subIndex * 2}
                                              >
                                                <p
                                                  style={{
                                                    fontFamily:
                                                      selectedFontFamily +
                                                      ", sans-serif",
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                      color.table_amount,
                                                    color: color.amount_font,
                                                    padding: "0",
                                                    borderColor:
                                                      color.table_border,
                                                  }}
                                                >
                                                  {formatIndianNumber(
                                                    itemTable.amount
                                                  )
                                                    ? "₹ " +
                                                      formatIndianNumber(
                                                        itemTable.amount
                                                      )
                                                    : ""}
                                                </p>
                                              </td>
                                            )
                                          ) : (
                                            <td
                                              className="final-quotation-amount quotation-amount-number"
                                              style={{
                                                borderColor: color.table_border,
                                                fontFamily:
                                                  selectedFontFamily +
                                                  ", sans-serif",
                                                backgroundColor:
                                                  color.table_amount,
                                                color: color.amount_font,
                                                borderRight: `1.5px solid ${color.table_border}`,
                                                borderTop: `1px solid ${color.table_border}`,
                                                borderBottom:
                                                  subIndex ===
                                                  itemTable.subItems.length - 1
                                                    ? `1.5px solid ${color.table_border}`
                                                    : "none",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  fontFamily:
                                                    selectedFontFamily +
                                                    ", sans-serif",
                                                  cursor: "pointer",
                                                  backgroundColor:
                                                    color.table_amount,
                                                  color: color.amount_font,
                                                  padding: "0",
                                                  borderColor:
                                                    color.table_border,
                                                }}
                                              >
                                                {formatIndianNumber(
                                                  subItemTable.amount
                                                )
                                                  ? "₹ " +
                                                    formatIndianNumber(
                                                      subItemTable.amount
                                                    )
                                                  : ""}
                                              </p>
                                            </td>
                                          )}
                                        </tr>
                                      </React.Fragment>
                                    )
                                  )}
                                </tbody>
                              </React.Fragment>
                            ))}
                          </table>
                        ))}

                        {index === areaTables.length - 1 && (
                          <React.Fragment>
                            {/* sub total  */}
                            <table
                              style={{
                                border: `1.5px solid ${color.table_border}`,
                              }}
                              className="quotation-table-container quotation-total-footer"
                            >
                              <tfoot
                                className="quotation-total-container "
                                style={{
                                  border: "none",
                                  backgroundColor: "#fff",
                                }}
                              >
                                {(checkSubTotalTablesPercentage(
                                  subTotalTables
                                ) ||
                                  subTotalTables.gst.percentage) && (
                                  <tr
                                    className="quotation-subtotal unedit-subtotal"
                                    style={{
                                      backgroundColor: color.table_header,

                                      color: color.table_font,
                                    }}
                                  >
                                    <td
                                      colSpan={5}
                                      style={{
                                        fontFamily:
                                          selectedFontFamily + ", sans-serif",
                                        padding: "6px",
                                      }}
                                    >
                                      {`Subtotal ${
                                        pathname.startsWith(
                                          "/download-quotation"
                                        )
                                          ? "(inc. of GST)"
                                          : ""
                                      }`}
                                    </td>
                                    <td
                                      style={{
                                        fontFamily:
                                          selectedFontFamily + ", sans-serif",
                                      }}
                                    >
                                      ₹{" "}
                                      {formatIndianNumber(
                                        subTotalTables.subTotal
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {subTotalTables.add.map((item, index) => (
                                  <React.Fragment key={item.id}>
                                    {item.percentage && (
                                      <tr
                                        className="final-quotation-designer-fee"
                                        style={{
                                          border: "none",
                                        }}
                                      >
                                        <td
                                          colSpan={4}
                                          style={{
                                            fontFamily:
                                              selectedFontFamily +
                                              ", sans-serif",
                                          }}
                                          className="quotation-designer-fee-text"
                                        >
                                          <span
                                            style={{
                                              fontFamily:
                                                selectedFontFamily +
                                                ", sans-serif",
                                              opacity: index === 0 ? "1" : "0",
                                            }}
                                          >
                                            Add
                                          </span>

                                          <span
                                            className="subtotal-fees"
                                            style={{
                                              fontFamily:
                                                selectedFontFamily +
                                                ", sans-serif",
                                            }}
                                          >
                                            {item.name}
                                          </span>
                                        </td>

                                        <td className="quotation-designer-percentage">
                                          <p
                                            style={{
                                              fontFamily:
                                                selectedFontFamily +
                                                ", sans-serif",
                                            }}
                                          >
                                            {item.percentage
                                              ? item.percentage + "%"
                                              : 0}
                                          </p>
                                        </td>
                                        <td
                                          style={{
                                            fontFamily:
                                              selectedFontFamily +
                                              ", sans-serif",
                                          }}
                                        >
                                          ₹{" "}
                                          {item.amount
                                            ? formatIndianNumber(item.amount)
                                            : "0"}
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))}

                                {subTotalTables.minus.map((item, index) => (
                                  <React.Fragment key={item.id}>
                                    {item.percentage && (
                                      <tr
                                        className="final-quotation-designer-fee"
                                        style={{
                                          border: "none",
                                        }}
                                      >
                                        <td
                                          colSpan={4}
                                          className="quotation-designer-fee-text"
                                        >
                                          <span
                                            style={{
                                              fontFamily:
                                                selectedFontFamily +
                                                ", sans-serif",
                                              opacity: index === 0 ? "1" : "0",
                                            }}
                                          >
                                            Minus
                                          </span>

                                          <span
                                            className="subtotal-fees"
                                            style={{
                                              fontFamily:
                                                selectedFontFamily +
                                                ", sans-serif",
                                            }}
                                          >
                                            {item.name}
                                          </span>
                                        </td>
                                        <td className="quotation-designer-percentage">
                                          <p
                                            style={{
                                              fontFamily:
                                                selectedFontFamily +
                                                ", sans-serif",
                                            }}
                                          >
                                            {item.percentage
                                              ? item.percentage + "%"
                                              : 0}
                                          </p>
                                        </td>
                                        <td
                                          style={{
                                            fontFamily: `${selectedFontFamily}, sans-serif`,
                                          }}
                                        >
                                          ₹{" "}
                                          {item.amount
                                            ? formatIndianNumber(item.amount)
                                            : "0"}
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))}

                                {/* taxable amount */}

                                {subTotalTables.gst.percentage && (
                                  <React.Fragment>
                                    {checkSubTotalTablesPercentage(
                                      subTotalTables
                                    ) && (
                                      <tr
                                        className="quotation-subtotal quotation-taxable-amount unedit-subtotal"
                                        style={{
                                          backgroundColor: color.table_header,
                                          color: color.table_font,
                                        }}
                                      >
                                        <td
                                          colSpan={5}
                                          style={{
                                            fontFamily:
                                              selectedFontFamily +
                                              ", sans-serif",
                                            padding: "6px",
                                          }}
                                        >
                                          Taxable Amount
                                        </td>
                                        <td
                                          style={{
                                            fontFamily:
                                              selectedFontFamily +
                                              ", sans-serif",
                                          }}
                                        >
                                          ₹{" "}
                                          {formatIndianNumber(
                                            subTotalTables.taxableAmount
                                          )}
                                        </td>
                                      </tr>
                                    )}

                                    <tr
                                      className="quotation-designer-fee quotation-gst"
                                      style={{
                                        borderColor:
                                          subTotalTables.gst
                                            .percentagetable_border,
                                      }}
                                    >
                                      <td
                                        colSpan={4}
                                        style={{
                                          fontFamily:
                                            selectedFontFamily + ", sans-serif",
                                          width: "70%",
                                        }}
                                        className="quotation-designer-fee-text"
                                      >
                                        <p
                                          style={{
                                            fontFamily:
                                              selectedFontFamily +
                                              ", sans-serif",
                                          }}
                                        >
                                          {subTotalTables.gst.name || "GST"}
                                        </p>
                                      </td>

                                      <td className="quotation-designer-percentage">
                                        <p>
                                          {subTotalTables.gst.percentage + "%"}
                                        </p>
                                      </td>
                                      <td
                                        style={{
                                          fontFamily:
                                            selectedFontFamily + ", sans-serif",
                                        }}
                                      >
                                        ₹{" "}
                                        {formatIndianNumber(
                                          subTotalTables.gst.amount
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )}

                                {/* total amount  */}
                                <tr
                                  className="quotation-subtotal unedit-subtotal"
                                  style={{
                                    backgroundColor: color.table_header,
                                    color: color.table_font,
                                  }}
                                >
                                  <td
                                    colSpan={5}
                                    style={{
                                      fontFamily:
                                        selectedFontFamily + ", sans-serif",
                                      padding: "6px",
                                    }}
                                  >
                                    {`Total ${
                                      !pathname.startsWith(
                                        "/download-quotation"
                                      )
                                        ? subTotalTables.gst.percentage
                                          ? "(inc. of GST)"
                                          : "(excl. of GST)"
                                        : "(inc. of GST)"
                                    }`}
                                  </td>
                                  <td
                                    style={{
                                      fontFamily:
                                        selectedFontFamily + ", sans-serif",
                                      width:
                                        !(
                                          checkSubTotalTablesPercentage(
                                            subTotalTables
                                          ) || subTotalTables.gst.percentage
                                        ) && "100px",
                                    }}
                                  >
                                    ₹ {formatIndianNumber(subTotalTables.total)}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </React.Fragment>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="block-to-type"></div>
        </div>
      ))}
    </section>
  );
}

export default MyQuotationTable;
