import React, { useState, useEffect, useRef } from "react";
import "./quotation_table.css";
import { IoIosAdd } from "react-icons/io";
import { HiOutlineMinusSm } from "react-icons/hi";
import { v4 as uuidv4 } from "uuid";
import {
  formatIndianNumber,
  generateTable,
  getUnappliedColumns,
  getValueForColumn,
  lightenColor,
  minusAmount,
  minusAreaAmount,
  minusItemAmount,
  updateAmount,
  updateAreaAmount,
  updateItemAmount,
  updateSubTotalAmount,
} from "../../services/reusableCodes";
import FinishesTypes from "../finishes_types/finishes-types";
import { HiMinusSm } from "react-icons/hi";
import { useLocation } from "react-router-dom";

function QuotationTable({
  background_color,
  table_header,
  table_font,
  table_border,
  table_amount,
  selectedFontFamily,
  amount_font,
  itemAmountRow,
  areaTables,
  setAreaTables,
  rateTables,
  setRateTables,
  subTotalTables,
  setSubTotalTables,
  appliedColumns,
  finishesData,
  setFinishesData,
  rates,
  setRates,
  userCategories,
}) {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const finishesTypesRef = useRef(null);
  const [containerRefs, setContainerRefs] = useState({});
  const [addGST, setAddGST] = useState(false);
  const [areaRefs, setAreaRefs] = useState({});
  const [itemRefs, setItemRefs] = useState({});
  const [subItemRefs, setSubItemRefs] = useState({});
  const subTotalRefs = useRef();
  const { pathname } = useLocation();
  const [hoveredType, setHoveredType] = useState(null);

  useEffect(() => {
    const newContainerRefs = {};
    const newAreaRefs = {};
    const newItemRefs = {};
    const newSubItemRefs = {};

    // Iterate through areaTables to set areaRefs for subItems
    areaTables.forEach((table) => {
      table.area.forEach((area) => {
        area.items.forEach((item) => {
          item.subItems.forEach((subItem) => {
            newAreaRefs[subItem.id] = React.createRef();
            newItemRefs[item.id] = React.createRef();
            newSubItemRefs[subItem.id] = React.createRef();
          });
        });
      });
    });

    // Iterate through areaTables and rateTables to set containerRefs for tables and areaRefs for areas
    [...areaTables, ...rateTables].forEach((table) => {
      newContainerRefs[table.id] = React.createRef();
      table.area.forEach((area) => {
        newAreaRefs[area.id] = React.createRef();
        area.items.forEach((item) => {
          newItemRefs[item.id] = React.createRef();
          item.subItems.forEach((subItem) => {
            newSubItemRefs[subItem.id] = React.createRef();
          });
        });
      });
    });

    if (subTotalTables.gst.percentage) {
      setAddGST(true);
    }

    // Set the state for all refs
    setContainerRefs(newContainerRefs);
    setAreaRefs(newAreaRefs);
    setItemRefs(newItemRefs);
    setSubItemRefs(newSubItemRefs);
  }, [areaTables, rateTables]);

  const canAddTable = (tableID) => {
    // subtotal = 122

    // Heights of different elements
    const areaTableHeight = 130;
    // const itemHeight = 78;
    // const subItemHeight = 24;

    // Get the container reference
    const container = containerRefs[tableID]?.current;
    if (!container) {
      return false;
    }

    // Calculate the total container height including additional elements and padding
    const containerHeight = container.offsetHeight;

    // Maximum allowed height
    const maxHeight = 280 * 3.77953 + areaTableHeight;

    // Calculate the remaining height
    const remainingHeight = maxHeight - containerHeight;

    if (containerHeight < maxHeight) {
      return remainingHeight;
    }

    return false;
  };

  const tableHeight = (tableName, id) => {
    let container = null;

    // Determine which refs to use based on tableName
    switch (tableName) {
      case "area":
        container = areaRefs[id]?.current;
        break;
      case "item":
        container = itemRefs[id]?.current;
        break;
      case "subItem":
        container = subItemRefs[id]?.current;
        break;
      case "subTotal":
        container = subTotalRefs?.current; // Assuming subTotalRefs is the ref for the subTotal section
        break;
      default:
        return false;
    }

    if (!container) {
      return false;
    }

    const height = container.offsetHeight;

    // Return the height
    return height;
  };

  //to add new table page
  const addTable = () => {
    const newTable = generateTable();
    newTable.area = [];
    setAreaTables((prevAreaTables) => [...prevAreaTables, newTable]);
    setRateTables((prevRateTables) => [...prevRateTables, newTable]);
  };

  const moveLastAreaToNextTable = (
    tables,
    currentTableId,
    initialRemainingHeight
  ) => {
    let currentIndex = tables.findIndex((table) => table.id === currentTableId);
    let currentRemainingHeight = initialRemainingHeight;

    while (currentIndex < tables.length - 1) {
      const currentTable = tables[currentIndex];
      const nextTable = tables[currentIndex + 1];
      const lastArea = currentTable.area[currentTable.area.length - 1];

      if (!lastArea) break; // If there are no areas to move, exit loop

      const lastAreaHeight = tableHeight("area", lastArea.id || null);

      if (currentRemainingHeight < 200 && currentTable.area.length > 0) {
        // Move last area of the current table to the next table
        currentTable.area.pop(); // Remove the last area from the current table
        nextTable.area.unshift(lastArea); // Add the last area to the beginning of the next table's areas

        currentRemainingHeight = canAddTable(currentTable.id); // Update remaining height for the current table
        currentIndex++; // Move to the next table
      } else {
        break; // If the condition is not met, exit the loop
      }
    }

    return { tables, currentRemainingHeight };
  };

  const addAreaTable = (tableID, areaTableId) => {
    const remainingHeight = canAddTable(tableID);

    if (typeof remainingHeight !== "number" || remainingHeight === false) {
      return;
    }

    const tableIndex = areaTables.findIndex((table) => table.id === tableID);

    if (tableIndex === areaTables.length - 1 && remainingHeight < 282) {
      const newAreaID1 = uuidv4();
      const newItemId1 = uuidv4();
      const newSubItemId1 = uuidv4();

      const addTwoAreas = (tables) =>
        tables.map((table) => {
          if (table.id === tableID) {
            return {
              ...table,
              area: [
                ...table.area,
                {
                  id: newAreaID1,
                  items: [
                    {
                      id: newItemId1,
                      subItems: [{ id: newSubItemId1 }],
                    },
                  ],
                },
              ],
            };
          }
          return table;
        });

      setAreaTables(addTwoAreas(areaTables));
      setRateTables(addTwoAreas(rateTables));
      addTable();

      return;
    }

    const nextTableId =
      tableIndex < areaTables.length - 1 ? areaTables[tableIndex + 1].id : null;
    const newAreaID = uuidv4();
    const newItemId = uuidv4();
    const newSubItemId = uuidv4();

    if (nextTableId) {
      const nextTable = areaTables.find((table) => table.id === nextTableId);

      if (nextTable.area.length === 0) {
        const updatedTables = areaTables.map((table) => {
          if (table.id === nextTableId) {
            return {
              ...table,
              area: [
                {
                  id: newAreaID,
                  items: [
                    {
                      id: newItemId,
                      subItems: [{ id: newSubItemId }],
                    },
                  ],
                },
                ...table.area,
              ],
            };
          }
          return table;
        });

        setAreaTables(updatedTables);
        setRateTables(updatedTables);
      }
    }

    if (remainingHeight < 200) {
      const addNewAreaBelow = (tables) =>
        tables.map((table) => {
          if (table.id === tableID) {
            const areaIndex = table.area.findIndex(
              (area) => area.id === areaTableId
            );
            if (areaIndex !== -1) {
              const newArea = {
                id: newAreaID,
                items: [
                  {
                    id: newItemId,
                    subItems: [{ id: newSubItemId }],
                  },
                ],
              };
              return {
                ...table,
                area: [
                  ...table.area.slice(0, areaIndex + 1),
                  newArea,
                  ...table.area.slice(areaIndex + 1),
                ],
              };
            }
          }
          return table;
        });

      const { tables: updatedTablesWithMovedArea, currentRemainingHeight } =
        moveLastAreaToNextTable(
          addNewAreaBelow(areaTables),
          tableID,
          remainingHeight
        );

      // Check if any table's remaining height is less than 282 and create new tables if necessary
      const lastTable =
        updatedTablesWithMovedArea[updatedTablesWithMovedArea.length - 1];
      const lastTableRemainingHeight = canAddTable(lastTable.id);

      if (lastTableRemainingHeight < 200) {
        const newTable = generateTable();
        newTable.area = [];
        // Update tables state
        setAreaTables([...updatedTablesWithMovedArea, newTable]);
        setRateTables([...updatedTablesWithMovedArea, newTable]);
      } else {
        setAreaTables(updatedTablesWithMovedArea);
        setRateTables(updatedTablesWithMovedArea);
      }

      return;
    }

    const addItem = (tables) =>
      tables.map((table) => {
        if (table.id === tableID) {
          const areaIndex = table.area.findIndex(
            (area) => area.id === areaTableId
          );
          if (areaIndex !== -1) {
            return {
              ...table,
              area: [
                ...table.area.slice(0, areaIndex + 1),
                {
                  id: newAreaID,
                  items: [
                    {
                      id: newItemId,
                      subItems: [{ id: newSubItemId }],
                    },
                  ],
                },
                ...table.area.slice(areaIndex + 1),
              ],
            };
          }
        }
        return table;
      });

    setAreaTables(addItem(areaTables));
    setRateTables(addItem(rateTables));
  };

  // to remove area table
  const moveFirstAreaToCurrentTable = (
    tables,
    currentTableId,
    initialRemainingHeight,
    areaTableId
  ) => {
    let currentIndex = tables.findIndex((table) => table.id === currentTableId);
    let currentRemainingHeight = initialRemainingHeight;
    let areasToMove = [];

    while (currentIndex < tables.length - 1) {
      const currentTable = tables[currentIndex];
      const nextTable = tables[currentIndex + 1];
      const nextTableFirstAreaHeight = tableHeight(
        "area",
        nextTable.area[0]?.id || null
      );

      if (
        nextTable.area.length > 0 &&
        nextTableFirstAreaHeight < currentRemainingHeight + 50
      ) {
        // Calculate the total height of areas that can be moved
        let totalAreaHeight = nextTableFirstAreaHeight;
        areasToMove = [nextTable.area[0]]; // Start with the first area

        for (let i = 1; i < nextTable.area.length; i++) {
          if (
            totalAreaHeight + tableHeight("area", nextTable.area[i].id) + 100 <
            currentRemainingHeight
          ) {
            areasToMove.push(nextTable.area[i]);
            totalAreaHeight += tableHeight("area", nextTable.area[i].id);
          } else {
            break;
          }
        }

        // Move the areas from the next table to the current table
        currentTable.area.push(...areasToMove);
        currentRemainingHeight = canAddTable(nextTable.id);

        // Remove the moved areas from the next table
        tables[currentIndex + 1].area = nextTable.area.slice(
          areasToMove.length
        );

        // Update the tables array
        tables[currentIndex] = currentTable;

        // Exit the loop if no more areas can be moved
        if (areasToMove.length === 0) {
          break;
        }
      } else {
        break;
      }

      currentIndex++;
    }

    // Check if the last table is empty and remove it if necessary
    const lastTable = tables[tables.length - 1];
    if (lastTable.area.length === 0) {
      let movingAreaSum = 0;

      // Calculate the total height of areas to move
      areasToMove.forEach((area) => {
        movingAreaSum += tableHeight("area", area.id);
      });
      const removingAreaHeight = tableHeight("area", areaTableId);
      const previousTable = tables[tables.length - 2];

      let prevRemainingHeight =
        canAddTable(previousTable.id) + removingAreaHeight - movingAreaSum;
      const subTotalHeight = tableHeight("subTotal", null);
      if (subTotalHeight + 150 < prevRemainingHeight) {
        tables.splice(tables.length - 1, 1); // Remove the empty last table
      }
    }
    return { tables, currentRemainingHeight };
  };

  const removeAreaTable = (tableId, areaTableId) => {
    const tableHasSingleArea =
      areaTables.length > 0 && areaTables[0].area.length <= 1;

    if (tableHasSingleArea) {
      return;
    }

    const remainingHeight = canAddTable(tableId);
    const { tables: updatedTables, currentRemainingHeight } =
      moveFirstAreaToCurrentTable(
        [...areaTables],
        tableId,
        remainingHeight,
        areaTableId
      );

    const filterAreaTable = (tables) =>
      tables.map((table) => {
        if (table.id === tableId) {
          return {
            ...table,
            area: table.area.filter((area) => area.id !== areaTableId),
          };
        }
        return table;
      });

    setAreaTables(filterAreaTable(updatedTables));
    setRateTables(filterAreaTable(updatedTables));
    minusAreaAmount(setAreaTables, tableId, areaTableId);
    updateSubTotalAmount(setAreaTables, setSubTotalTables, subTotalTables);
  };

  const moveLastAreaToNextTableInItem = (
    tables,
    currentTableId,
    initialRemainingHeight,
    itemTableId
  ) => {
    let currentIndex = tables.findIndex((table) => table.id === currentTableId);
    let currentRemainingHeight = initialRemainingHeight;

    while (currentIndex < tables.length - 1) {
      const currentTable = tables[currentIndex];
      const nextTable = tables[currentIndex + 1];
      const lastArea = currentTable.area[currentTable.area.length - 1];

      if (!lastArea) break; // If there are no areas to move, exit loop
      const lastItem = lastArea.items.pop();
      const lastAreaValue = lastArea.areaValue;

      if (lastArea.items.length === 0) {
        // If the last area has no items left after removing the last item, delete the last area
        currentTable.area.pop();
      }

      if (lastItem) {
        const firstAreaInNextTable = nextTable.area[0];

        if (
          firstAreaInNextTable &&
          lastAreaValue === firstAreaInNextTable.areaValue
        ) {
          // Add last item of the last area in the current table to the last position in the first area of the next table

          nextTable.area[0].items.unshift(lastItem);
        } else {
          // Create a new area in the next table and add the removed item to it
          const newAreaID = uuidv4();
          nextTable.area.unshift({
            id: newAreaID,
            areaValue: lastAreaValue,
            items: [lastItem],
          });
        }
      }

      // Check if the current area has items and remaining height is less than 200
      if (currentTable.area.length > 0 && currentRemainingHeight < 200) {
        currentRemainingHeight = canAddTable(currentTable.id); // Update remaining height for the current table
        currentIndex++; // Move to the next table
      } else {
        break; // If the condition is not met, exit the loop
      }
    }

    return { tables, currentRemainingHeight };
  };

  const addItemTable = (tableId, areaTableId, itemId) => {
    const remainingHeight = canAddTable(tableId);

    if (typeof remainingHeight !== "number" || remainingHeight === false) {
      return;
    }

    const tableIndex = areaTables.findIndex((table) => table.id === tableId);

    if (tableIndex === areaTables.length - 1 && remainingHeight < 180) {
      const newItemId = uuidv4();
      const newSubItemId = uuidv4();

      const addItem = (tables) =>
        tables.map((table) => {
          if (table.id === tableId) {
            return {
              ...table,
              area: table.area.map((area) => {
                if (area.id === areaTableId) {
                  return {
                    ...area,
                    items: [
                      ...area.items,
                      {
                        id: newItemId,
                        subItems: [{ id: newSubItemId }],
                      },
                    ],
                  };
                }
                return area;
              }),
            };
          }
          return table;
        });

      setAreaTables(addItem(areaTables));
      setRateTables(addItem(rateTables));
      addTable();

      return;
    }

    if (remainingHeight < 165) {
      const addNewItemBelow = (tables) =>
        tables.map((table) => {
          if (table.id === tableId) {
            const areaIndex = table.area.findIndex(
              (area) => area.id === areaTableId
            );
            if (areaIndex !== -1) {
              const newItemId = uuidv4();
              const newSubItemId = uuidv4();
              const newItem = {
                id: newItemId,
                subItems: [{ id: newSubItemId }],
              };

              return {
                ...table,
                area: table.area.map((area, idx) => {
                  if (idx === areaIndex) {
                    const itemIndex = area.items.findIndex(
                      (item) => item.id === itemId
                    );
                    if (itemIndex !== -1) {
                      const updatedItems = [
                        ...area.items.slice(0, itemIndex + 1),
                        newItem,
                        ...area.items.slice(itemIndex + 1),
                      ];
                      return {
                        ...area,
                        items: updatedItems,
                      };
                    }
                  }
                  return area;
                }),
              };
            }
          }
          return table;
        });

      const { tables: updatedTablesWithMovedItem, currentRemainingHeight } =
        moveLastAreaToNextTableInItem(
          addNewItemBelow(areaTables),
          tableId,
          remainingHeight,
          itemId
        );

      const lastTable =
        updatedTablesWithMovedItem[updatedTablesWithMovedItem.length - 1];
      const lastTableRemainingHeight = canAddTable(lastTable.id);

      if (lastTableRemainingHeight < 200) {
        const newTable = generateTable();
        newTable.area = [];
        setAreaTables([...updatedTablesWithMovedItem, newTable]);
        setRateTables([...updatedTablesWithMovedItem, newTable]);
      } else {
        setAreaTables(updatedTablesWithMovedItem);
        setRateTables(updatedTablesWithMovedItem);
      }

      return;
    }

    const newItemId = uuidv4();
    const newSubItemId = uuidv4();

    const addItem = (tables) =>
      tables.map((table) => {
        if (table.id === tableId) {
          return {
            ...table,
            area: table.area.map((area) => {
              if (area.id === areaTableId) {
                const itemIndex = area.items.findIndex(
                  (item) => item.id === itemId
                );
                if (itemIndex !== -1) {
                  const updatedItems = [
                    ...area.items.slice(0, itemIndex + 1),
                    {
                      id: newItemId,
                      subItems: [{ id: newSubItemId }],
                    },
                    ...area.items.slice(itemIndex + 1),
                  ];
                  return {
                    ...area,
                    items: updatedItems,
                  };
                }
              }
              return area;
            }),
          };
        }
        return table;
      });

    setAreaTables(addItem(areaTables));
    setRateTables(addItem(rateTables));
  };

  const moveFirstAreaToCurrentTableItem = (
    tables,
    currentTableId,
    initialRemainingHeight,
    itemTableId
  ) => {
    let currentIndex = tables.findIndex((table) => table.id === currentTableId);
    let currentRemainingHeight = initialRemainingHeight;
    let areasToMove = [];

    while (currentIndex < tables.length - 1) {
      const currentTable = tables[currentIndex];
      const nextTable = tables[currentIndex + 1];
      const nextTableFirstAreaHeight = tableHeight(
        "area",
        nextTable.area[0]?.id || null
      );

      if (
        nextTable.area.length > 0 &&
        nextTableFirstAreaHeight + 100 < currentRemainingHeight + 50
      ) {
        // Calculate the total height of areas that can be moved
        let totalAreaHeight = nextTableFirstAreaHeight;
        areasToMove = [nextTable.area[0]]; // Start with the first area

        for (let i = 1; i < nextTable.area.length; i++) {
          if (
            totalAreaHeight + tableHeight("area", nextTable.area[i].id) + 100 <
            currentRemainingHeight
          ) {
            areasToMove.push(nextTable.area[i]);
            totalAreaHeight += tableHeight("area", nextTable.area[i].id);
          } else {
            break;
          }
        }

        // Move the areas from the next table to the current table
        currentTable.area.push(...areasToMove);
        currentRemainingHeight = canAddTable(nextTable.id);

        // Remove the moved areas from the next table
        tables[currentIndex + 1].area = nextTable.area.slice(
          areasToMove.length
        );

        // Update the tables array
        tables[currentIndex] = currentTable;

        // Exit the loop if no more areas can be moved
        if (areasToMove.length === 0) {
          break;
        }
      } else {
        break;
      }

      currentIndex++;
    }

    // Check if the last table is empty and remove it if necessary
    const lastTable = tables[tables.length - 1];
    if (lastTable.area.length === 0) {
      let movingAreaSum = 0;

      // Calculate the total height of areas to move
      areasToMove.forEach((area) => {
        movingAreaSum += tableHeight("area", area.id);
      });

      const removingItemHeight = tableHeight("item", itemTableId);
      const previousTable = tables[tables.length - 2];

      let prevRemainingHeight =
        canAddTable(previousTable.id) + removingItemHeight - movingAreaSum;

      const subTotalHeight = tableHeight("subTotal", null);
      if (subTotalHeight + 150 < prevRemainingHeight) {
        tables.splice(tables.length - 1, 1); // Remove the empty last table
      }
    }
    return { tables, currentRemainingHeight };
  };

  // to remove item table
  const removeItemTable = (tableId, areaTableId, itemTableId) => {
    const areaHasSingleItem = areaTables.some((table) =>
      table.area.some(
        (area) => area.id === areaTableId && area.items.length <= 1
      )
    );

    if (areaHasSingleItem) {
      return;
    }

    // Calculate remaining height for the current table
    const remainingHeight = canAddTable(tableId);

    // Call moveFirstAreaToCurrentTable to potentially move areas to the current table
    const { tables: updatedTables, currentRemainingHeight } =
      moveFirstAreaToCurrentTableItem(
        [...areaTables],
        tableId,
        remainingHeight,
        itemTableId
      );

    // Filter out the removed item from the areaTables and rateTables
    const filterItemTable = (tables) =>
      tables.map((table) => {
        if (table.id === tableId) {
          return {
            ...table,
            area: table.area.map((area) => {
              if (area.id === areaTableId) {
                // Remove the item from the area
                if (area.items.length <= 1) {
                  return area;
                }
                return {
                  ...area,
                  items: area.items.filter((item) => item.id !== itemTableId),
                };
              }
              return area;
            }),
          };
        }
        return table;
      });

    // Update areaTables and rateTables with the filtered tables
    setAreaTables(filterItemTable(updatedTables));
    setRateTables(filterItemTable(updatedTables));

    // Perform other necessary updates
    minusItemAmount(setAreaTables, tableId, areaTableId, itemTableId);
    updateAreaAmount(setAreaTables, tableId, areaTableId);
    updateSubTotalAmount(setAreaTables, setSubTotalTables, subTotalTables);
  };

  const moveLastAreaToNextTableInSubItem = (
    tables,
    currentTableId,
    initialRemainingHeight,
    itemTableId
  ) => {
    let currentIndex = tables.findIndex((table) => table.id === currentTableId);
    let currentRemainingHeight = initialRemainingHeight;

    while (currentIndex < tables.length - 1) {
      const currentTable = tables[currentIndex];
      const nextTable = tables[currentIndex + 1];
      const lastArea = currentTable.area[currentTable.area.length - 1];

      if (!lastArea) break; // If there are no areas to move, exit loop
      const lastItem = lastArea.items.pop();
      const lastAreaValue = lastArea.areaValue;

      if (lastArea.items.length === 0) {
        // If the last area has no items left after removing the last item, delete the last area
        currentTable.area.pop();
      }

      if (lastItem) {
        const firstAreaInNextTable = nextTable.area[0];

        if (
          firstAreaInNextTable &&
          lastAreaValue === firstAreaInNextTable.areaValue
        ) {
          // Add last item of the last area in the current table to the last position in the first area of the next table

          nextTable.area[0].items.unshift(lastItem);
        } else {
          // Create a new area in the next table and add the removed item to it
          const newAreaID = uuidv4();
          nextTable.area.unshift({
            id: newAreaID,
            areaValue: lastAreaValue,
            items: [lastItem],
          });
        }
      }

      // Check if the current area has items and remaining height is less than 200
      if (currentTable.area.length > 0 && currentRemainingHeight < 200) {
        currentRemainingHeight = canAddTable(currentTable.id);
        currentIndex++; // Move to the next table
      } else {
        break;
      }
    }

    return { tables, currentRemainingHeight };
  };

  // to add sub item table
  const addSubItemTables = (tableId, areaTableId, itemTableId, subItemId) => {
    const remainingHeight = canAddTable(tableId);

    if (typeof remainingHeight === "number" && remainingHeight === false) {
      return;
    }

    const tableIndex = areaTables.findIndex((table) => table.id === tableId);
    const nextTableId =
      tableIndex < areaTables.length - 1 ? areaTables[tableIndex + 1].id : null;

    if (tableIndex === areaTables.length - 1 && remainingHeight < 166) {
      const newSubItemId = uuidv4();

      const addSubItem = (tables) =>
        tables.map((table) => {
          if (table.id === tableId) {
            return {
              ...table,
              area: table.area.map((area) => {
                if (area.id === areaTableId) {
                  return {
                    ...area,
                    items: area.items.map((item) => {
                      if (item.id === itemTableId) {
                        return {
                          ...item,
                          subItems: [...item.subItems, { id: newSubItemId }],
                        };
                      }
                      return item;
                    }),
                  };
                }
                return area;
              }),
            };
          }
          return table;
        });

      setAreaTables(addSubItem(areaTables));
      setRateTables(addSubItem(rateTables));
      addTable();

      return;
    }

    if (remainingHeight < 150) {
      const addNewSubItemBelow = (tables) =>
        tables.map((table) => {
          if (table.id === tableId) {
            const areaIndex = table.area.findIndex(
              (area) => area.id === areaTableId
            );
            if (areaIndex !== -1) {
              return {
                ...table,
                area: table.area.map((area, idx) => {
                  if (idx === areaIndex) {
                    return {
                      ...area,
                      items: area.items.map((item) => {
                        if (item.id === itemTableId) {
                          const newSubItemId = uuidv4();
                          return {
                            ...item,
                            subItems: [...item.subItems, { id: newSubItemId }],
                          };
                        }
                        return item;
                      }),
                    };
                  }
                  return area;
                }),
              };
            }
          }
          return table;
        });

      const { tables: updatedTablesWithMovedItem, currentRemainingHeight } =
        moveLastAreaToNextTableInSubItem(
          addNewSubItemBelow(areaTables),
          tableId,
          remainingHeight,
          itemTableId
        );

      const lastTable =
        updatedTablesWithMovedItem[updatedTablesWithMovedItem.length - 1];
      const lastTableRemainingHeight = canAddTable(lastTable.id);

      if (lastTableRemainingHeight < 200) {
        const newTable = generateTable();
        newTable.area = [];
        setAreaTables([...updatedTablesWithMovedItem, newTable]);
        setRateTables([...updatedTablesWithMovedItem, newTable]);
      } else {
        setAreaTables(updatedTablesWithMovedItem);
        setRateTables(updatedTablesWithMovedItem);
      }

      return;
    }

    const newSubItemId = uuidv4();

    const addSubItem = (tables) =>
      tables.map((table) => {
        if (table.id === tableId) {
          return {
            ...table,
            area: table.area.map((area) => {
              if (area.id === areaTableId) {
                return {
                  ...area,
                  items: area.items.map((item) => {
                    if (item.id === itemTableId) {
                      return {
                        ...item,
                        subItems: [...item.subItems, { id: newSubItemId }],
                      };
                    }
                    return item;
                  }),
                };
              }
              return area;
            }),
          };
        }
        return table;
      });

    setAreaTables(addSubItem(areaTables));
    setRateTables(addSubItem(rateTables));
  };

  const moveFirstAreaToCurrentTableSubItem = (
    tables,
    currentTableId,
    initialRemainingHeight,
    itemTableId
  ) => {
    let currentIndex = tables.findIndex((table) => table.id === currentTableId);
    let currentRemainingHeight = initialRemainingHeight;
    let areasToMove = [];

    while (currentIndex < tables.length - 1) {
      const currentTable = tables[currentIndex];
      const nextTable = tables[currentIndex + 1];
      const nextTableFirstAreaHeight = tableHeight(
        "area",
        nextTable.area[0]?.id || null
      );

      if (
        nextTable.area.length > 0 &&
        nextTableFirstAreaHeight + 150 < currentRemainingHeight + 50
      ) {
        // Calculate the total height of areas that can be moved
        let totalAreaHeight = nextTableFirstAreaHeight;
        areasToMove = [nextTable.area[0]]; // Start with the first area

        for (let i = 1; i < nextTable.area.length; i++) {
          if (
            totalAreaHeight + tableHeight("area", nextTable.area[i].id) + 100 <
            currentRemainingHeight
          ) {
            areasToMove.push(nextTable.area[i]);
            totalAreaHeight += tableHeight("area", nextTable.area[i].id);
          } else {
            break;
          }
        }

        // Move the areas from the next table to the current table
        currentTable.area.push(...areasToMove);
        currentRemainingHeight = canAddTable(nextTable.id);

        // Remove the moved areas from the next table
        tables[currentIndex + 1].area = nextTable.area.slice(
          areasToMove.length
        );

        // Update the tables array
        tables[currentIndex] = currentTable;

        // Exit the loop if no more areas can be moved
        if (areasToMove.length === 0) {
          break;
        }
      } else {
        break;
      }

      currentIndex++;
    }

    // Check if the last table is empty and remove it if necessary
    const lastTable = tables[tables.length - 1];
    if (lastTable.area.length === 0) {
      let movingAreaSum = 0;

      // Calculate the total height of areas to move
      areasToMove.forEach((area) => {
        movingAreaSum += tableHeight("area", area.id);
      });

      const removingItemHeight = tableHeight("item", itemTableId);
      const previousTable = tables[tables.length - 2];

      let prevRemainingHeight =
        canAddTable(previousTable.id) + removingItemHeight - movingAreaSum;

      const subTotalHeight = tableHeight("subTotal", null);
      if (subTotalHeight + 150 < prevRemainingHeight) {
        tables.splice(tables.length - 1, 1); // Remove the empty last table
      }
    }
    return { tables, currentRemainingHeight };
  };

  // to remove sub item table
  const removeSubItemTables = (
    tableId,
    areaTableId,
    itemTableId,
    subItemId,
    subIndex
  ) => {
    const itemHasSingleSubItem = areaTables.some((table) =>
      table.area.some((area) =>
        area.items.some(
          (item) => item.id === itemTableId && item.subItems.length <= 1
        )
      )
    );

    if (itemHasSingleSubItem) {
      return;
    }

    const remainingHeight = canAddTable(tableId);

    // Call moveFirstAreaToCurrentTable to potentially move areas to the current table
    const { tables: updatedTables, currentRemainingHeight } =
      moveFirstAreaToCurrentTableSubItem(
        [...areaTables],
        tableId,
        remainingHeight,
        itemTableId
      );

    const filterSubItemTable = (tables) =>
      tables.map((table) => {
        if (table.id === tableId) {
          // Filter out the subitem with the specified ID
          return {
            ...table,
            area: table.area.map((area) => {
              if (area.id === areaTableId) {
                return {
                  ...area,
                  items: area.items.map((item) => {
                    if (item.id === itemTableId) {
                      if (item.subItems.length <= 1) {
                        return item;
                      }
                      return {
                        ...item,
                        subItems: item.subItems.filter(
                          (subItem) => subItem.id !== subItemId
                        ),
                      };
                    }
                    return item;
                  }),
                };
              }
              return area;
            }),
          };
        }
        return table;
      });

    // Update the state by filtering out the specified subitem table
    setAreaTables(filterSubItemTable(updatedTables));
    setRateTables(filterSubItemTable(updatedTables));
    minusAmount(
      setAreaTables,
      tableId,
      areaTableId,
      itemTableId,
      subItemId,
      subIndex
    );
    updateItemAmount(setAreaTables, tableId, areaTableId, itemTableId);
    updateAreaAmount(setAreaTables, tableId, areaTableId);
    updateSubTotalAmount(setAreaTables, setSubTotalTables, subTotalTables);
  };

  //to add sub-total designer row
  const addSubTotalRow = (name) => {
    if (name === "add" && subTotalTables.add.length >= 3) {
      return;
    }

    if (name === "minus" && subTotalTables.minus.length >= 3) {
      return;
    }
    setSubTotalTables((prevState) => {
      const newRow = { id: uuidv4() };
      return {
        ...prevState,
        [name]: [...prevState[name], newRow],
      };
    });
  };

  //to remove sub-total designer row
  const removeSubTotalRow = (name, id) => {
    // if (name === "add" && subTotalTables.add.length <= 1) {
    //   return;
    // }

    // if (name === "minus" && subTotalTables.minus.length <= 1) {
    //   return;
    // }

    setSubTotalTables((prevState) => {
      const updatedRows = prevState[name].filter((row) => row.id !== id);

      const updatedState = {
        ...prevState,
        [name]: updatedRows,
      };

      // Recalculate totals after removing the row
      calculateTotal(setSubTotalTables, updatedState);

      return updatedState;
    });
  };

  const toggleDropdown = (subItemID) => {
    setActiveDropdown(subItemID);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        finishesTypesRef.current &&
        !finishesTypesRef.current.contains(event.target)
      ) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [finishesTypesRef]);

  //to store table values
  const handleAreaTableChange = async (
    tableId,
    areaId,
    itemId,
    subItemId,
    subIndex,
    key,
    value
  ) => {
    if (key === "height" || key === "width") {
      value = value.replace(/[^\d.]/g, "");
    }

    setAreaTables((currentTables) =>
      currentTables.map((table) =>
        table.id === tableId
          ? {
              ...table,
              area: table.area.map((area) =>
                area.id === areaId
                  ? {
                      ...area,
                      areaValue: key === "areaValue" ? value : area.areaValue,
                      items: area.items.map((item) =>
                        item.id === itemId
                          ? {
                              ...item,
                              itemValue:
                                key === "itemValue" ? value : item.itemValue,
                              descriptionValue:
                                key === "descriptionValue"
                                  ? value
                                  : item.descriptionValue,
                              subItems: item.subItems
                                ? item.subItems.map((subItem) =>
                                    subItem.id === subItemId
                                      ? {
                                          ...subItem,
                                          [key]: value,
                                          warning:
                                            key === "subItemValue"
                                              ? subItem.warning
                                              : "",
                                        }
                                      : subItem
                                  )
                                : [], // Check if subItems exists before mapping
                            }
                          : item
                      ),
                    }
                  : area
              ),
            }
          : table
      )
    );

    const correspondingTable = areaTables.find((table) => table.id === tableId);
    if (correspondingTable) {
      const correspondingArea = correspondingTable.area.find(
        (area) => area.id === areaId
      );
      if (correspondingArea) {
        const correspondingItem = correspondingArea.items.find(
          (item) => item.id === itemId
        );
        if (correspondingItem) {
          const correspondingSubItem = correspondingItem.subItems.find(
            (subItem) => subItem.id === subItemId
          );
          if (correspondingSubItem) {
            updateAmount(
              setAreaTables,
              setRateTables,
              tableId,
              areaId,
              itemId,
              subItemId,
              subIndex
            );

            // Update item amount
            updateItemAmount(setAreaTables, tableId, areaId, itemId);
            updateAreaAmount(setAreaTables, tableId, areaId);
            updateSubTotalAmount(
              setAreaTables,
              setSubTotalTables,
              subTotalTables
            );
          }
        }
      }
    }
  };

  const handleRateTableChange = (
    setAreaTables,
    setRateTables,
    setSubTotalTables,
    tableId,
    areaId,
    itemId,
    subItemId,
    subIndex,
    rateValue
  ) => {
    setAreaTables((currentTables) =>
      currentTables.map((table) =>
        table.id === tableId
          ? {
              ...table,
              area: table.area.map((area) =>
                area.id === areaId
                  ? {
                      ...area,
                      items: area.items.map((item) =>
                        item.id === itemId
                          ? {
                              ...item,
                              subItems: item.subItems.map((subItem) =>
                                subItem.id === subItemId
                                  ? {
                                      ...subItem,
                                      rate: rateValue.replace(/[^\d]/g, ""),
                                      updatedRate: "",
                                      warning:
                                        subItem.warning === "item"
                                          ? subItem.warning
                                          : "",
                                    }
                                  : subItem
                              ),
                            }
                          : item
                      ),
                    }
                  : area
              ),
            }
          : table
      )
    );
    updateAmount(
      setAreaTables,
      setRateTables,
      tableId,
      areaId,
      itemId,
      subItemId,
      subIndex
    );
    updateItemAmount(setAreaTables, tableId, areaId, itemId);
    updateAreaAmount(setAreaTables, tableId, areaId);
    calculateTotal(setSubTotalTables, subTotalTables);
    updateSubTotalAmount(setAreaTables, setSubTotalTables, subTotalTables);
  };

  const handleSubtotalChange = (type, id, field, value) => {
    setSubTotalTables((prevState) => {
      let newValue = value;

      if (field === "percentage") {
        newValue = value.replace(/[^\d.]/g, "");
        newValue = newValue < 100 ? newValue : "99";
        // Ensure only one decimal point
        const parts = newValue.split(".");
        if (parts.length > 2) {
          newValue = parts[0] + "." + parts.slice(1).join("");
        }
      }

      if (type === "gst") {
        const updatedState = {
          ...prevState,
          gst: {
            ...prevState.gst,
            [field]: newValue,
          },
        };

        // Calculate total after updating GST fields
        calculateTotal(setSubTotalTables, updatedState);

        return updatedState;
      }

      const newTypeState = prevState[type].map((item) => {
        if (item.id === id) {
          const updatedItem = { ...item, [field]: newValue };
          if (field === "percentage") {
            const subTotalValue = parseFloat(
              prevState.subTotal.replace(/[^\d.-]+/g, "")
            );
            updatedItem.amount = Math.ceil(
              (subTotalValue * parseFloat(newValue)) / 100
            );
          }
          return updatedItem;
        }
        return item;
      });

      const updatedState = {
        ...prevState,
        [type]: newTypeState,
      };

      // Calculate total after updating fields
      calculateTotal(setSubTotalTables, updatedState);

      return updatedState;
    });
  };

  const calculateTotal = (setSubTotalTables, subTotalTables) => {
    let subTotal = parseFloat(
      subTotalTables.subTotal.replace(/[^0-9.-]+/g, "")
    );
    let total = subTotal;

    subTotalTables.add.forEach((item) => {
      if (item.amount) {
        total += parseFloat(item.amount);
      }
    });

    subTotalTables.minus.forEach((item) => {
      if (item.amount) {
        total -= parseFloat(item.amount);
      }
    });

    // Calculate GST amount based on the updated total
    const gstPercentage = parseFloat(subTotalTables.gst.percentage) || 0;
    const gstAmount = Math.ceil((total * gstPercentage) / 100);

    // Round total to two decimal places and then ceil it
    total = Math.ceil(total.toFixed(0));

    // Update state
    setSubTotalTables((prevState) => ({
      ...prevState,
      taxableAmount: total.toFixed(0),
      gst: {
        ...prevState.gst,
        amount: gstAmount.toFixed(0),
      },
      total: (total + gstAmount).toFixed(0),
    }));
  };

  const handleRemoveGST = () => {
    setSubTotalTables((prevState) => {
      // Reset GST fields
      const updatedState = {
        ...prevState,
        gst: {
          name: "",
          percentage: "",
          amount: "0",
        },
      };

      // Calculate total after resetting GST fields
      calculateTotal(setSubTotalTables, updatedState);

      return updatedState;
    });

    // Set addGST to false
    setAddGST(false);
  };

  return (
    <React.Fragment>
      {areaTables.map((table, index) => (
        <div className="quotation-editor">
          <div className="quotation-editor-section" key={index}>
            <div
              className="pdf-text-editor-space quotation-editor-space"
              style={{
                backgroundColor: background_color,
                overflowY: activeDropdown ? "auto" : "",
              }}
            >
              <div
                className="quotation-table-editor"
                ref={containerRefs[table.id]}
              >
                <div
                  className="quotation-table-header"
                  style={{
                    background: `linear-gradient(to right,  ${lightenColor(
                      table_header,
                      0
                    )}, ${lightenColor(table_header, 30)} , ${lightenColor(
                      table_header,
                      95
                    )})`,
                    color: table_font,
                    fontFamily: selectedFontFamily + ", sans-serif",
                    opacity: index === 0 ? "1" : "0",
                  }}
                >
                  Quotation
                </div>

                {/*item , sub-item inputs  */}
                {table.area.map((areaTable, areaIndex) => (
                  <table
                    className="quotation-table-container"
                    key={areaTable.id}
                    ref={areaRefs[areaTable.id]}
                  >
                    <thead className="quotation-header">
                      <tr
                        className="quotation-table-row-header"
                        style={{
                          color: table_font,
                          border: "none",
                        }}
                      >
                        <th
                          className="quotation-add-icon"
                          style={{
                            borderColor: background_color,
                            backgroundColor: background_color,
                            padding: 0,
                          }}
                        >
                          <IoIosAdd
                            className="quotation-add"
                            onClick={() => addAreaTable(table.id, areaTable.id)}
                          />
                        </th>
                        <th
                          className="quotation-input"
                          style={{
                            backgroundColor: table_header,
                            color: table_font,
                            borderColor: table_border,
                            fontFamily: selectedFontFamily + ", sans-serif",
                            borderLeft: `1.5px solid ${table_border}`,
                            borderTop: `1.5px solid ${table_border}`,
                            borderBottom: `1.5px solid ${table_border}`,
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Area 01"
                            className="placeholder-color"
                            style={{
                              backgroundColor: table_header,
                              color: table_font,
                              "--placeholder-color": table_font,
                              fontFamily: selectedFontFamily + ", sans-serif",
                            }}
                            value={areaTable.areaValue}
                            onChange={(e) =>
                              handleAreaTableChange(
                                table.id,
                                areaTable.id,
                                null,
                                null,
                                null,
                                "areaValue",
                                e.target.value
                              )
                            }
                          />
                        </th>
                        <th
                          className="quotation-finish"
                          style={{
                            borderColor: table_border,
                            backgroundColor: table_header,
                            fontFamily: selectedFontFamily + ", sans-serif",
                            borderTop: `1.5px solid ${table_border}`,
                            borderBottom: `1.5px solid ${table_border}`,
                          }}
                        >
                          Finish
                        </th>
                        <th
                          className="quotation-type"
                          style={{
                            borderColor: table_border,
                            backgroundColor: table_header,
                            fontFamily: selectedFontFamily + ", sans-serif",
                            borderTop: `1.5px solid ${table_border}`,
                            borderBottom: `1.5px solid ${table_border}`,
                          }}
                        >
                          Type
                        </th>

                        {Array.from(appliedColumns).map(
                          (column, columnIndex) => (
                            <th
                              className={`quotation-height ${
                                column === "Rate" || column === "Area"
                                  ? "quotation-area"
                                  : ""
                              }`}
                              key={columnIndex}
                              style={{
                                borderColor: table_border,
                                backgroundColor: table_header,
                                fontFamily: selectedFontFamily + ", sans-serif",
                                borderTop: `1.5px solid ${table_border}`,
                                borderBottom: `1.5px solid ${table_border}`,
                              }}
                            >
                              {column}
                            </th>
                          )
                        )}

                        <th
                          className="quotation-amount"
                          style={{
                            borderColor: table_border,
                            backgroundColor: table_header,
                            fontFamily: selectedFontFamily + ", sans-serif",
                            borderRight: `1.5px solid ${table_border}`,
                            borderTop: `1.5px solid ${table_border}`,
                            borderBottom: `1.5px solid ${table_border}`,
                          }}
                        >
                          Amount
                        </th>
                        <th
                          className="quotation-minus-icon"
                          style={{ border: "none" }}
                        >
                          <HiOutlineMinusSm
                            className="quotation-minus"
                            onClick={() =>
                              removeAreaTable(table.id, areaTable.id)
                            }
                          />
                        </th>
                      </tr>
                    </thead>

                    {/* body  */}
                    {areaTable.items.map((itemTable, itemIndex) => (
                      <React.Fragment key={itemTable.id}>
                        <tbody
                          className="quotation-body"
                          style={{ border: "none" }}
                          ref={itemRefs[itemTable.id]}
                        >
                          <tr className="quotation-item-name">
                            <td
                              className="quotation-add-icon"
                              style={{
                                border: "none",
                                backgroundColor: background_color,
                              }}
                            >
                              <IoIosAdd
                                className="quotation-add"
                                onClick={() =>
                                  addItemTable(
                                    table.id,
                                    areaTable.id,
                                    itemTable.id
                                  )
                                }
                              />
                            </td>
                            <td
                              colSpan={4 + Array.from(appliedColumns).length}
                              style={{
                                borderLeft: `1.5px solid ${table_border}`,
                                borderRight: `1.5px solid ${table_border}`,
                                zIndex: 0,
                                position: "relative",
                              }}
                            >
                              <input
                                type="text"
                                className="quotation-input-item"
                                placeholder="Item 01"
                                style={{
                                  fontFamily:
                                    selectedFontFamily + ", sans-serif",
                                }}
                                value={itemTable.itemValue}
                                onChange={(e) =>
                                  handleAreaTableChange(
                                    table.id,
                                    areaTable.id,
                                    itemTable.id,
                                    null,
                                    null,
                                    "itemValue",
                                    e.target.value
                                  )
                                }
                              />

                              <input
                                type="text"
                                className="quotation-input-description"
                                placeholder="Type description here"
                                style={{
                                  fontFamily:
                                    selectedFontFamily + ", sans-serif",
                                }}
                                value={itemTable.descriptionValue}
                                onChange={(e) =>
                                  handleAreaTableChange(
                                    table.id,
                                    areaTable.id,
                                    itemTable.id,
                                    null,
                                    null,
                                    "descriptionValue",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td
                              className="quotation-minus-icon"
                              style={{
                                backgroundColor: background_color,
                              }}
                            >
                              <HiOutlineMinusSm
                                className="quotation-minus"
                                onClick={() =>
                                  removeItemTable(
                                    table.id,
                                    areaTable.id,
                                    itemTable.id
                                  )
                                }
                              />
                            </td>
                          </tr>

                          {itemTable.subItems.map((subItemTable, subIndex) => (
                            <React.Fragment key={subItemTable.id}>
                              <tr
                                className={`quotation-item-subname-container ${
                                  subItemTable.warning
                                    ? "quotation-item-warning-container"
                                    : ""
                                }`}
                                ref={subItemRefs[subItemTable.id]}
                                style={{
                                  borderColor: table_border,
                                }}
                              >
                                <td
                                  className="quotation-add-icon"
                                  style={{
                                    border: "none",
                                    backgroundColor: background_color,
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <IoIosAdd
                                    className="quotation-add"
                                    onClick={() =>
                                      addSubItemTables(
                                        table.id,
                                        areaTable.id,
                                        itemTable.id,
                                        subItemTable.id
                                      )
                                    }
                                  />
                                </td>
                                <td
                                  className="quotation-input"
                                  style={{
                                    borderColor: table_border,
                                    borderLeft: `1.5px solid ${table_border}`,
                                    borderTop: `1px solid ${table_border}`,
                                    borderBottom:
                                      subIndex === itemTable.subItems.length - 1
                                        ? `1.5px solid ${table_border}`
                                        : "none",
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="quotation-input"
                                    placeholder="Sub item 01"
                                    style={{
                                      fontFamily:
                                        selectedFontFamily + ", sans-serif",
                                    }}
                                    value={subItemTable.subItemValue}
                                    onChange={(e) =>
                                      handleAreaTableChange(
                                        table.id,
                                        areaTable.id,
                                        itemTable.id,
                                        subItemTable.id,
                                        subIndex,
                                        "subItemValue",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td
                                  className="quotation-finish"
                                  style={{
                                    cursor: "pointer",
                                    borderColor: table_border,
                                    borderTop: `1px solid ${table_border}`,
                                    borderBottom:
                                      subIndex === itemTable.subItems.length - 1
                                        ? `1.5px solid ${table_border}`
                                        : "none",
                                  }}
                                  onClick={() =>
                                    toggleDropdown(subItemTable.id)
                                  }
                                >
                                  <input
                                    type="text"
                                    className="quotation-input"
                                    placeholder={
                                      subItemTable.category !== "Other"
                                        ? "Finish"
                                        : "type here..."
                                    }
                                    style={{
                                      fontFamily:
                                        selectedFontFamily + ", sans-serif",
                                      cursor: "pointer",
                                    }}
                                    value={subItemTable.finish}
                                    onChange={(e) =>
                                      handleAreaTableChange(
                                        table.id,
                                        areaTable.id,
                                        itemTable.id,
                                        subItemTable.id,
                                        subIndex,
                                        "finish",
                                        e.target.value
                                      )
                                    }
                                    readOnly={subItemTable.category !== "Other"}
                                  />
                                </td>
                                <td
                                  className="quotation-type"
                                  style={{
                                    cursor: "pointer",
                                    borderColor: table_border,
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                    borderTop: `1px solid ${table_border}`,
                                    borderBottom:
                                      subIndex === itemTable.subItems.length - 1
                                        ? `1.5px solid ${table_border}`
                                        : "none",
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="quotation-input"
                                    placeholder={
                                      subItemTable.category !== "Other"
                                        ? "Type"
                                        : "type here..."
                                    }
                                    style={{
                                      fontFamily:
                                        selectedFontFamily + ", sans-serif",
                                      cursor: "pointer",
                                    }}
                                    value={subItemTable.type}
                                    onChange={(e) =>
                                      handleAreaTableChange(
                                        table.id,
                                        areaTable.id,
                                        itemTable.id,
                                        subItemTable.id,
                                        subIndex,
                                        "type",
                                        e.target.value
                                      )
                                    }
                                    readOnly={subItemTable.category !== "Other"}
                                    onMouseEnter={() =>
                                      setHoveredType(
                                        `${areaIndex}-${itemIndex}-${subIndex}`
                                      )
                                    }
                                    onMouseLeave={() => setHoveredType(null)}
                                  />
                                  {subItemTable.description &&
                                    hoveredType ===
                                      `${areaIndex}-${itemIndex}-${subIndex}` && (
                                      <p className="quotation-decription ">
                                        {subItemTable.description}
                                      </p>
                                    )}
                                </td>

                                {/* height  */}
                                {Array.from(appliedColumns).map(
                                  (column, columnIndex) => (
                                    <td
                                      className={`quotation-height ${
                                        column === "Rate" || column === "Area"
                                          ? "quotation-area"
                                          : ""
                                      }`}
                                      style={{
                                        borderColor: table_border,
                                        borderTop: `1px solid ${table_border}`,
                                        borderBottom:
                                          subIndex ===
                                          itemTable.subItems.length - 1
                                            ? `1.5px solid ${table_border}`
                                            : "none",
                                      }}
                                      key={columnIndex}
                                    >
                                      {((column === "Height" ||
                                        column === "Width") &&
                                        subItemTable.unit !== "HW" &&
                                        (column === "Height"
                                          ? subItemTable.unit !== "H"
                                          : subItemTable.unit !== "W")) ||
                                     (column === "Area" &&  (subItemTable.widthUnit === "RFT" || subItemTable.widthUnit === "QTY" ))  ?  (
                                        <HiMinusSm
                                          style={{
                                            marginTop: "2px",
                                            marginBottom: "-2px",
                                          }}
                                        />
                                      ) : (
                                        <input
                                          type="text"
                                          placeholder="0"
                                          style={{
                                            fontFamily:
                                              selectedFontFamily +
                                              ", sans-serif",
                                          }}
                                          value={getValueForColumn(
                                            column,
                                            subItemTable
                                          )}
                                          onChange={(e) => {
                                            const value = e.target.value;

                                            if (
                                              column === "Height" ||
                                              column === "Width"
                                            ) {
                                              handleAreaTableChange(
                                                table.id,
                                                areaTable.id,
                                                itemTable.id,
                                                subItemTable.id,
                                                subIndex,
                                                column.toLowerCase(), // Adjusting to match property names
                                                value
                                              );
                                            }
                                            if (column === "Rate") {
                                              handleRateTableChange(
                                                setAreaTables,
                                                setRateTables,
                                                setSubTotalTables,
                                                table.id,
                                                areaTable.id,
                                                itemTable.id,
                                                subItemTable.id,
                                                subIndex,
                                                value
                                              );
                                            }
                                          }}
                                          readOnly={
                                            column !== "Height" &&
                                            column !== "Width" &&
                                            column !== "Rate"
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                    </td>
                                  )
                                )}

                                {/* amount  */}
                                {!itemAmountRow ? (subIndex === 0 && (
                                  <td
                                    className="quotation-amount quotation-amount-number"
                                    style={{
                                      borderColor: table_border,
                                      fontFamily:
                                        selectedFontFamily + ", sans-serif",
                                      backgroundColor: table_amount,
                                      color: amount_font,
                                      borderRight: `1.5px solid ${table_border}`,
                                      borderTop: `1px solid ${table_border}`,
                                      borderBottom: `1.5px solid ${table_border}`,
                                    }}
                                    rowSpan={subIndex * 2}
                                  >
                                    <input
                                      type="text"
                                      readOnly
                                      className="quotation-input"
                                      placeholder="0"
                                      style={{
                                        fontFamily:
                                          selectedFontFamily + ", sans-serif",
                                        cursor: "pointer",
                                        backgroundColor: table_amount,
                                        color: amount_font,
                                        padding: "0",
                                      }}
                                      value={
                                        formatIndianNumber(itemTable.amount)
                                          ? "₹ " +
                                            formatIndianNumber(itemTable.amount)
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleAreaTableChange(
                                          table.id,
                                          areaTable.id,
                                          itemTable.id,
                                          "amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                )):<td
                                  className="quotation-amount subItem-amount-text"
                                  style={{
                                    borderColor: table_border,
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                    backgroundColor: table_amount,
                                    color: amount_font,
                                    borderRight: `1.5px solid ${table_border}`,
                                    borderTop: `1px solid ${table_border}`,
                                    borderBottom:
                                      subIndex === itemTable.subItems.length - 1
                                        ? `1.5px solid ${table_border}`
                                        : "none",
                                  }}
                                 
                                >
                                  <input
                                    type="text"
                                    readOnly
                                    className="quotation-input"
                                    placeholder="0"
                                    style={{
                                      fontFamily:
                                        selectedFontFamily + ", sans-serif",
                                      cursor: "pointer",
                                      backgroundColor: table_amount,
                                      color: amount_font,
                                      padding: "0",
                                    }}
                                    value={
                                      formatIndianNumber(subItemTable.amount)
                                        ? "₹ " +
                                          formatIndianNumber(
                                            subItemTable.amount
                                          )
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleAreaTableChange(
                                        table.id,
                                        areaTable.id,
                                        itemTable.id,
                                        "amount",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>}

                              

                                <td
                                  className="quotation-minus-icon"
                                  style={{
                                    border: "none",
                                    backgroundColor: background_color,
                                    padding: 0,
                                  }}
                                >
                                  <HiOutlineMinusSm
                                    className="quotation-minus"
                                    onClick={() =>
                                      removeSubItemTables(
                                        table.id,
                                        areaTable.id,
                                        itemTable.id,
                                        subItemTable.id,
                                        subIndex
                                      )
                                    }
                                  />
                                </td>
                              </tr>

                              {activeDropdown === subItemTable.id && (
                                <tr
                                  className="quotation-item-subname-container"
                                  ref={finishesTypesRef}
                                  style={{
                                    borderColor: table_border,
                                  }}
                                >
                                  <td
                                    className="quotation-add-icon subtotal-add-icon"
                                    style={{ visibility: "hidden" }}
                                  >
                                    <IoIosAdd className="quotation-add" />
                                  </td>
                                  <td
                                    colSpan={4 + Array.from(appliedColumns).length}
                                    style={{
                                      borderColor: table_border,
                                      borderLeft: `1.5px solid ${table_border}`,
                                      borderTop: `1px solid ${table_border}`,
                                    }}
                                  >
                                    <FinishesTypes
                                      tableDetails={{
                                        tableId: table.id,
                                        areaId: areaTable.id,
                                        itemId: itemTable.id,
                                        subItemId: subItemTable.id,
                                        subIndex: subIndex,
                                        setSubTotalTables: setSubTotalTables,
                                        subTotalTables: subTotalTables,
                                      }}
                                      setActiveDropdown={setActiveDropdown}
                                      setAreaTables={setAreaTables}
                                      setRateTables={setRateTables}
                                      rates={rates}
                                      setFinishesData={setFinishesData}
                                      finishesData={finishesData}
                                      setRates={setRates}
                                      table_header={table_header}
                                      table_font={table_font}
                                      userCategories={userCategories}
                                    />
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </React.Fragment>
                    ))}
                    <div className="space-provider">.</div>
                  </table>
                ))}

                {index === areaTables.length - 1 && (
                  <React.Fragment>
                    <div className="space-provider">.</div>
                    {/* sub total  */}
                    <table className="quotation-table-container quotation-total-footer">
                      <tfoot
                        className="quotation-total-container"
                        ref={subTotalRefs}
                      >
                        {(subTotalTables.minus.length !== 0 ||
                          subTotalTables.add.length !== 0 ||
                          addGST) && (
                          <tr
                            className="quotation-subtotal"
                            style={{
                              color: table_font,
                            }}
                          >
                            <td
                              className="quotation-add-icon subtotal-add-icon"
                              style={{
                                backgroundColor: background_color,
                                border: "none",
                                visibility: "hidden",
                              }}
                            >
                              <IoIosAdd className="quotation-add " />
                            </td>
                            <td
                              className="subtotal-title"
                              colSpan={5}
                              style={{
                                fontFamily: selectedFontFamily + ", sans-serif",
                                backgroundColor: table_header,
                                borderLeft: `1.5px solid ${table_border}`,
                                borderTop: `1.5px solid ${table_border}`,
                              }}
                            >
                              Subtotal
                            </td>
                            <td
                              style={{
                                fontFamily: selectedFontFamily + ", sans-serif",
                                backgroundColor: table_header,
                                borderRight: `1.5px solid ${table_border}`,
                                borderTop: `1.5px solid ${table_border}`,
                              }}
                              className="subtotal-amount"
                            >
                              ₹ {formatIndianNumber(subTotalTables.subTotal)}
                            </td>
                            <td
                              className="quotation-minus-icon"
                              style={{
                                backgroundColor: background_color,
                                border: "none",
                                visibility: "hidden",
                              }}
                            >
                              <HiOutlineMinusSm className="quotation-minus" />
                            </td>
                          </tr>
                        )}

                        {subTotalTables.add.map((item, addIndex) => (
                          <React.Fragment key={item.id}>
                            <tr
                              className="quotation-designer-fee"
                              style={{ borderColor: table_border }}
                            >
                              <td className="quotation-add-icon subtotal-add-icon">
                                <IoIosAdd
                                  style={{
                                    backgroundColor: background_color,
                                  }}
                                  className="quotation-add "
                                  onClick={() => addSubTotalRow("add")}
                                />
                              </td>
                              <td
                                colSpan={4}
                                style={{
                                  fontFamily:
                                    selectedFontFamily + ", sans-serif",
                                  borderLeft: `1.5px solid ${table_border}`,
                                  backgroundColor: "#fff",
                                }}
                                className="quotation-designer-fee-text"
                              >
                                <span
                                  style={{
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                    opacity: addIndex === 0 ? "1" : "0",
                                  }}
                                >
                                  Add
                                </span>

                                <input
                                  type="text"
                                  placeholder="type..."
                                  style={{
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                  }}
                                  value={item.name}
                                  onChange={(e) =>
                                    handleSubtotalChange(
                                      "add",
                                      item.id,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>

                              <td
                                className="quotation-designer-percentage"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <input
                                  type="text"
                                  placeholder="0"
                                  style={{
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                  }}
                                  value={item.percentage}
                                  onChange={(e) =>
                                    handleSubtotalChange(
                                      "add",
                                      item.id,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                                <span>%</span>
                              </td>
                              <td
                                style={{
                                  fontFamily:
                                    selectedFontFamily + ", sans-serif",
                                  backgroundColor: "#fff",
                                  borderRight: `1.5px solid ${table_border}`,
                                }}
                              >
                                ₹{" "}
                                {item.amount
                                  ? formatIndianNumber(item.amount)
                                  : "0"}
                              </td>
                              <td className="quotation-minus-icon">
                                <HiOutlineMinusSm
                                  className="quotation-minus"
                                  onClick={() =>
                                    removeSubTotalRow("add", item.id)
                                  }
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}

                        {subTotalTables.minus.map((item, minusIndex) => (
                          <React.Fragment key={item.id}>
                            <tr
                              className="quotation-designer-fee"
                              style={{ borderColor: table_border }}
                            >
                              <td className="quotation-add-icon subtotal-add-icon">
                                <IoIosAdd
                                  className="quotation-add"
                                  onClick={() => addSubTotalRow("minus")}
                                />
                              </td>
                              <td
                                colSpan={4}
                                className="quotation-designer-fee-text"
                                style={{
                                  borderLeft: `1.5px solid ${table_border}`,
                                  backgroundColor: "#fff",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                    opacity: minusIndex === 0 ? "1" : "0",
                                  }}
                                >
                                  Minus
                                </span>

                                <input
                                  type="text"
                                  placeholder="type..."
                                  style={{
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                  }}
                                  value={item.name}
                                  onChange={(e) =>
                                    handleSubtotalChange(
                                      "minus",
                                      item.id,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td
                                className="quotation-designer-percentage"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <input
                                  type="text"
                                  placeholder="0"
                                  style={{
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                  }}
                                  value={item.percentage}
                                  onChange={(e) =>
                                    handleSubtotalChange(
                                      "minus",
                                      item.id,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                                <span>%</span>
                              </td>
                              <td
                                style={{
                                  fontFamily: `${selectedFontFamily}, sans-serif`,
                                  borderRight: `1.5px solid ${table_border}`,
                                  backgroundColor: "#fff",
                                }}
                              >
                                ₹{" "}
                                {item.amount
                                  ? formatIndianNumber(item.amount)
                                  : "0"}
                              </td>
                              <td className="quotation-minus-icon">
                                <HiOutlineMinusSm
                                  className="quotation-minus"
                                  onClick={() =>
                                    removeSubTotalRow("minus", item.id)
                                  }
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}

                        {(subTotalTables.minus.length !== 0 ||
                          subTotalTables.add.length !== 0) &&
                          !addGST && (
                            <tr className="quotation-gst-btn">
                              <td
                                className="quotation-add-icon subtotal-add-icon"
                                style={{ visibility: "hidden" }}
                              >
                                <IoIosAdd className="quotation-add" />
                              </td>
                              <td
                                colSpan={6}
                                className="subtotal-add-gst"
                                style={{
                                  borderLeft: `1.5px solid ${table_border}`,
                                  borderRight: `1.5px solid ${table_border}`,
                                }}
                              >
                                <span onClick={() => setAddGST(true)}>
                                  add GST
                                </span>
                              </td>
                              <td
                                className="quotation-minus-icon"
                                style={{ visibility: "hidden" }}
                              >
                                <HiOutlineMinusSm className="quotation-minus" />
                              </td>
                            </tr>
                          )}

                        {/* taxable amount */}
                        {addGST && (
                          <React.Fragment>
                            {(subTotalTables.minus.length !== 0 ||
                              subTotalTables.add.length !== 0) && (
                              <tr
                                className="quotation-subtotal quotation-taxable-amount "
                                style={{
                                  borderColor: table_border,
                                  color: table_font,
                                }}
                              >
                                <td
                                  className="quotation-add-icon subtotal-add-icon"
                                  style={{
                                    backgroundColor: background_color,
                                    border: "none",
                                    visibility: "hidden",
                                  }}
                                >
                                  <IoIosAdd className="quotation-add" />
                                </td>
                                <td
                                  className="subtotal-title"
                                  colSpan={5}
                                  style={{
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                    borderLeft: `1.5px solid ${table_border}`,
                                    backgroundColor: table_header,
                                  }}
                                >
                                  Taxable Amount
                                </td>
                                <td
                                  style={{
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                    borderRight: `1.5px solid ${table_border}`,
                                    backgroundColor: table_header,
                                  }}
                                  className="subtotal-amount"
                                >
                                  ₹{" "}
                                  {formatIndianNumber(
                                    subTotalTables.taxableAmount
                                  )}
                                </td>
                                <td
                                  className="quotation-minus-icon"
                                  style={{
                                    backgroundColor: background_color,
                                    border: "none",
                                    visibility: "hidden",
                                  }}
                                >
                                  <HiOutlineMinusSm className="quotation-minus" />
                                </td>
                              </tr>
                            )}

                            <tr
                              className="quotation-designer-fee quotation-gst"
                              style={{ borderColor: table_border }}
                            >
                              <td
                                className="quotation-add-icon"
                                style={{
                                  backgroundColor: background_color,
                                  border: "none",
                                  visibility: "hidden",
                                }}
                              >
                                <IoIosAdd className="quotation-add" />
                              </td>
                              <td
                                colSpan={4}
                                style={{
                                  fontFamily:
                                    selectedFontFamily + ", sans-serif",
                                  borderLeft: `1.5px solid ${table_border}`,

                                  backgroundColor: "#fff",
                                }}
                                className="quotation-designer-fee-text"
                              >
                                <input
                                  type="text"
                                  placeholder="GST"
                                  style={{
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                  }}
                                  value={subTotalTables.gst.name}
                                  onChange={(e) =>
                                    handleSubtotalChange(
                                      "gst",
                                      null,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>

                              <td
                                className="quotation-designer-percentage"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <input
                                  type="text"
                                  placeholder="0"
                                  style={{
                                    fontFamily:
                                      selectedFontFamily + ", sans-serif",
                                  }}
                                  value={subTotalTables.gst.percentage}
                                  onChange={(e) =>
                                    handleSubtotalChange(
                                      "gst",
                                      null,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                                <span>%</span>
                              </td>
                              <td
                                style={{
                                  fontFamily:
                                    selectedFontFamily + ", sans-serif",
                                  backgroundColor: "#fff",
                                  borderRight: `1.5px solid ${table_border}`,
                                }}
                              >
                                ₹{" "}
                                {formatIndianNumber(subTotalTables.gst.amount)}
                              </td>
                              <td className="quotation-minus-icon">
                                <HiOutlineMinusSm
                                  className="quotation-minus"
                                  onClick={handleRemoveGST}
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        )}

                        {/* total amount  */}
                        <tr
                          className="quotation-subtotal"
                          style={{
                            borderColor: table_border,
                            color: table_font,
                          }}
                        >
                          <td
                            className="quotation-add-icon subtotal-add-icon"
                            style={{
                              backgroundColor: background_color,
                              border: "none",
                              visibility:
                                subTotalTables.minus.length !== 0 ||
                                subTotalTables.add.length !== 0 ||
                                addGST
                                  ? "hidden"
                                  : "visible",
                            }}
                          >
                            <IoIosAdd
                              className="quotation-add"
                              onClick={() =>
                                setSubTotalTables((prevState) => ({
                                  ...prevState,
                                  add: [
                                    {
                                      id: uuidv4(),
                                      name: "",
                                      percentage: "",
                                      amount: "0",
                                    },
                                  ],
                                  minus: [
                                    {
                                      id: uuidv4(),
                                      name: "",
                                      percentage: "",
                                      amount: "0",
                                    },
                                  ],
                                }))
                              }
                            />
                          </td>
                          <td
                            className="subtotal-title"
                            colSpan={5}
                            style={{
                              fontFamily: selectedFontFamily + ", sans-serif",
                              backgroundColor: table_header,
                              borderLeft: `1.5px solid ${table_border}`,
                            }}
                          >
                            {`Total ${
                              subTotalTables.gst.percentage
                                ? "(inc. of GST)"
                                : "(excl. of GST)"
                            }`}
                          </td>
                          <td
                            style={{
                              fontFamily: selectedFontFamily + ", sans-serif",
                              backgroundColor: table_header,
                              borderRight: `1.5px solid ${table_border}`,
                            }}
                            className="subtotal-amount"
                          >
                            ₹ {formatIndianNumber(subTotalTables.total)}
                          </td>
                          <td
                            className="quotation-minus-icon"
                            style={{
                              backgroundColor: background_color,
                              border: "none",
                              visibility: "hidden",
                            }}
                          >
                            <HiOutlineMinusSm className="quotation-minus" />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          {/* quotation rate  */}
          <div
            className="quotation-rates"
            style={{
              backgroundColor: background_color,
            }}
          >
            <div className="quotation-table-header hide-quotation-table-header">
              Quotation
            </div>

            {table.area.map((areaTable, areaindex) => (
              <table key={areaTable.id} className="quotation-table-container">
                <thead>
                  <tr
                    className={
                      areaindex === 0
                        ? "quotation-rate-header"
                        : "hide-quotation-rate-header"
                    }
                    style={{
                      borderColor: background_color,
                     
                    }}
                  >
                    {getUnappliedColumns(appliedColumns).map(
                      (column, columnRateIndex) => (
                        <th
                          className="quotation-rate"
                          style={{
                            backgroundColor: table_header,
                            fontFamily: selectedFontFamily + ", sans-serif",
                            opacity: areaindex === 0 ? "1" : "0",
                            
                          }}
                          key={columnRateIndex}
                        >
                          {column}
                        </th>
                      )
                    )}
                  </tr>
                </thead>

                {areaTable.items.map((itemTable, itemIndex) => (
                  <tbody
                    key={itemTable.id}
                    style={{
                      display: "table-header-group",
                    }}
                  >
                    <tr className="quotation-rate-item-name hide-row">
                      <td colSpan={3}>
                        <input
                          type="text"
                          className="quotation-input-item"
                          placeholder="Item 01"
                          style={{
                            fontFamily: selectedFontFamily + ", sans-serif",
                          }}
                        />
                        <input
                          type="text"
                          className="quotation-input-description"
                          placeholder="Type description here"
                          style={{
                            fontFamily: selectedFontFamily + ", sans-serif",
                           
                          }}
                        />
                      </td>
                    </tr>

                    {itemTable.subItems.map((subItemTable, subIndex) => (
                      <tr
                        className={`quotation-rate-input`}
                        key={subItemTable.id}
                      >
                        {getUnappliedColumns(appliedColumns).map(
                          (column, columnRateIndex) => (
                            <td key={columnRateIndex}>
                              <input
                                type="text"
                                placeholder="0"
                                style={{
                                  
                                  borderTop:subIndex !== 0 && `0px solid ${table_border}`,
                                  borderColor: table_border,
                                  borderTopColor: table_border,
                                  fontFamily:
                                    selectedFontFamily + ", sans-serif",
                                  color: subItemTable.warning ? "red" : "",
                                }}
                                value={getValueForColumn(column, subItemTable)}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (column === "Rate") {
                                    handleRateTableChange(
                                      setAreaTables,
                                      setRateTables,
                                      setSubTotalTables,
                                      table.id,
                                      areaTable.id,
                                      itemTable.id,
                                      subItemTable.id,
                                      subIndex,
                                      value
                                    );
                                  }
                                }}
                                readOnly={
                                  subItemTable.finish == "" ||
                                  subItemTable.type == "" ||
                                  !subItemTable.finish ||
                                  subItemTable.warning === "item" ||
                                  column !== "Rate"
                                }
                              />

                              {getUnappliedColumns(appliedColumns).length -
                                1 ===
                                columnRateIndex &&
                                subItemTable.warning && (
                                  <div className="quotation-warning">
                                    {(subItemTable.warning === "unit" ||
                                      subItemTable.warning === "range") && (
                                      <p>
                                        Warning ! This line item has been
                                        changed. Please verify (h), (w) and the
                                        rates again.
                                      </p>
                                    )}

                                    {subItemTable.warning === "item" && (
                                      <p>
                                        Warning ! This item no longer exists.
                                        Please delete it and reselect again
                                      </p>
                                    )}

                                    {subItemTable.warning === "empty" && (
                                      <p>
                                        Warning ! Please select the finish and
                                        type for this item
                                      </p>
                                    )}
                                  </div>
                                )}
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                ))}
                <div className="space-provider">.</div>
              </table>
            ))}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
}

export default QuotationTable;
