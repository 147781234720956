import React from 'react'
import { FaRegCircleCheck } from 'react-icons/fa6'
import { IoSearchOutline } from 'react-icons/io5'
import { MdDelete } from 'react-icons/md'
// import "./teamProjects.css"

function TeamProjects() {
  return (
    <section className="profile-details order-details">
          <div className="order-history">
            <h1 className="order-history-title">Team Members</h1>
            <div className="order-search-bar">
              <IoSearchOutline className="order-search-icon" />
              <input type="text" placeholder="Search in order history" />
            </div>

          </div>
          <div className="order-table">
            <table>
              <thead>
                <tr>
                  <th className="order-table-header">Sl No</th>
                  <th className="order-table-header team-member-name">Projects</th>
                  <th className="order-table-header team-member-name">Assigned Member</th>
                  <th className="order-table-header">Assigned On</th>
                  <th className="order-table-header">Assign</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 15 }).map((_, index) => (
                  <tr className="order-row" key={index}>
                    <td className="order-data">{index+1}</td>
                    <td className="order-data">Project 1</td>
                    <td className="order-data">Kiran H S</td>
                    <td className="order-data">22 Feb, 2024</td>
                    <td className="order-data">
                      <button className='team-assign-button'> Assign </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
  )
}

export default TeamProjects