import React, { useEffect, useRef, useState } from "react";
import landingPageImg from "../../../assets/Landing_page_picture.jpg";
import Sidebar from "../../../components/sidebar_newquotation/sideBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AXIOS from "../../../services/axios";
import {
  checkForWarnings,
  extractCategoryInfo,
  formatIndianNumber,
  generateTable,
  intialRateTable,
} from "../../../services/reusableCodes";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../../components/loading/loading";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import CustomTextBox from "../../../components/customizedQuilTexBox/CustomTextBox";
import { MdOutlineTextFields } from "react-icons/md";
import MyQuotationTable from "./my-quotation-table";
import { useSelector } from "react-redux";
import MobileScreenMessage from "../../../components/mobileScreenMessage/mobileScreenMessage";

function MyQuotation() {
  const [color, setColor] = useState({
    background_color: "#fff7ef",
    border_color: "#fff7ef",
    table_header: "#f16f40",
    table_font: "#ffffff",
    table_border: "#f16f40",
    table_amount: "#ffffff",
    amount_font: "#636363",
    itemAmountRow:false
  });
  const [selectedFontFamily, setSelectedFontFamily] = useState("Montserrat");
  const { pathname } = useLocation();
  const { projectId, version } = useParams();
  const initialTable = generateTable();
  const initialRateTable = intialRateTable(initialTable);
  const [areaTables, setAreaTables] = useState([initialTable]);
  const [rateTables, setRateTables] = useState([initialRateTable]);
  const [subTotalTables, setSubTotalTables] = useState({
    subTotal: "0",
    total: "0",
    taxableAmount: "0",
    gst: {
      name: "",
      percentage: "",
      amount: "0",
    },
    add: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
    minus: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
  });
  const [summary, setSummary] = useState({
    area: [
      {
        name: "",
        value: "",
      },
    ],
    subtotal: "",
    total: "",
  });
  const navigate = useNavigate();
  const [updateSave, setUpdateSave] = useState(false);
  const [interioverseRate, setInterioversRate] = useState({
    total: "",
    gstTotal: "",
    mainTotal: "",
    InterioverseGst: "",
    userRange: "",
    maxRange: "",
    designerCommision: "",
  });
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({
    name: "",
    cityName: "",
  });
  const [editorPages, setEditorPages] = useState([]);
  const [tablePages, setTablePages] = useState([]);
  const [initialPages, setInitialPages] = useState([]);
  const [initialBoxes, setInitialBoxes] = useState([]);
  const [initialProjectBoxes, setInitialProjectBoxes] = useState([]);
  const [projectName,setProjectName] = useState("")
  const [pageContentData, setPageContentData] = useState({
    content: [],
    pageMerge: "",
  });
  const [activePage, setActivePage] = useState(null);
  const [activeBox, setActiveBox] = useState(null);
  const isMobile = useSelector((state) => state.isMobileScreen.isMobile);
  const [isCityFound, setIsCityFound] = useState(false);
  const [appliedColumns, setAppliedColumns] = useState(
    new Set(["Height", "Width"])
  );
  

  const handleRangeChange = (event) => {
    setInterioversRate((prev) => ({
      ...prev,
      userRange: event.target.value,
    }));
  };

  const checkCity = async () => {
    try {
      const response = await AXIOS.get(`/project/check-city/${projectId}`);
      if (response.status === 200) {
        setIsCityFound(true);
      }
    } catch (error) {
      if (error.response.status === 200) {
        setIsCityFound(false);
      }
    }
  };

  const handleGetTableData = async (version) => {
    try {
      let response;

      response = await AXIOS.get(
        `/user-template/template/${projectId}/${version}`
      );

      if (response && response.status === 200) {
        let templateData;
        let pagesContent;
        let pageToMerge;
        let color;
        let project_name
        let selected_column

        if (response.data.user_template) {
          templateData = response.data.user_template;
          pagesContent = response.data.pagesContent;
          pageToMerge = parseInt(response.data.pageToMerge, 10);
          color = response.data.color;
          project_name = response.data.projectName
          selected_column = response.data.selected_column
        } else {
          templateData = response.data.baseTemplates[0];
          pagesContent = response.data.baseTemplates[0].pagesContent;
          pageToMerge = parseInt(
            response.data.baseTemplates[0].pageToMerge,
            10
          );
          project_name = response.data.projectName
          selected_column = response.data.baseTemplates[0].selected_column
        }

        if (pagesContent) {
          let newEditorPages = pagesContent
            .slice(0, pageToMerge)
            .map((page) => ({
              id: page.id,
              editorRef: React.createRef(),
              quillRef: React.createRef(),
              textBox: page.textbox.map((box) => ({
                id: box.id,
                textBoxRef: React.createRef(),
                quillRef: React.createRef(),
                position: JSON.parse(box.position),
                dragging: { current: JSON.parse(box.dragging) },
                offset: { current: JSON.parse(box.offset) },
                resize: box.resize,
                image: JSON.parse(box.image),
                width: box.width,
                height: box.height,
                zIndex: JSON.parse(box.zIndex),
                fontSize: box.fontSize,
                content: "",
                isTextareaActive: false,
              })),
              projectNameBox: page.projectNameBox.map((box) => ({
                id: box.id,
                textBoxRef: React.createRef(),
                quillRef: React.createRef(),
                position: JSON.parse(box.position),
                dragging: { current: JSON.parse(box.dragging) },
                offset: { current: JSON.parse(box.offset) },
                resize: box.resize,
                image: JSON.parse(box.image),
                width: box.width,
                height: box.height,
                zIndex: JSON.parse(box.zIndex),
                fontSize: box.fontSize,
                content: "",
                isTextareaActive: false,
              })),
            }));

          let newTablePages = pagesContent.slice(pageToMerge).map((page) => ({
            id: page.id,
            editorRef: React.createRef(),
            quillRef: React.createRef(),
            textBox: page.textbox.map((box) => ({
              id: box.id,
              textBoxRef: React.createRef(),
              quillRef: React.createRef(),
              position: JSON.parse(box.position),
              dragging: { current: JSON.parse(box.dragging) },
              offset: { current: JSON.parse(box.offset) },
              resize: box.resize,
              image: JSON.parse(box.image),
              width: box.width,
              height: box.height,
              zIndex: JSON.parse(box.zIndex),
              fontSize: box.fontSize,
              content: "",
              isTextareaActive: false,
            })),
            projectNameBox: page.projectNameBox.map((box) => ({
              id: box.id,
              textBoxRef: React.createRef(),
              quillRef: React.createRef(),
              position: JSON.parse(box.position),
              dragging: { current: JSON.parse(box.dragging) },
              offset: { current: JSON.parse(box.offset) },
              resize: box.resize,
              image: JSON.parse(box.image),
              width: box.width,
              height: box.height,
              zIndex: JSON.parse(box.zIndex),
              fontSize: box.fontSize,
              content: "",
              isTextareaActive: false,
            })),
          }));

          if (newEditorPages.length > 0) {
            setEditorPages(newEditorPages);
          }

          if (newTablePages.length > 0) {
            setTablePages(newTablePages);
          }
          setInitialPages(newEditorPages.concat(newTablePages));

          let boxes = [];
          newEditorPages.concat(newTablePages).map((page) => {
            page.textBox.map((box) => {
              boxes.push(box);
            });
          });

          setInitialBoxes(boxes);

          let projectBoxes = [];
          newEditorPages.concat(newTablePages).forEach((page) => {
            page.projectNameBox.forEach((box) => {
              projectBoxes.push(box);
            });
          });

          setInitialProjectBoxes(projectBoxes);

          setPageContentData((prev) => ({
            ...prev,
            content: pagesContent,
            pageMerge: pageToMerge,
          }));
        }

        if (templateData) {
          let newSubTotalTables;

          newSubTotalTables = {
            subTotal: templateData.user_subTotal || templateData.subTotal,
            total: templateData.user_total || templateData.total,
            taxableAmount:
              templateData.user_taxableAmount || templateData.taxableAmount,
            gst: templateData.user_gst || templateData.gst,
            add: templateData.user_add || templateData.add,
            minus: templateData.user_minus || templateData.minus,
          };

          // Extract areaTables data from templateData
          const newAreaTables = (templateData.template || []).map((table) => ({
            id: table.id || uuidv4(),
            area: (table.areas || []).map((area) => ({
              id: area.area_id || uuidv4(),
              areaValue: area.name || "",
              amount: area.amount || "",
              items: (area.items || []).map((item) => ({
                id: item.item_id || uuidv4(),
                itemValue: item.name || "",
                descriptionValue: item.description || "",
                amount: item.amount || "",
                subItems: (item.subItems || []).map((subItem) => ({
                  id: subItem.name_id || uuidv4(),
                  subItemValue: subItem.name || "",
                  amount: subItem.amount || "",
                  category: subItem.category || "",
                  finish: subItem.finish || "",
                  type: subItem.type || "",
                  height: subItem.height || "",
                  width: subItem.width || "",
                  amount: subItem.amount || "",
                  unit: subItem.unit || "",
                  range: subItem.range || "",
                  rate: subItem.rate || "",
                  categoryId: subItem.categoryId || "",
                  finishId: subItem.finishId || "",
                  typeId: subItem.typeId || "",
                  warning: subItem.warning || "",
                  widthUnit: subItem.widthUnit || "",
                  categoryType: subItem.categoryType || "",
                })),
              })),
            })),
          }));
          setColor({
            background_color:
              templateData.background_color ||
              color.background_color ||
              "#fff7ef",
            table_header:
              templateData.table_header || color.table_header || "#f16f40",
            table_font:
              templateData.table_font || color.table_font || "#ffffff",
            table_border:
              templateData.table_border || color.table_border || "#f16f40",
            table_amount:
              templateData.table_amount || color.table_amount || "#ffffff",
            amount_font:
              templateData.amount_font || color.amount_font || "#636363",
              itemAmountRow:color.item_Amount_Row || false
          });
          setSelectedFontFamily(
            templateData.font_family || color.font_family || "Montserrat"
          );
          // Update the states

          checkForWarnings(newAreaTables);

          if (checkForWarnings(newAreaTables)) {
            navigate(`/new-project/${projectId}/${version}`);
            return;
          }
          setSubTotalTables(newSubTotalTables);
          setAreaTables(newAreaTables);
          setProjectName(project_name)
          setAppliedColumns(new Set(selected_column));
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("Error saving data", error);
      setLoading(false);
    }
  };

  
  useEffect(() => {
    if (!isMobile) {
      handleGetTableData(version);
    } else {
      setLoading(false);
    }

    checkCity();
  }, [pathname]);

  useEffect(() => {
    if (initialPages) {
      multiInitializeEditors(initialPages);
    }
    if (initialBoxes) {
      multiInitializeTextBox(initialBoxes, "text");
    }
    if (initialProjectBoxes) {
      multiInitializeTextBox(initialProjectBoxes, "project");
    }
  }, [initialPages, initialBoxes, initialProjectBoxes]);

  const multiInitializeEditors = (pages) => {
    pages.forEach((page) => {
      if (page.editorRef.current && !page.quillRef.current) {
        const quill = new Quill(page.editorRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `#toolbar-${page.id}`,
              handlers: {
                customTextBox: () =>
                  quill.getModule("customTextBox").showTextBox(),
              },
            },
            customTextBox: {
              showTextBox: (range) => {
                const bounds = quill.getBounds(range);
              },
            },
          },
        });

        page.editorRef.current.setAttribute("spellinitialPages", "false");
        page.quillRef.current = quill;
      }
    });
  };

  const multiInitializeTextBox = (boxes, boxKey) => {
    boxes.forEach((box) => {
      if (box.textBoxRef.current && !box.quillRef.current) {
        const Font = Quill.import("formats/font");
        Font.whitelist = ["mirza", "roboto", "serif"];
        Quill.register(Font, true);

        const quill = new Quill(box.textBoxRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `${
                boxKey === "text"
                  ? `#textBox-${box.id}`
                  : `#projectNameBox-${box.id}`
              }`,
            },
          },
        });

        box.textBoxRef.current.setAttribute("spellinitialPages", "false");
        box.quillRef.current = quill;
      }
    });
  };

  useEffect(() => {
    const tableData = async () => {
      try {
        if (
          pageContentData.content &&
          Array.isArray(pageContentData.content) &&
          pageContentData.content.length > 0
        ) {
          const pagesContent = pageContentData.content;
          const pageToMerge = parseInt(pageContentData.pageMerge, 10);

          // Update editorPages
          editorPages.forEach((prevPage) => {
            const matchedPage = pagesContent
              .slice(0, pageToMerge)
              .find((page) => page.id === prevPage.id);
            if (matchedPage) {
              if (prevPage.quillRef.current) {
                prevPage.quillRef.current.root.innerHTML =
                  matchedPage.quillData;
              }

              prevPage.textBox.forEach((prevBox) => {
                const matchedBox = matchedPage.textbox.find(
                  (box) => box.id === prevBox.id
                );
                if (matchedBox) {
                  if (prevBox.quillRef.current) {
                    prevBox.quillRef.current.root.innerHTML =
                      matchedBox.quillData;
                  }
                }
              });
              

             prevPage.projectNameBox.forEach((prevBox) => {
              const matchedProjectBox = matchedPage.projectNameBox.find(
                (box) => box.id === prevBox.id
              );
          
              if (matchedProjectBox) {
                if (prevBox.quillRef.current) {
                  
                  // Create a temporary container to hold the quillData content
                  const tempDiv = document.createElement("div");
                  tempDiv.innerHTML = matchedProjectBox.quillData;
                  
                  // Recursively search and replace the "Project name placeholder" in the text nodes
                  const replaceTextNodes = (element) => {
                    element.childNodes.forEach((node) => {
                      if (node.nodeType === Node.TEXT_NODE && node.textContent.includes("Project name placeholder")) {
                        // Replace the placeholder text with the projectName
                        node.textContent = node.textContent.replace("Project name placeholder", projectName);
                      } else if (node.nodeType === Node.ELEMENT_NODE) {
                        // Recursively handle element nodes to search within nested tags
                        replaceTextNodes(node);
                      }
                    });
                  };
          
                  // Start replacing the text content
                  replaceTextNodes(tempDiv);
          
                  // Update the quill editor's root element with the modified HTML
                  prevBox.quillRef.current.root.innerHTML = tempDiv.innerHTML;
                }
              }
            });
            }
          });

          // Update tablePages
          tablePages.forEach((prevPage) => {
            const matchedPage = pagesContent
              .slice(pageToMerge)
              .find((page) => page.id === prevPage.id);
            if (matchedPage) {
              if (prevPage.quillRef.current) {
                prevPage.quillRef.current.root.innerHTML =
                  matchedPage.quillData;
              }

              prevPage.textBox.forEach((prevBox) => {
                const matchedBox = matchedPage.textbox.find(
                  (box) => box.id === prevBox.id
                );
                if (matchedBox) {
                  if (prevBox.quillRef.current) {
                    prevBox.quillRef.current.root.innerHTML =
                      matchedBox.quillData;
                  }
                }
              });

              prevPage.projectNameBox.forEach((prevBox) => {
                const matchedProjectBox = matchedPage.projectNameBox.find(
                  (box) => box.id === prevBox.id
                );
            
                if (matchedProjectBox) {
                  if (prevBox.quillRef.current) {
                    
                    // Create a temporary container to hold the quillData content
                    const tempDiv = document.createElement("div");
                    tempDiv.innerHTML = matchedProjectBox.quillData;
                    
                    // Recursively search and replace the "Project name placeholder" in the text nodes
                    const replaceTextNodes = (element) => {
                      element.childNodes.forEach((node) => {
                        if (node.nodeType === Node.TEXT_NODE && node.textContent.includes("Project name placeholder")) {
                          // Replace the placeholder text with the projectName
                          node.textContent = node.textContent.replace("Project name placeholder", projectName);
                        } else if (node.nodeType === Node.ELEMENT_NODE) {
                          // Recursively handle element nodes to search within nested tags
                          replaceTextNodes(node);
                        }
                      });
                    };
            
                    // Start replacing the text content
                    replaceTextNodes(tempDiv);
            
                    // Update the quill editor's root element with the modified HTML
                    prevBox.quillRef.current.root.innerHTML = tempDiv.innerHTML;
                  }
                }
              });
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    tableData();
  }, [initialPages, initialBoxes]);

  useEffect(() => {
    if (activePage) {
      initializeEditors(activePage);
    }
  }, [activePage]);

  useEffect(() => {
    const newSummary = {
      area: areaTables.flatMap((table) =>
        (table.area || []).map((area) => ({
          name: area.areaValue || "",
          value: area.amount || "",
        }))
      ),
      subtotal: subTotalTables.subTotal || "0",
      total: subTotalTables.total || "0",
    };

    // Update the summary state
    setSummary(newSummary);
  }, [areaTables, rateTables]);

  const textBoxImage = (boxId, width, height) => {
    // Helper function to find and update the page containing the boxId
    const findAndUpdateBoxInPages = (pages, setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) => {
          if (page.textBox.some((box) => box.id === boxId)) {
            return {
              ...page,
              textBox: page.textBox.map((box) =>
                box.id === boxId
                  ? { ...box, image: true, width: width, height: height }
                  : box
              ),
            };
          }
          return page;
        })
      );
    };

    // initialPages in editorPages and update if found
    const pageExistsInEditorPages = editorPages.some((page) =>
      page.textBox.some((box) => box.id === boxId)
    );
    if (pageExistsInEditorPages) {
      findAndUpdateBoxInPages(editorPages, setEditorPages);
      return;
    }

    // initialPages in tablePages and update if found
    const pageExistsInTablePages = tablePages.some((page) =>
      page.textBox.some((box) => box.id === boxId)
    );
    if (pageExistsInTablePages) {
      findAndUpdateBoxInPages(tablePages, setTablePages);
    }
  };

  const initializeEditors = (page) => {
    if (page.editorRef.current && !page.quillRef.current) {
      const Font = Quill.import("formats/font");
      Font.whitelist = ["mirza", "roboto", "serif"];
      Quill.register(Font, true);

      const Size = Quill.import("attributors/style/size");
      Size.whitelist = ["10px", "13px", "18px", "24px", "32px", "48px"];
      Quill.register(Size, true);

      const quill = new Quill(page.editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: `#toolbar-${page.id}`,
            handlers: {
              bold: function () {
                const isBold = quill.getSelection() && quill.getFormat().bold;
                quill.format("bold", !isBold);
              },
              italic: function () {
                const isItalic =
                  quill.getSelection() && quill.getFormat().italic;
                quill.format("italic", !isItalic);
              },
              underline: function () {
                const isUnderline =
                  quill.getSelection() && quill.getFormat().underline;
                quill.format("underline", !isUnderline);
              },
              color: function (value) {
                if (quill.getSelection()) {
                  quill.format("color", value);
                  quill.format("background", false);
                }
              },
              background: function (value) {
                if (quill.getSelection()) {
                  quill.format("background", value);
                }
              },
              clean: function () {
                const selection = quill.getSelection();
                if (selection) {
                  quill.removeFormat(selection.index, selection.length);
                }
              },
              list: function (value) {
                const range = quill.getSelection();
                if (range) {
                  const format = quill.getFormat(range);
                  if (format.list === value) {
                    quill.format("list", false);
                  } else {
                    quill.format("list", value);
                  }
                }
              },
              indent: function (value) {
                quill.format("indent", value);
              },
              align: function (value) {
                quill.format("align", value);
              },
              size: function (value) {
                const range = quill.getSelection();
                if (range) {
                  quill.format("size", value);
                }
              },
              font: function (value) {
                const currentSelection = quill.getSelection();
                if (currentSelection) {
                  const { index, length } = currentSelection;
                  quill.formatText(index, length, "font", value);
                  setTimeout(() => {
                    quill.setSelection(index, length);
                  }, 0);
                }
              },
              customTextBox: () =>
                quill.getModule("customTextBox").showTextBox(),
            },
          },
          customTextBox: {
            showTextBox: (range) => {
              const bounds = quill.getBounds(range);
            },
          },
        },
      });

      page.editorRef.current.setAttribute("spellinitialPages", "false");
      page.quillRef.current = quill;
    }
  };

  const initializeTextBox = (box) => {
    if (box && box.textBoxRef.current && !box.quillRef.current) {
      const Font = Quill.import("formats/font");
      Font.whitelist = ["mirza", "roboto", "serif"];
      Quill.register(Font, true);

      const quill = new Quill(box.textBoxRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: `#textBox-${box.id}`,
            handlers: {
              bold: function () {
                const isBold = quill.getSelection() && quill.getFormat().bold;
                quill.format("bold", !isBold);
              },
              italic: function () {
                const isItalic =
                  quill.getSelection() && quill.getFormat().italic;
                quill.format("italic", !isItalic);
              },
              underline: function () {
                const isUnderline =
                  quill.getSelection() && quill.getFormat().underline;
                quill.format("underline", !isUnderline);
              },
              color: function (value) {
                if (box.image) return;
                if (quill.getSelection()) {
                  quill.format("color", value);
                  quill.format("background", false);
                }
              },
              background: function (value) {
                if (box.image) return;
                if (quill.getSelection()) {
                  quill.format("background", value);
                }
              },
              clean: function () {
                const selection = quill.getSelection();
                if (selection) {
                  quill.removeFormat(selection.index, selection.length);
                }
              },
              image: function () {
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");

                input.onchange = async (event) => {
                  const file = event.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const dataUrl = e.target.result;

                      // Create an image element to get the dimensions
                      const img = new Image();
                      img.onload = () => {
                        const width = img.width;
                        const height = img.height;

                        quill.focus();
                        const range = quill.getSelection();
                        quill.setContents([]); // Clear the content
                        quill.insertEmbed(range.index, "image", dataUrl);
                        quill.insertText(range.index + 1, "\u200B"); // Insert zero-width space
                        quill.setSelection(range.index + 1); // Set cursor to the zero-width space

                        if (box.image === false) {
                          textBoxImage(box.id, width, height);
                        }
                      };
                      img.src = dataUrl;
                    };
                    reader.readAsDataURL(file);
                  }
                };
                input.click();
              },
              list: function (value) {
                const range = quill.getSelection();
                if (range) {
                  const format = quill.getFormat(range);
                  if (format.list === value) {
                    quill.format("list", false);
                  } else {
                    quill.format("list", value);
                  }
                }
              },
              align: function (value) {
                quill.format("align", value);
              },
              font: function (value) {
                if (box.image) return;
                const currentSelection = quill.getSelection();
                if (currentSelection) {
                  const { index, length } = currentSelection;
                  quill.formatText(index, length, "font", value);
                  setTimeout(() => {
                    quill.setSelection(index, length);
                  }, 0);
                }
              },
            },
          },
        },
      });

      box.textBoxRef.current.setAttribute("spellinitialPages", "false");
      box.quillRef.current = quill;
    }
  };

  useEffect(() => {
    if (activeBox) {
      initializeTextBox(activeBox);
    }
  }, [activeBox]);

  useEffect(() => {
    if (pathname.startsWith("/my-quotation")) {
      getInterioverseTotal();
    }
    if (pathname.startsWith("/interioverse-quotation")) {
      getInterioverseGstTotal();
    }
    getGstDiscount();
    updateInterioverseRate();
  }, [areaTables, pathname]);

  const getInterioverseTotal = async () => {
    try {
      const categories = extractCategoryInfo(areaTables);

      const response = await AXIOS.post(
        "/user-template/get-interioverse-total",
        {
          projectId: projectId,
          categories: categories,
          version: version,
        }
      );
      setInterioversRate((prev) => ({
        ...prev,
        total: response.data.totalAmount,
        gstTotal: response.data.gstTotalAmount,
      }));
      setUserInfo((prev) => ({
        ...prev,
        name: response.data.name,
        cityName: response.data.city,
      }));
    } catch (error) {
      console.error("Error fetching interioverse total:", error);
    }
  };

  const getGstDiscount = async () => {
    try {
      const response = await AXIOS.get(
        `/admin/get-gst-discount/${projectId}/${version}`
      );
      if (response.status === 200) {
        setInterioversRate((prev) => ({
          ...prev,
          gst: response.data.gstPercent,
          maxRange: response.data.discountPercent,
          userRange:
            response.data.designerDiscount !== ""
              ? response.data.designerDiscount
              : response.data.discountPercent,
        }));
      }
    } catch (error) {
      console.error("Error fetching GST discount:", error);
    }
  };

  const getInterioverseMainTotal = async (
    interioverseSubtotal,
    maxRange,
    designerDiscount,
    gst
  ) => {
    const abc =
      ((interioverseSubtotal * 100) / (100 - maxRange)) *
      (1 - designerDiscount / 100);
    const salesCommision1 = (abc - interioverseSubtotal) * 0.7;
    const salesCommision2 = interioverseSubtotal * 0.07;
    const designerCommision = salesCommision1 + salesCommision2;
    const totalAmount =
      ((interioverseSubtotal * 100) / (100 - maxRange)) *
      (1 - designerDiscount / 100) *
      (1 + gst / 100);
    return {
      designerCommision: Math.ceil(designerCommision),
      mainTotal: Math.ceil(totalAmount),
    };
  };

  const getInterioverseGstTotal = async () => {
    try {
      const response = await AXIOS.get(
        `/user-template/interioverse-gst-total/${projectId}/${version}`
      );
      if (response.status === 200) {
        setInterioversRate((prev) => ({
          ...prev,
          total: response.data.totalAmount,
          gstTotal: response.data.gstTotalAmount,
        }));
      }
    } catch (error) {
      console.error("Error fetching interioverse total:", error);
    }
  };

  const updateInterioverseRate = async () => {
    try {
      const { designerCommision, mainTotal } = await getInterioverseMainTotal(
        interioverseRate.total,
        interioverseRate.maxRange,
        parseFloat(interioverseRate.userRange),
        interioverseRate.gst
      );

      // Update the state with the calculated values
      setInterioversRate((prev) => ({
        ...prev,
        designerCommision,
        mainTotal,
      }));
    } catch (error) {
      console.error("Error calculating main total:", error);
    }
  };

  useEffect(() => {
    updateInterioverseRate();
  }, [interioverseRate.userRange]);

  const getInterioversequotation = async () => {
    try {
      const categories = extractCategoryInfo(areaTables);

      const response = await AXIOS.post(
        "/user-template/interioverse-quotation",
        {
          projectId: projectId,
          categories: categories,
          maxRange: interioverseRate.maxRange,
        }
      );

      if (response.status === 200) {
        const { result, sumOfItemAmount, user_discount } = response.data;

        // Update areaTables with item amounts
        setAreaTables((prevTables) =>
          prevTables.map((table) => {
            table.area.forEach((area) => {
              result.forEach((res) => {
                if (area.id === res.areaId && table.id === res.tableId) {
                  area.items.forEach((item) => {
                    if (item.id === res.itemId) {
                      item.amount = res.itemAmount; // Update the amount
                    }
                  });
                }
              });
            });
            return table;
          })
        );

        // Calculate new subTotal using sumOfItemAmount
        const newSubTotal = parseFloat(sumOfItemAmount) || 0;

        // Calculate new minus object with user_discount
        const newDiscount = {
          name: "discount",
          percentage: user_discount,
          amount: Math.ceil((newSubTotal * parseFloat(user_discount)) / 100),
        };

        // Calculate taxable amount
        const taxableAmount = newSubTotal - newDiscount.amount;

        // Calculate GST
        const gstAmount = Math.ceil(
          (taxableAmount * parseFloat(interioverseRate.gst)) / 100
        );

        // Calculate total
        const total = taxableAmount + gstAmount;

        // Update subTotalTables state preserving other properties
        setSubTotalTables((prevTotal) => ({
          ...prevTotal,
          subTotal: newSubTotal,
          add: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
          minus: [newDiscount],
          taxableAmount: taxableAmount,
          gst: {
            name: "GST",
            percentage: interioverseRate.gst,
            amount: gstAmount,
          },
          total: total,
        }));
      }
    } catch (error) {
      console.error("Error fetching interioverse total:", error);
    }
  };

  const postInterioverseRateByProjectId = async (e) => {
    e.preventDefault();
    try {
      const response = await AXIOS.post(
        "/user-template/interioverse-total",
        {
          projectId: projectId,
          version: version,
          interioverse_gst_total: interioverseRate.mainTotal,
          designer_commision: interioverseRate.designerCommision,
          designer_discount: interioverseRate.userRange,
        },
        {
          withCredentials: true, // to include cookies in the request
        }
      );
      if (response.status === 200) {
        getInterioversequotation();
        navigate(`/download-quotation/${projectId}/${version}`);
      }
    } catch (error) {
      console.error(
        "Error posting interioverse rate:",
        error.response ? error.response.data : error.message
      );
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (isMobile) {
    return <MobileScreenMessage />;
  }

  return (
    <div>
      <figure>
        <img
          src={landingPageImg}
          alt="Cover img"
          className="partner-cover-img"
        />
      </figure>
      <section className="quotation-template">
        <article className="quotation-template-container">
          {editorPages && editorPages.length > 0 && (
            <section
              className="editor-section"
              style={{
                margin: "0px",
              }}
            >
              {editorPages.map((editor) => (
                <div
                  key={editor.id}
                  className="pdf-text-editor"
                  style={{ zIndex: 0 }}
                >
                  <div
                    className="pdf-text-editor-space"
                    style={{
                      backgroundColor: color.background_color,
                    }}
                  >
                    <div>
                      {editor.textBox &&
                        editor.textBox.map((box) => (
                          <div
                            className="draggable-container"
                            key={box.id}
                            style={{ height: "auto" }}
                          >
                            <div
                              style={{
                                left: box.position.x,
                                top: box.position.y,
                                width: box.width ? box.width : "",
                                height: box.height ? box.height : "",
                                resize: "none",
                                border: "none",
                                padding: "0px",
                                zIndex: `${box.zIndex ? box.zIndex : 1}`,
                                fontSize: box.fontSize
                                  ? `${box.fontSize}px`
                                  : "13px",
                              }}
                              ref={box.textBoxRef}
                              className="pdf-text-box"
                              spellinitialPages={false}
                            ></div>
                            <div
                              id={`textBox-${box.id}`}
                              className="text-box-tools-container"
                              style={{
                                display: "none",
                              }}
                            ></div>
                          </div>
                        ))}
                      {editor.projectNameBox &&
                        editor.projectNameBox.map((box) => (
                          <div
                            className="draggable-container"
                            key={box.id}
                            style={{ height: "auto" }}
                          >
                            <div
                              style={{
                                left: box.position.x,
                                top: box.position.y,
                                width: box.width ? box.width : "",
                                height: box.height ? box.height : "",
                                resize: "none",
                                border: "none",
                                padding: "0px",
                                paddingTop:'10px',
                                zIndex: `${box.zIndex ? box.zIndex : 1}`,
                                fontSize: box.fontSize
                                  ? `${box.fontSize}px`
                                  : "13px",
                              }}
                              ref={box.textBoxRef}
                              className="pdf-text-box"
                              spellinitialPages={false}
                            ></div>
                            <div
                              id={`projectNameBox-${box.id}`}
                              className="text-box-tools-container"
                              style={{
                                display: "none",
                              }}
                            ></div>
                          </div>
                        ))}
                      <div
                        ref={editor.editorRef}
                        className="pdf-text-enter-space"
                      ></div>
                    </div>
                  </div>
                  <div className="block-to-type"></div>
                </div>
              ))}
            </section>
          )}

          <MyQuotationTable
            color={color}
            areaTables={areaTables}
            subTotalTables={subTotalTables}
            selectedFontFamily={selectedFontFamily}
            appliedColumns = {appliedColumns}
          />

          {tablePages && tablePages.length > 0 && (
            <section
              className="editor-section"
              style={{
                margin: "0px",
              }}
            >
              {tablePages.map((editor) => (
                <div
                  key={editor.id}
                  className="pdf-text-editor"
                  style={{ zIndex: 0 }}
                >
                  <div
                    className="pdf-text-editor-space"
                    style={{
                      backgroundColor: color.background_color,
                    }}
                  >
                    <div>
                      {editor.textBox &&
                        editor.textBox.map((box) => (
                          <div
                            className="draggable-container"
                            key={box.id}
                            style={{ height: "auto" }}
                          >
                            <div
                              style={{
                                left: box.position.x,
                                top: box.position.y,
                                width: box.width ? box.width : "",
                                height: box.height ? box.height : "",
                                resize: "none",
                                border: "none",
                                padding: "0px",
                                zIndex: `${box.zIndex ? box.zIndex : 1}`,
                                fontSize: box.fontSize
                                  ? `${box.fontSize}px`
                                  : "13px",
                              }}
                              ref={box.textBoxRef}
                              className="pdf-text-box"
                              spellinitialPages={false}
                            ></div>
                            <div
                              id={`textBox-${box.id}`}
                              className="text-box-tools-container"
                              style={{
                                display: "none",
                              }}
                            ></div>
                          </div>
                        ))}

                      {editor.projectNameBox &&
                        editor.projectNameBox.map((box) => (
                          <div
                            className="draggable-container"
                            key={box.id}
                            style={{ height: "auto" }}
                          >
                            <div
                              style={{
                                left: box.position.x,
                                top: box.position.y,
                                width: box.width ? box.width : "",
                                height: box.height ? box.height : "",
                                resize: "none",
                                border: "none",
                                padding: "0px",
                                paddingTop:'10px',
                                zIndex: `${box.zIndex ? box.zIndex : 1}`,
                                fontSize: box.fontSize
                                  ? `${box.fontSize}px`
                                  : "13px",
                              }}
                              ref={box.textBoxRef}
                              className="pdf-text-box"
                              spellinitialPages={false}
                            ></div>
                            <div
                              id={`projectNameBox-${box.id}`}
                              className="text-box-tools-container"
                              style={{
                                display: "none",
                              }}
                            ></div>
                          </div>
                        ))}
                      <div
                        ref={editor.editorRef}
                        className="pdf-text-enter-space"
                      ></div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="block-to-type"></div>
            </section>
          )}
        </article>

        <Sidebar
          interioverseRate={interioverseRate}
          summary={summary}
          updateSave={updateSave}
          areaTables={areaTables}
          subTotalTables={subTotalTables}
          setUpdateSave={setUpdateSave}
          color={color}
          font_family={selectedFontFamily}
          projectId={projectId}
          version={version}
          maxRange={interioverseRate.maxRange}
          userInfo={userInfo}
          isCityFound={isCityFound}
        />
      </section>

      {/* interioverse rate */}
      {pathname.startsWith("/interioverse-quotation") && (
        <section className="interioverse-rate-section">
          <div className="interioverse-rate-content">
            <div className="interioverse-rate-container">
              <p className="base-execution-price">
                Interioverse’s base execution price is:{" "}
                <span className="base-execution-price-value">
                  {" "}
                  {"₹ " + formatIndianNumber(interioverseRate.total)}
                </span>{" "}
                <span className="executing-by-gst-exclusion">
                  excluding GST
                </span>
              </p>
              <p className="interioverse-quotation-value">
                Quotation Value{" "}
                <span>
                  {"₹ " + formatIndianNumber(interioverseRate.mainTotal)}
                </span>{" "}
                <span className="executing-by-gst-exclusion">
                  {`inclusive of GST @${interioverseRate.gst}%`}
                </span>
              </p>
              <div className="my-discount-section">
                <p className="my-discount-info">Discount for your client:</p>

                <div className="range-bar-section">
                  <p className="range-bar-value">
                    {interioverseRate.userRange}%
                  </p>
                  <div className="range-bar">
                    <input
                      type="range"
                      min="0"
                      max={interioverseRate.maxRange}
                      step="1"
                      value={interioverseRate.userRange}
                      onChange={handleRangeChange}
                      className="custom-range"
                    />
                    <div
                      className="range-bar-fill"
                      style={{
                        width: `${
                          (interioverseRate.userRange /
                            interioverseRate.maxRange) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                  <div className="max-range">
                    <p>0</p>
                    <p>{interioverseRate.maxRange}</p>
                  </div>
                </div>
              </div>

              <div className="my-commission-section">
                <p className="my-commission-info">
                  Your Commission:{" "}
                  <span className="my-commission-value">
                    {"₹ " +
                      formatIndianNumber(interioverseRate.designerCommision)}
                  </span>
                </p>
                <p className="my-commission-details">
                  *Your commission includes base commission of 7% and Sales
                  Commission (Discount Difference) if any.
                </p>
              </div>
              <div className="view-new-quotation-button">
                <button onClick={postInterioverseRateByProjectId}>
                  View New Quotation
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      {editorPages &&
        editorPages.concat(tablePages).map((page) => (
          <div
            className="pdf-editor-text-header"
            key={page.id}
            style={{
              display: "none",
            }}
          >
            <p className="pdf-editor-text-title">Page Editor</p>
            <div id={`toolbar-${page.id}`}>
              <button className="ql-customTextBox">
                <MdOutlineTextFields />
              </button>
            </div>
          </div>
        ))}
    </div>
  );
}

export default MyQuotation;
