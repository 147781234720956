import React, { useState, useEffect, useRef, useCallback } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./quotationTemplate.css";
import { IoMdRefresh } from "react-icons/io";
import { HiOutlineMinusSm } from "react-icons/hi";
import { BiSolidColorFill } from "react-icons/bi";
import landingPageImg from "../../assets/Landing_page_picture.jpg";
// import ResizableImage from "../../components/quill-resize-image/quill-resize-image";
import QuotationTable from "../../components/quotation_table/quotation_table";
import AXIOS from "../../services/axios";
import { RiFontFamily } from "react-icons/ri";
import { ImTextWidth } from "react-icons/im";
import { v4 as uuidv4 } from "uuid";
import {
  checkForWarnings,
  fetchUserRates,
  generateTable,
  getPositionAndDimensions,
  getProjectBoxPositionAndDimensions,
  intialRateTable,
} from "../../services/reusableCodes";
import Loading from "../../components/loading/loading";
import PageEditorMedia from "../../components/pageEditorMedia/page_editor_media";
import CustomTextBox from "../../components/customizedQuilTexBox/CustomTextBox";
import CustomProjectNameBox from "../../components/customizedQuilTexBox/customProjectName";
import CustomImage from "../../components/customizedQuilTexBox/customImage";
import { RiDragMoveFill } from "react-icons/ri";
import { HiMinus } from "react-icons/hi";
import { FaPlus, FaRegImage } from "react-icons/fa6";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { FaProductHunt } from "react-icons/fa";
import useScreenWidth from "../../components/useScreenWidth";
import useScreenOrientation from "../../components/useScreenOrientation";
import MobileScreeMessage from "../../components/mobileScreenMessage/mobileScreenMessage";
import { useSelector } from "react-redux";
import { MdDelete,MdOutlineTableRows } from "react-icons/md";
import FinishesTypesPopup from "../../components/finishes_types-popup/finishes-types-popup";
import { useParams } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { TiArrowUpThick, TiArrowDownThick } from "react-icons/ti";
import { FaRegCopy } from "react-icons/fa";

function QuotationTemplate() {
  const [color, setColor] = useState({
    background_color: "#fff7ef",
    table_header: "#f16f40",
    table_font: "#ffffff",
    table_border: "#f16f40",
    table_amount: "#ffffff",
    amount_font: "#636363",
    itemAmountRow: false
  });
  const [selectedFontFamily, setSelectedFontFamily] = useState("Montserrat");
  const inputRefs = {
    table_font: useRef(null),
    table_border: useRef(null),
    table_amount: useRef(null),
    amount_font: useRef(null),
    table_header: useRef(null),
    background_color: useRef(null),
  };
  const fontFamilies = [
    "Montserrat",
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Courier New",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Comic Sans MS",
    "Trebuchet MS",
    "Tahoma",
    "Geneva",
    "Lucida Grande",
    "Calibri",
    "Century Gothic",
  ];
  const [finishes, setFinishes] = useState(false);
  const [editorPages, setEditorPages] = useState([
    {
      id: uuidv4(),
      editorRef: React.createRef(),
      quillRef: React.createRef(),
      textBox: [],
      projectNameBox: [],
    },
  ]);
  const [tablePages, setTablePages] = useState([]);
  const initialTable = generateTable();
  const initialRateTable = intialRateTable(initialTable);
  const [areaTables, setAreaTables] = useState([initialTable]);
  const [rateTables, setRateTables] = useState([initialRateTable]);
  const [subTotalTables, setSubTotalTables] = useState({
    subTotal: "0",
    total: "0",
    taxableAmount: "0",
    gst: {
      name: "",
      percentage: "",
      amount: "0",
    },
    add: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
    minus: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
  });
  const [activePage, setActivePage] = useState(null);
  const [activeBox, setActiveBox] = useState(null);
  const [projectBox, setProjectBox] = useState(null);
  const [initialPages, setInitialPages] = useState([]);
  const [initialBoxes, setInitialBoxes] = useState([]);
  const [initialProjectBoxes, setInitialProjectBoxes] = useState([]);
  const [pageContentData, setPageContentData] = useState({
    content: [],
    pageMerge: "",
  });
  const [updateSave, setUpdateSave] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMedia, setIsMedia] = useState(null);
  const [isWarning, setIsWarning] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isScreenSmall = useScreenWidth(1100);
  const isLandscape = useScreenOrientation();
  const isMobile = useSelector((state) => state.isMobileScreen.isMobile);
  const [selectedColumns, setSelectedColumns] = useState(
    new Set([
      { name: "Height", select: true },
      { name: "Width", select: true },
    ])
  );
  const [appliedColumns, setAppliedColumns] = useState(
    new Set(["Height", "Width"])
  );
  const [editableDescriptions, setEditableDescriptions] = useState([]);
  const [error, setError] = useState("");
  const [finishesData, setFinishesData] = useState([]);
  const [rates, setRates] = useState([]);
  const [userCategories, setUserCategories] = useState([]);
  const { cityName } = useParams();
  const [keyBordActions, setKeyBoardActions] = useState({
    box:"",
    copiedBox: "",
    undoBox: {
      page: "",
      pageId: "",
      box: "",
    },
  });
  const [pressedKeys, setPressedKeys] = useState(new Set());
  const [templatePopup, setTemplatePopup] = useState(false);

  const tableData = async (e) => {
    try {
      const response = await AXIOS.get(
        `/user-template/base-template/${cityName ? cityName : "base template"}`
      );
      // const response = await AXIOS.get("/user-template/base-template");
      if (response.status === 200) {
        const templateData = response.data.baseTemplates[0];

        if (templateData) {
          initializeTemplateData(
            templateData,
            setSubTotalTables,
            setAreaTables,
            setEditorPages,
            setTablePages,
            setActivePage,
            initializeEditors,
            setActiveBox,
            initializeTextBox,
            setInitialPages,
            setInitialBoxes,
            setPageContentData,
            setColor,
            setLoading,
            setProjectBox,
            setInitialProjectBoxes,
            initializeProjectNameBox
          );
        }
      }
    } catch (error) {
      setActivePage(editorPages[0]);
      initializeEditors(editorPages[0]);
      setLoading(false);
    }
  };

  const fetchQuotations = async () => {
    try {
      const response = await AXIOS.get(`/admin/all-quotations`);
      if (response.status === 200) {
        fetchUserRates(setRates, setUserCategories);
        setFinishesData(response.data);
      }
    } catch (error) {
      console.error("Error fetching quotations:", error);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      tableData();
      fetchQuotations();
      const handleGlobalMouseUp = () => {
        setEditorPages((prevEditors) =>
          prevEditors.map((editor) => ({
            ...editor,
            textBox: editor.textBox.map((box) =>
              box.dragging.current
                ? { ...box, dragging: { ...box.dragging, current: false } }
                : box
            ),
            projectNameBox: editor.projectNameBox.map((box) =>
              box.dragging.current
                ? { ...box, dragging: { ...box.dragging, current: false } }
                : box
            ),
          }))
        );

        setTablePages((prevEditors) =>
          prevEditors.map((editor) => ({
            ...editor,
            textBox: editor.textBox.map((box) =>
              box.dragging.current
                ? { ...box, dragging: { ...box.dragging, current: false } }
                : box
            ),
            projectNameBox: editor.projectNameBox.map((box) =>
              box.dragging.current
                ? { ...box, dragging: { ...box.dragging, current: false } }
                : box
            ),
          }))
        );
      };

      document.addEventListener("mouseup", handleGlobalMouseUp);

      return () => {
        document.removeEventListener("mouseup", handleGlobalMouseUp);
      };
    } else {
      setLoading(false);
    }
  }, []);

  const initializeTemplateData = (
    templateData,
    setSubTotalTables,
    setAreaTables,
    setEditorPages,
    setTablePages,
    setActivePage,
    initializeEditors,
    setActiveBox,
    initializeTextBox,
    setInitialPages,
    setInitialBoxes,
    setPageContentData,
    setColor,
    setLoading,
    setProjectBox,
    setInitialProjectBoxes,
    initializeProjectNameBox
  ) => {
    const newSubTotalTables = {
      subTotal: templateData.subTotal || "0",
      total: templateData.total || "0",
      taxableAmount: templateData.taxableAmount || "0",
      gst: templateData.gst || {
        name: "",
        percentage: "",
        amount: "0",
      },
      add: templateData.add || [
        { id: uuidv4(), name: "", percentage: "", amount: "0" },
      ],
      minus: templateData.minus || [
        { id: uuidv4(), name: "", percentage: "", amount: "0" },
      ],
    };

    // Extract areaTables data from templateData
    const newAreaTables = (templateData.template || []).map((table) => ({
      id: table.id || uuidv4(),
      area: (table.areas || []).map((area) => ({
        id: area.area_id || uuidv4(),
        areaValue: area.name || "",
        amount: area.amount || "",
        items: (area.items || []).map((item) => ({
          id: item.item_id || uuidv4(),
          itemValue: item.name || "",
          descriptionValue: item.description || "",
          amount: item.amount || "",
          subItems: (item.subItems || []).map((subItem) => ({
            id: subItem.name_id || uuidv4(),
            subItemValue: subItem.name || "",
            amount: subItem.amount || "",
            category: subItem.category || "",
            finish: subItem.finish || "",
            type: subItem.type || "",
            height: subItem.height || "",
            width: subItem.width || "",
            amount: subItem.amount || "",
            unit: subItem.unit || "",
            range: subItem.range || "",
            rate: subItem.rate || "",
            description: subItem.description || "",
            categoryId: subItem.categoryId || "",
            finishId: subItem.finishId || "",
            typeId: subItem.typeId || "",
            updatedRate: subItem.updatedRate || "",
            warning: subItem.warning || "",
            widthUnit: subItem.widthUnit || "",
            categoryType: subItem.categoryType || "",
          })),
        })),
      })),
    }));

    if (templateData.pagesContent.length > 0) {
      const pagesContent = templateData.pagesContent || [];
      const pageToMerge = parseInt(templateData.pageToMerge, 10);

      const stripHtmlTags = (htmlString) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = htmlString;
        return tempElement.textContent.replace(/\s+/g, " ").trim() || "";
      };

      let newEditorPages = pagesContent.slice(0, pageToMerge).map((page) => ({
        id: page.id,
        editorRef: React.createRef(),
        quillRef: React.createRef(),
        textBox: page.textbox.map((box) => ({
          id: box.id,
          textBoxRef: React.createRef(),
          quillRef: React.createRef(),
          position: JSON.parse(box.position),
          dragging: { current: JSON.parse(box.dragging) },
          offset: { current: JSON.parse(box.offset) },
          resize: box.resize,
          image: JSON.parse(box.image),
          width: box.width,
          height: box.height,
          zIndex: JSON.parse(box.zIndex),
          fontSize: box.fontSize,
          content: stripHtmlTags(box.quillData),
          isTextareaActive: false,
        })),
        projectNameBox:
          page?.projectNameBox?.length > 0
            ? page.projectNameBox.map((box) => ({
                id: box.id,
                textBoxRef: React.createRef(),
                quillRef: React.createRef(),
                position: JSON.parse(box.position),
                dragging: { current: JSON.parse(box.dragging) },
                offset: { current: JSON.parse(box.offset) },
                resize: box.resize,
                image: JSON.parse(box.image),
                width: box.width,
                height: box.height,
                zIndex: JSON.parse(box.zIndex),
                fontSize: box.fontSize,
                content: stripHtmlTags(box.quillData),
                isTextareaActive: false,
              }))
            : [],
      }));

      let newTablePages = pagesContent.slice(pageToMerge).map((page) => ({
        id: page.id,
        editorRef: React.createRef(),
        quillRef: React.createRef(),
        textBox: page.textbox.map((box) => ({
          id: box.id,
          textBoxRef: React.createRef(),
          quillRef: React.createRef(),
          position: JSON.parse(box.position),
          dragging: { current: JSON.parse(box.dragging) },
          offset: { current: JSON.parse(box.offset) },
          resize: box.resize,
          image: JSON.parse(box.image),
          width: box.width,
          height: box.height,
          zIndex: JSON.parse(box.zIndex),
          fontSize: box.fontSize,
          content: stripHtmlTags(box.quillData),
          isTextareaActive: false,
        })),
        projectNameBox:
          page?.projectNameBox?.length > 0
            ? page.projectNameBox.map((box) => ({
                id: box.id,
                textBoxRef: React.createRef(),
                quillRef: React.createRef(),
                position: JSON.parse(box.position),
                dragging: { current: JSON.parse(box.dragging) },
                offset: { current: JSON.parse(box.offset) },
                resize: box.resize,
                image: JSON.parse(box.image),
                width: box.width,
                height: box.height,
                zIndex: JSON.parse(box.zIndex),
                fontSize: box.fontSize,
                content: stripHtmlTags(box.quillData),
                isTextareaActive: false,
              }))
            : [],
      }));

      if (newEditorPages.length > 0) {
        setEditorPages(newEditorPages);
        setActivePage(newEditorPages[0]);
        initializeEditors(newEditorPages[0]);
        setActiveBox(newEditorPages[0].textBox[0]);
        initializeTextBox(newEditorPages[0].textBox[0]);
        setProjectBox(newEditorPages[0].projectNameBox[0]);
        initializeProjectNameBox(newEditorPages[0].projectNameBox[0]);
      }

      // if (newTablePages.length > 0) {
      //   setTablePages(newTablePages||[]);
      //   initializeEditors(newTablePages[0]);
      //   initializeTextBox(newTablePages[0].textBox[0]);
      //   initializeProjectNameBox(newTablePages[0].projectNameBox[0]);
      // }

      setTablePages(newTablePages || []);

      setInitialPages(newEditorPages.concat(newTablePages));

      let boxes = [];
      newEditorPages.concat(newTablePages).forEach((page) => {
        page.textBox.forEach((box) => {
          boxes.push(box);
        });
      });

      setInitialBoxes(boxes);

      let projectBoxes = [];
      newEditorPages.concat(newTablePages).forEach((page) => {
        page.projectNameBox.forEach((box) => {
          projectBoxes.push(box);
        });
      });

      setInitialProjectBoxes(projectBoxes);

      setPageContentData((prev) => ({
        ...prev,
        content: pagesContent,
        pageMerge: pageToMerge,
      }));
    }

    templateData.selected_column = templateData.selected_column || [
      "Height",
      "Width",
    ];

    setAppliedColumns(new Set(templateData.selected_column));
    const updatedColumns = new Set(
      templateData.selected_column.map((name) => ({ name, select: true }))
    );
    setSelectedColumns(updatedColumns);

    setSubTotalTables(newSubTotalTables);
    setAreaTables(newAreaTables);
    setColor({
      background_color: templateData.background_color || "#fff7ef",
      table_header: templateData.table_header || "#f16f40",
      table_font: templateData.table_font || "#ffffff",
      table_border: templateData.table_border || "#f16f40",
      table_amount: templateData.table_amount || "#ffffff",
      amount_font: templateData.amount_font || "#636363",
      itemAmountRow:templateData.item_Amount_Row || false
    });
    setEditableDescriptions(templateData.descriptions);

    if (templateData.pagesContent.length === 0) {
      setActivePage(editorPages[0]);
      initializeEditors(editorPages[0]);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (initialPages) {
      multiInitializeEditors(initialPages);
    }
  }, [initialPages]);

  useEffect(() => {
    if (initialBoxes) {
      multiInitializeTextBox(initialBoxes, "text");
    }
  }, [initialBoxes]);

  useEffect(() => {
    if (initialProjectBoxes) {
      multiInitializeTextBox(initialProjectBoxes, "project");
    }
  }, [initialProjectBoxes]);

  useEffect(() => {
    tableContentData();
  }, [initialPages, initialBoxes, initialProjectBoxes]);

  const multiInitializeEditors = (pages) => {
    pages.forEach((page) => {
      if (page.editorRef.current && !page.quillRef.current) {
        const Font = Quill.import("formats/font");
        Font.whitelist = ["mirza", "roboto", "serif"];
        Quill.register(Font, true);

        const Size = Quill.import("attributors/style/size");
        Size.whitelist = ["10px", "13px", "18px", "24px", "32px", "48px"];
        Quill.register(Size, true);

        const quill = new Quill(page.editorRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `#toolbar-${page.id}`,
              handlers: {
                bold: function () {
                  const isBold = quill.getSelection() && quill.getFormat().bold;
                  quill.format("bold", !isBold);
                },
                italic: function () {
                  const isItalic =
                    quill.getSelection() && quill.getFormat().italic;
                  quill.format("italic", !isItalic);
                },
                underline: function () {
                  const isUnderline =
                    quill.getSelection() && quill.getFormat().underline;
                  quill.format("underline", !isUnderline);
                },
                color: function (value) {
                  console.log(value);
                  if (quill.getSelection()) {
                    quill.format("color", value);
                    quill.format("background", false);
                  }
                },
                background: function (value) {
                  if (quill.getSelection()) {
                    quill.format("background", value);
                  }
                },
                clean: function () {
                  const selection = quill.getSelection();
                  if (selection) {
                    quill.removeFormat(selection.index, selection.length);
                  }
                },
                list: function (value) {
                  const range = quill.getSelection();
                  if (range) {
                    const format = quill.getFormat(range);
                    if (format.list === value) {
                      quill.format("list", false);
                    } else {
                      quill.format("list", value);
                    }
                  }
                },
                indent: function (value) {
                  quill.format("indent", value);
                },
                align: function (value) {
                  quill.format("align", value);
                },
                size: function (value) {
                  const range = quill.getSelection();
                  if (range) {
                    quill.format("size", value);
                  }
                },
                font: function (value) {
                  const currentSelection = quill.getSelection();
                  if (currentSelection) {
                    const { index, length } = currentSelection;
                    quill.formatText(index, length, "font", value);
                    setTimeout(() => {
                      quill.setSelection(index, length);
                    }, 0);
                  }
                },
                customTextBox: () =>
                  quill.getModule("customTextBox").showTextBox(),
                customImage: () => quill.getModule("customImage").showImage(),
                customProjectNameBox: () =>
                  quill.getModule("customProjectNameBox").showTextBox(),
              },
            },
            customTextBox: {
              showTextBox: (range) => {
                const bounds = quill.getBounds(range);
                addTextBox(page.id);
              },
            },

            customImage: {
              showImage: (range) => {
                const bounds = quill.getBounds(range);
                addTextBox(page.id, true);
              },
            },

            customProjectNameBox: {
              showTextBox: (range) => {
                const bounds = quill.getBounds(range);
                addProjectNameBox(page.id);
              },
            },
          },
        });

        page.editorRef.current.setAttribute("spellinitialPages", "false");
        page.quillRef.current = quill;
      }
    });
  };

  const multiInitializeTextBox = (boxes, boxKey) => {
    boxes.forEach((box) => {
      if (box.textBoxRef.current && !box.quillRef.current) {
        const Font = Quill.import("formats/font");
        Font.whitelist = ["mirza", "roboto", "serif"];
        Quill.register(Font, true);

        const quill = new Quill(box.textBoxRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `${
                boxKey === "text"
                  ? `#textBox-${box.id}`
                  : `#projectNameBox-${box.id}`
              }`,
              handlers: {
                bold: function () {
                  const isBold = quill.getSelection() && quill.getFormat().bold;
                  quill.format("bold", !isBold);
                },
                italic: function () {
                  const isItalic =
                    quill.getSelection() && quill.getFormat().italic;
                  quill.format("italic", !isItalic);
                },
                underline: function () {
                  const isUnderline =
                    quill.getSelection() && quill.getFormat().underline;
                  quill.format("underline", !isUnderline);
                },
                color: function (value) {
                  if (box.image) return;
                  if (quill.getSelection()) {
                    quill.format("color", value);
                    quill.format("background", false);
                  }
                },
                background: function (value) {
                  if (box.image) return;
                  if (quill.getSelection()) {
                    quill.format("background", value);
                  }
                },
                // clean: function () {
                //   const selection = quill.getSelection();
                //   if (selection) {
                //     quill.removeFormat(selection.index, selection.length);
                //   }
                // },

                list: function (value) {
                  const range = quill.getSelection();
                  if (range) {
                    const format = quill.getFormat(range);
                    if (format.list === value) {
                      quill.format("list", false);
                    } else {
                      quill.format("list", value);
                    }
                  }
                },
                align: function (value) {
                  quill.format("align", value);
                },
                font: function (value) {
                  if (box.image) return;
                  const currentSelection = quill.getSelection();
                  if (currentSelection) {
                    const { index, length } = currentSelection;
                    quill.formatText(index, length, "font", value);
                    setTimeout(() => {
                      quill.setSelection(index, length);
                    }, 0);
                  }
                },
              },
            },
          },
        });

        box.textBoxRef.current.setAttribute("spellinitialPages", "false");
        box.quillRef.current = quill;
      }
    });
  };

  const tableContentData = async () => {
    try {
      if (
        pageContentData.content &&
        Array.isArray(pageContentData.content) &&
        pageContentData.content.length > 0
      ) {
        const pagesContent = pageContentData.content;
        const pageToMerge = parseInt(pageContentData.pageMerge, 10);

        // Update editorPages
        editorPages.forEach((prevPage) => {
          const matchedPage = pagesContent
            .slice(0, pageToMerge)
            .find((page) => page.id === prevPage.id);
          if (matchedPage) {
            if (prevPage.quillRef.current) {
              prevPage.quillRef.current.root.innerHTML = matchedPage.quillData;
            }

            prevPage.textBox.forEach((prevBox) => {
              const matchedBox = matchedPage.textbox.find(
                (box) => box.id === prevBox.id
              );
              if (matchedBox) {
                if (prevBox.quillRef.current) {
                  prevBox.quillRef.current.root.innerHTML =
                    matchedBox.quillData;
                }
              }
            });

            prevPage.projectNameBox.forEach((prevBox) => {
              const matchedProjectBox = matchedPage.projectNameBox.find(
                (box) => box.id === prevBox.id
              );
              if (matchedProjectBox) {
                if (prevBox.quillRef.current) {
                  prevBox.quillRef.current.root.innerHTML =
                    matchedProjectBox.quillData;
                }
              }
            });
          }
        });

        // Update tablePages
        tablePages.forEach((prevPage) => {
          const matchedPage = pagesContent
            .slice(pageToMerge)
            .find((page) => page.id === prevPage.id);
          if (matchedPage) {
            if (prevPage.quillRef.current) {
              prevPage.quillRef.current.root.innerHTML = matchedPage.quillData;
            }

            prevPage.textBox.forEach((prevBox) => {
              const matchedBox = matchedPage.textbox.find(
                (box) => box.id === prevBox.id
              );
              if (matchedBox) {
                if (prevBox.quillRef.current) {
                  prevBox.quillRef.current.root.innerHTML =
                    matchedBox.quillData;
                }
              }
            });

            prevPage.projectNameBox.forEach((prevBox) => {
              const matchedProjectBox = matchedPage.projectNameBox.find(
                (box) => box.id === prevBox.id
              );
              if (matchedProjectBox) {
                if (prevBox.quillRef.current) {
                  prevBox.quillRef.current.root.innerHTML =
                    matchedProjectBox.quillData;
                }
              }
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDivClick = (inputName) => {
    inputRefs[inputName].current.click();
  };

  const handleFontFamilyChange = (event) => {
    setSelectedFontFamily(event.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setColor({ ...color, [name]: value });
  };

  const handleAddPage = (section) => {
    const id = uuidv4();
    const editorRef = React.createRef();
    const quillRef = React.createRef();
    const textBox = [];
    const projectNameBox = [];

    const newPage = { id, editorRef, quillRef, textBox, projectNameBox };

    if (section === "editor") {
      setEditorPages((prevEditors) => [...prevEditors, newPage]);
    } else if (section === "table") {
      setTablePages((prevPages) => [...prevPages, newPage]);
    }
    setActivePage(newPage);
  };

  const handleRemovePage = (section, id) => {
    if (section === "editor") {
      setEditorPages((prevEditors) => {
        if (prevEditors.length > 1) {
          const activePageIndex = prevEditors.findIndex(
            (page) => page.id === id
          );
          const previousActivePage = prevEditors[activePageIndex - 1];

          if (previousActivePage) {
            setActivePage(previousActivePage);
          }
          return prevEditors.filter((page) => page.id !== id);
        } else {
          // If only one page left, do not remove it
          return prevEditors;
        }
      });
    } else if (section === "table") {
      // setTablePages((prevPages) => prevPages.filter((page) => page.id !== id));
      setTablePages((prevEditors) => {
        if (prevEditors.length >= 0) {
          const activePageIndex = prevEditors.findIndex(
            (page) => page.id === id
          );
          const previousActivePage =
            activePageIndex > 0
              ? prevEditors[activePageIndex - 1]
              : editorPages[editorPages.length - 1];

          if (previousActivePage) {
            setActivePage(previousActivePage);
          }
          return prevEditors.filter((page) => page.id !== id);
        } else {
          // If only one page left, do not remove it
          return prevEditors;
        }
      });
    }
  };

  const addTextBox = (pageId, isImage) => {
    const activeEditor = editorPages.find((page) => page.id === pageId);
    const activeTable = tablePages.find((page) => page.id === pageId);

    // Default position
    let position = { x: 0, y: 0 };

    if (activeEditor) {
      position = getPositionAndDimensions(activeEditor);
    } else if (activeTable) {
      position = getPositionAndDimensions(activeTable);
    }

    let box = {
      id: uuidv4(),
      visible: true,
      content: "",
      textBoxRef: React.createRef(),
      quillRef: React.createRef(),
      position: position, // Set the box's position to cursor location
      dragging: React.createRef(false),
      offset: React.createRef({ x: 0, y: 0 }),
      isTextareaActive: false,
      resize: "",
      isEditable: false,
      image: false,
      width: "",
      height: "",
      zIndex: 2,
      fontSize: "13",
    };

    const updatePages = (setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                textBox: [...page.textBox, box],
              }
            : page
        )
      );
      setActiveBox(box);
    };

    if (activeEditor) updatePages(setEditorPages);
    if (activeTable) updatePages(setTablePages);

    if (isImage) {
      setIsMedia(box);
    }
  };

  const addProjectNameBox = (pageId) => {
    const activeEditor = editorPages.find((page) => page.id === pageId);
    const activeTable = tablePages.find((page) => page.id === pageId);

    // Default position
    let position = { x: 0, y: 0 };

    if (activeEditor) {
      position = getProjectBoxPositionAndDimensions(activeEditor);
    } else if (activeTable) {
      position = getProjectBoxPositionAndDimensions(activeTable);
    }

    let box = {
      id: uuidv4(),
      visible: true,
      content: "Project name placeholder", // Default content
      textBoxRef: React.createRef(),
      quillRef: React.createRef(),
      position: position,
      dragging: React.createRef(false),
      offset: React.createRef({ x: 0, y: 0 }),
      isTextareaActive: false,
      resize: "",
      image: false,
      width: "",
      height: "",
      zIndex: 2,
      fontSize: "13",
    };

    // Set the placeholder content once the Quill editor is initialized
    const initializeQuill = (quillRef) => {
      if (quillRef.current) {
        quillRef.current.root.innerHTML = "Project name placeholder";
      }
    };

    const updatePages = (setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                projectNameBox: [...page.projectNameBox, box],
              }
            : page
        )
      );
      setTimeout(() => initializeQuill(box.quillRef), 0); // Initialize Quill
      setProjectBox(box);
    };

    if (activeEditor) updatePages(setEditorPages);
    if (activeTable) updatePages(setTablePages);
  };

  const textBoxImage = (boxId, width, height) => {
    // Helper function to find and update the page containing the boxId
    const findAndUpdateBoxInPages = (pages, setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) => {
          if (page.textBox.some((box) => box.id === boxId)) {
            return {
              ...page,
              textBox: page.textBox.map((box) =>
                box.id === boxId
                  ? { ...box, image: true, width: width, height: height }
                  : box
              ),
            };
          }
          return page;
        })
      );
    };

    // initialPages in editorPages and update if found
    const pageExistsInEditorPages = editorPages.some((page) =>
      page.textBox.some((box) => box.id === boxId)
    );
    if (pageExistsInEditorPages) {
      findAndUpdateBoxInPages(editorPages, setEditorPages);
      return;
    }

    // initialPages in tablePages and update if found
    const pageExistsInTablePages = tablePages.some((page) =>
      page.textBox.some((box) => box.id === boxId)
    );
    if (pageExistsInTablePages) {
      findAndUpdateBoxInPages(tablePages, setTablePages);
    }
  };

  const initializeEditors = (page) => {
    if (page.editorRef.current && !page.quillRef.current) {
      const Font = Quill.import("formats/font");
      Font.whitelist = ["mirza", "roboto", "serif"];
      Quill.register(Font, true);

      const Size = Quill.import("attributors/style/size");
      Size.whitelist = ["10px", "13px", "18px", "24px", "32px", "48px"];
      Quill.register(Size, true);

      const quill = new Quill(page.editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: `#toolbar-${page.id}`,
            handlers: {
              bold: function () {
                const isBold = quill.getSelection() && quill.getFormat().bold;
                quill.format("bold", !isBold);
              },
              italic: function () {
                const isItalic =
                  quill.getSelection() && quill.getFormat().italic;
                quill.format("italic", !isItalic);
              },
              underline: function () {
                const isUnderline =
                  quill.getSelection() && quill.getFormat().underline;
                quill.format("underline", !isUnderline);
              },
              color: function (value) {
                if (quill.getSelection()) {
                  quill.format("color", value);
                  quill.format("background", false);
                }
              },
              background: function (value) {
                if (quill.getSelection()) {
                  quill.format("background", value);
                }
              },
              // clean: function () {
              //   const selection = quill.getSelection();
              //   if (selection) {
              //     quill.removeFormat(selection.index, selection.length);
              //   }
              // },

              //  image: function () {
              //   setIsMedia(box);
              // },
              list: function (value) {
                const range = quill.getSelection();
                if (range) {
                  const format = quill.getFormat(range);
                  if (format.list === value) {
                    quill.format("list", false);
                  } else {
                    quill.format("list", value);
                  }
                }
              },
              indent: function (value) {
                quill.format("indent", value);
              },
              align: function (value) {
                quill.format("align", value);
              },
              size: function (value) {
                const range = quill.getSelection();
                if (range) {
                  quill.format("size", value);
                }
              },
              font: function (value) {
                const currentSelection = quill.getSelection();
                if (currentSelection) {
                  const { index, length } = currentSelection;
                  quill.formatText(index, length, "font", value);
                  setTimeout(() => {
                    quill.setSelection(index, length);
                  }, 0);
                }
              },
              customTextBox: () =>
                quill.getModule("customTextBox").showTextBox(),
              customImage: () => quill.getModule("customImage").showImage(),
              customProjectNameBox: () =>
                quill.getModule("customProjectNameBox").showTextBox(),
            },
          },
          customTextBox: {
            showTextBox: (range) => {
              const bounds = quill.getBounds(range);
              addTextBox(page.id);
            },
          },
          customImage: {
            showImage: (range) => {
              const bounds = quill.getBounds(range);
              addTextBox(page.id, true);
            },
          },
          customProjectNameBox: {
            showTextBox: (range) => {
              const bounds = quill.getBounds(range);
              addProjectNameBox(page.id);
            },
          },
        },
      });

      page.editorRef.current.setAttribute("spellinitialPages", "false");
      page.quillRef.current = quill;
    }
  };

  const initializeTextBox = (box) => {
    if (box && box.textBoxRef.current && !box.quillRef.current) {

      
      const Font = Quill.import("formats/font");
      Font.whitelist = ["mirza", "roboto", "serif"];
      Quill.register(Font, true);

      const quill = new Quill(box.textBoxRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: `#textBox-${box.id}`,
            handlers: {
              bold: function () {
                const isBold = quill.getSelection() && quill.getFormat().bold;
                quill.format("bold", !isBold);
              },
              italic: function () {
                const isItalic =
                  quill.getSelection() && quill.getFormat().italic;
                quill.format("italic", !isItalic);
              },
              underline: function () {
                const isUnderline =
                  quill.getSelection() && quill.getFormat().underline;
                quill.format("underline", !isUnderline);
              },
              color: function (value) {
                if (box.image) return;
                if (quill.getSelection()) {
                  quill.format("color", value);
                  quill.format("background", false);
                }
              },
              background: function (value) {
                if (box.image) return;
                if (quill.getSelection()) {
                  quill.format("background", value);
                }
              },
              // clean: function () {
              //   const selection = quill.getSelection();
              //   if (selection) {
              //     quill.removeFormat(selection.index, selection.length);
              //   }
              // },

              list: function (value) {
                const range = quill.getSelection();
                if (range) {
                  const format = quill.getFormat(range);
                  if (format.list === value) {
                    quill.format("list", false);
                  } else {
                    quill.format("list", value);
                  }
                }
              },
              align: function (value) {
                quill.format("align", value);
              },
              font: function (value) {
                if (box.image) return;
                const currentSelection = quill.getSelection();
                if (currentSelection) {
                  const { index, length } = currentSelection;
                  quill.formatText(index, length, "font", value);
                  setTimeout(() => {
                    quill.setSelection(index, length);
                  }, 0);
                }
              },
            },
          },
        },
      });

      box.textBoxRef.current.setAttribute("spellinitialPages", "false");
      box.quillRef.current = quill;
    }
  };

  const initializeProjectNameBox = (box) => {
    if (box && box.textBoxRef.current && !box.quillRef.current) {
      const Font = Quill.import("formats/font");
      Font.whitelist = ["mirza", "roboto", "serif"];
      Quill.register(Font, true);

      const quill = new Quill(box.textBoxRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: `#projectNameBox-${box.id}`,
            handlers: {
              bold: function () {
                const contentLength = quill.getLength();
                const isBold = quill.getFormat(0, contentLength).bold;
                quill.formatText(0, contentLength, "bold", !isBold);
              },
              italic: function () {
                const contentLength = quill.getLength();
                const isItalic = quill.getFormat(0, contentLength).italic;
                quill.formatText(0, contentLength, "italic", !isItalic);
              },
              underline: function () {
                const contentLength = quill.getLength();
                const isUnderline = quill.getFormat(0, contentLength).underline;
                quill.formatText(0, contentLength, "underline", !isUnderline);
              },
              color: function (value) {
                const contentLength = quill.getLength();
                if (!box.image) {
                  quill.formatText(0, contentLength, "color", value);
                }
              },
              background: function (value) {
                const contentLength = quill.getLength();
                if (!box.image) {
                  quill.formatText(0, contentLength, "background", value);
                }
              },
              // clean: function () {
              //   const contentLength = quill.getLength();
              //   quill.removeFormat(0, contentLength);
              // },
              list: function (value) {
                const contentLength = quill.getLength();
                const format = quill.getFormat(0, contentLength).list;
                quill.formatText(
                  0,
                  contentLength,
                  "list",
                  format === value ? false : value
                );
              },
              align: function (value) {
                const contentLength = quill.getLength();
                quill.formatText(0, contentLength, "align", value);
              },
              font: function (value) {
                const contentLength = quill.getLength();
                if (!box.image) {
                  quill.formatText(0, contentLength, "font", value);
                }
              },
            },
          },
        },
      });

      box.textBoxRef.current.setAttribute("spellinitialPages", "false");
      box.quillRef.current = quill;
    }
  };

  useEffect(() => {
    if (activePage) {
      initializeEditors(activePage);
    }
  }, [activePage]);

  useEffect(() => {
    if (activeBox) {
      
      initializeTextBox(activeBox);
    }
  }, [activeBox]);

  useEffect(() => {
    if (projectBox) {
      initializeProjectNameBox(projectBox);
    }
  }, [projectBox]);

  useEffect(() => {
      const handleKeyPress = (e) => handleKeyDown(e, keyBordActions);

      const keyUpHandler = handleKeyUp;

      window.addEventListener("keydown", handleKeyPress);
      window.addEventListener("keyup", keyUpHandler);

      // Cleanup function to remove event listeners
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
        window.removeEventListener("keyup", keyUpHandler);
      };
  }, [activePage,keyBordActions]);

  const handleTextBoxChange = (pageId, boxId, boxKey) => {
    const updateTextBoxContent = (pages, boxKey) =>
      pages.map((page) =>
        page.id === pageId
          ? {
              ...page,
              [boxKey]: page[boxKey].map((box) => {
                const strippedContent = stripHtmlTags(
                  box.textBoxRef.current.innerHTML
                );

                return box.id === boxId
                  ? {
                      ...box,
                      content: strippedContent === "" ? "" : strippedContent,
                    }
                  : box;
              }),
            }
          : page
      );

    const stripHtmlTags = (htmlString) => {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlString;
      return tempElement.textContent.replace(/\s+/g, " ").trim() || "";
    };

    setEditorPages((prevEditorPages) =>
      prevEditorPages.some((page) => page.id === pageId)
        ? updateTextBoxContent(prevEditorPages, boxKey)
        : prevEditorPages
    );

    setTablePages((prevTablePages) =>
      prevTablePages.some((page) => page.id === pageId)
        ? updateTextBoxContent(prevTablePages, boxKey)
        : prevTablePages
    );
  };

  const handleActivePage = (page) => {
    if (page && page.id !== activePage?.id)  {
      setActivePage(page);
      initializeEditors(page);
    }
  };
  

  const handleDeleteTextBox = (pageId, deletingBox, boxKey, isMedia) => {
    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === pageId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === pageId
    );

    if (pageExistsInEditorPages) {
      setEditorPages((prevEditorPages) =>
        prevEditorPages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                [boxKey]: page[boxKey].filter(
                  (box) => box.id !== deletingBox.id
                ),
              }
            : page
        )
      );

      if (!isMedia) {
        const copiedBox = {
          ...deletingBox,
          textBoxRef: React.createRef(),
          quillRef: React.createRef(),
        };

        // Copy current DOM content to the new refs
        if (deletingBox.textBoxRef.current) {
          copiedBox.textBoxRef.current = deletingBox.textBoxRef.current;
        }
        if (deletingBox.quillRef.current) {
          copiedBox.quillRef.current = deletingBox.quillRef.current;
        }

        const addToundoBox = {
          page: "editor",
          name:boxKey,
          pageId: pageId,
          box: copiedBox,
        };

        setKeyBoardActions((prevState) => {
          return {
            ...prevState,
            undoBox: addToundoBox, // Append the new box
          };
        });
      }
    }

    if (pageExistsInTablePages) {
      setTablePages((prevEditorPages) =>
        prevEditorPages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                [boxKey]: page[boxKey].filter(
                  (box) => box.id !== deletingBox.id
                ),
              }
            : page
        )
      );

      if (!isMedia) {
        const copiedBox = {
          ...deletingBox,
          textBoxRef: React.createRef(),
          quillRef: React.createRef(),
        };

        // Copy current DOM content to the new refs
        if (deletingBox.textBoxRef.current) {
          copiedBox.textBoxRef.current = deletingBox.textBoxRef.current;
        }
        if (deletingBox.quillRef.current) {
          copiedBox.quillRef.current = deletingBox.quillRef.current;
        }

        const addToundoBox = {
          page: "table",
          name:boxKey,
          pageId: pageId,
          box: copiedBox,
        };

        setKeyBoardActions((prevState) => {
          return {
            ...prevState,
            undoBox: addToundoBox, // Append the new box
          };
        });
      }
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (checkForWarnings(areaTables)) {
      setIsWarning(true);
      setTimeout(() => {
        setIsWarning(false);
      }, 10000);
      return;
    }

    

    const getPageData = (pages) =>
      pages.map((page) => ({
        id: page.id,
        editorData: page.editorRef.current.innerHTML, // Serialize the content
        quillData: page.quillRef.current.root.innerHTML,
        textbox: page.textBox.map((box) => ({
          id: box.id,
          textBoxData: box.textBoxRef.current.innerHTML, // Serialize the content
          quillData: box.quillRef.current.root.innerHTML,
          position: JSON.stringify(box.position),
          dragging: JSON.stringify(box.dragging.current),
          offset: JSON.stringify(box.offset.current),
          resize: box.resize,
          image: JSON.stringify(box.image),
          width: box.width,
          height: box.height,
          zIndex: JSON.stringify(box.zIndex),
          fontSize: box.fontSize,
        })),
        projectNameBox: page.projectNameBox.map((box) => ({
          id: box.id,
          textBoxData: box.textBoxRef.current.innerHTML, // Serialize the content
          quillData: box.quillRef.current.root.innerHTML,
          position: JSON.stringify(box.position),
          dragging: JSON.stringify(box.dragging.current),
          offset: JSON.stringify(box.offset.current),
          resize: box.resize,
          image: JSON.stringify(box.image),
          width: box.width,
          height: box.height,
          zIndex: JSON.stringify(box.zIndex),
          fontSize: box.fontSize,
        })),
      }));

    const htmlContents = [
      ...getPageData(editorPages),
      ...getPageData(tablePages),
    ];

    try {
      setUpdateSave('process');
      const mergedAreaTables = {
        ...subTotalTables,
        areas: areaTables.map((areaTable) => ({ ...areaTable })),
        background_color: color.background_color,
        table_header: color.table_header,
        table_font: color.table_font,
        table_border: color.table_border,
        table_amount: color.table_amount,
        amount_font: color.amount_font,
        itemAmountRow:color.itemAmountRow,
        font_family: selectedFontFamily,
      };

      const selectedColumn = Array.from(appliedColumns);

      const response = await AXIOS.post(
        `/user-template/save-base-template/${
          cityName ? cityName : "base template"
        }`,
        {
          template: mergedAreaTables,
          pagesContent: htmlContents,
          pageToMerge: editorPages.length,
          selectedColumn,

        }
      );

      if (response.status === 200) {
        setUpdateSave("saved");
        setTimeout(() => {
          setUpdateSave(false);
        }, 3000);
      }
    } catch (error) {
      setUpdateSave(false);
    }
  };

  const handleMouseDown = (editorId, boxId, boxKey, e) => {
    const updateDraggingState = (pages, setPages, boxKey) => {
      setPages((prevEditors) =>
        prevEditors.map((editor) =>
          editor.id === editorId
            ? {
                ...editor,
                [boxKey]: editor[boxKey].map((box) =>
                  box.id === boxId
                    ? {
                        ...box,
                        dragging: { ...box.dragging, current: true },
                        offset: {
                          ...box.offset,
                          current: {
                            x: e.clientX - box.position.x,
                            y: e.clientY - box.position.y,
                          },
                        },
                      }
                    : box
                ),
              }
            : editor
        )
      );
    };

    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === editorId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === editorId
    );

    if (pageExistsInEditorPages) {
      updateDraggingState(editorPages, setEditorPages, boxKey);
    }

    if (pageExistsInTablePages) {
      updateDraggingState(tablePages, setTablePages, boxKey);
    }

    // Add global listeners
    document.addEventListener("mousemove", (event) =>
      handleMouseMove(editorId, boxId, boxKey, event)
    );
    document.addEventListener("mouseup", () =>
      handleMouseUp(editorId, boxId, boxKey)
    );
  };

  const handleMouseMove = (editorId, boxId, boxKey, e) => {
    const updatePosition = (pages, setPages, boxKey) => {
      setPages((prevEditors) =>
        prevEditors.map((editor) => {
          if (editor.id === editorId) {
            return {
              ...editor,
              [boxKey]: editor[boxKey].map((box) => {
                if (
                  box.id === boxId &&
                  box.dragging.current &&
                  box.textBoxRef.current
                ) {
                  const newX = e.clientX - box.offset.current.x;
                  const newY = e.clientY - box.offset.current.y;

                  const containerWidth =
                    editor.editorRef.current?.offsetWidth + 60 || 0;
                  const containerHeight =
                    editor.editorRef.current?.offsetHeight + 40 || 0;
                  const elementWidth = box.textBoxRef.current?.offsetWidth || 0;
                  const elementHeight =
                    box.textBoxRef.current?.offsetHeight || 0;

                  const clampedX = Math.max(
                    0,
                    Math.min(newX, containerWidth - elementWidth)
                  );
                  const clampedY = Math.max(
                    0,
                    Math.min(newY, containerHeight - elementHeight)
                  );

                  return {
                    ...box,
                    position: { x: clampedX, y: clampedY },
                  };
                }
                return box;
              }),
            };
          }
          return editor;
        })
      );
    };

    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === editorId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === editorId
    );

    if (pageExistsInEditorPages) {
      updatePosition(editorPages, setEditorPages, boxKey);
    }

    if (pageExistsInTablePages) {
      updatePosition(tablePages, setTablePages, boxKey);
    }
  };

  const handleMouseUp = (editorId, boxId, boxKey) => {
    const updateDraggingState = (setPages, boxKey) => {
      setPages((prevEditors) =>
        prevEditors.map((editor) =>
          editor.id === editorId
            ? {
                ...editor,
                [boxKey]: editor[boxKey].map((box) =>
                  box.id === boxId
                    ? {
                        ...box,
                        dragging: { ...box.dragging, current: false },
                      }
                    : box
                ),
              }
            : editor
        )
      );
    };

    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === editorId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === editorId
    );

    if (pageExistsInEditorPages) {
      updateDraggingState(setEditorPages, boxKey);
    }

    if (pageExistsInTablePages) {
      updateDraggingState(setTablePages, boxKey);
    }

    // Remove global listeners
    document.removeEventListener("mousemove", (event) =>
      handleMouseMove(editorId, boxId, boxKey, event)
    );
    document.removeEventListener("mouseup", () =>
      handleMouseUp(editorId, boxId, boxKey)
    );
  };

  const makeBoxEditable = (editorId, boxId, boxKey) => {
    const updateEditableState = (setPages, boxKey) => {
      setPages((prevEditors) =>
        prevEditors.map((editor) =>
          editor.id === editorId
            ? {
                ...editor,
                [boxKey]: editor[boxKey].map((box) =>
                  box.id === boxId
                    ? {
                        ...box,
                        isEditable: !box.isEditable,
                      }
                    : box
                ),
              }
            : editor
        )
      );
    };

    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === editorId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === editorId
    );

    if (pageExistsInEditorPages) {
      updateEditableState(setEditorPages, boxKey);
    }

    if (pageExistsInTablePages) {
      updateEditableState(setTablePages, boxKey);
    }
  };

  const handleResize = (editorId, boxId, boxKey) => {
    const updateResize = (pages, setPages, boxKey) => {
      setPages((prevEditors) =>
        prevEditors.map((editor) => {
          if (editor.id === editorId) {
            return {
              ...editor,
              [boxKey]: editor[boxKey].map((box) => {
                if (box.id === boxId) {
                  const containerWidth =
                    editor.editorRef.current?.offsetWidth + 60 || 0;
                  const containerHeight =
                    editor.editorRef.current?.offsetHeight + 40 || 0;
                  const elementWidth = box.textBoxRef.current?.offsetWidth || 0;
                  const elementHeight =
                    box.textBoxRef.current?.offsetHeight || 0;

                  const isWithinBounds =
                    box.position.x + elementWidth <= containerWidth &&
                    box.position.y + elementHeight <= containerHeight &&
                    box.isTextareaActive;

                  // Update box width and height based on element dimensions
                  const newWidth = `${elementWidth}px`;
                  const newHeight = `${elementHeight}px`;

                  return {
                    ...box,
                    resize: isWithinBounds ? "both" : "none",
                    width: newWidth,
                    height: newHeight,
                  };
                }
                return box;
              }),
            };
          }
          return editor;
        })
      );
    };

    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === editorId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === editorId
    );

    if (pageExistsInEditorPages) {
      updateResize(editorPages, setEditorPages, boxKey);
    }

    if (pageExistsInTablePages) {
      updateResize(tablePages, setTablePages, boxKey);
    }
  };

  const handleImageZIndex = (type, editorId, boxId, operation) => {
    const updateZIndex = (pages, setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) => {
          if (page.id === editorId) {
            return {
              ...page,
              textBox: page.textBox.map((box) => {
                if (box.id === boxId) {
                  const currentZIndex = box.zIndex || 1;
                  let newZIndex =
                    operation === "plus"
                      ? currentZIndex + 1
                      : currentZIndex - 1;
                  newZIndex = newZIndex < 1 ? 1 : newZIndex; // Ensure zIndex does not go below 10
                  return {
                    ...box,
                    zIndex: newZIndex,
                  };
                }
                return box;
              }),
            };
          }
          return page;
        })
      );
    };

    if (type === "editor") {
      updateZIndex(editorPages, setEditorPages);
    } else if (type === "table") {
      updateZIndex(tablePages, setTablePages);
    }
  };

  const handleFontsize = (type, editorId, boxId, operation, boxKey) => {
    const updateFontSize = (pages, setPages, boxKey) => {
      setPages((prevPages) =>
        prevPages.map((page) => {
          if (page.id === editorId) {
            return {
              ...page,
              [boxKey]: page[boxKey].map((box) => {
                if (box.id === boxId) {
                  const currentFontSize = parseFloat(box.fontSize) || 10;
                  let newFontSize =
                    operation === "plus"
                      ? currentFontSize + 1
                      : currentFontSize - 1;
                  newFontSize = newFontSize < 10 ? 10 : newFontSize; // Ensure zIndex does not go below 10
                  return {
                    ...box,
                    fontSize: newFontSize,
                  };
                }
                return box;
              }),
            };
          }
          return page;
        })
      );
    };

    if (type === "editor") {
      updateFontSize(editorPages, setEditorPages, boxKey);
    } else if (type === "table") {
      updateFontSize(tablePages, setTablePages, boxKey);
    }
  };

  const handleFontSizeInput = (type, editorId, boxId, newFontSize, boxKey) => {
    const updateFontSize = (pages, setPages, boxKey) => {
      setPages((prevPages) =>
        prevPages.map((page) => {
          if (page.id === editorId) {
            return {
              ...page,
              [boxKey]: page[boxKey].map((box) => {
                if (box.id === boxId) {
                  // Parse the new font size as float or fallback to default 10
                  const parsedFontSize = newFontSize
                    ? parseFloat(newFontSize)
                    : "";
                  return {
                    ...box,
                    fontSize: parsedFontSize.toString(), // Ensure it's stored as string if needed
                  };
                }
                return box;
              }),
            };
          }
          return page;
        })
      );
    };

    if (type === "editor") {
      updateFontSize(editorPages, setEditorPages, boxKey);
    } else if (type === "table") {
      updateFontSize(tablePages, setTablePages, boxKey);
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      const updateState = (pages, setPages, editorId) => {
        setPages((prevPages) =>
          prevPages.map((page) => {
            if (page.id === editorId) {
              return {
                ...page,
                textBox: page.textBox.map((box) => {
                  if (box.isTextareaActive && box.textBoxRef.current) {
                    const toolsContainer = document.getElementById(
                      `textBox-${box.id}`
                    );
                    if (
                      !box.textBoxRef.current.contains(event.target) &&
                      (!toolsContainer ||
                        !toolsContainer.contains(event.target))
                    ) {
                      return {
                        ...box,
                        isTextareaActive: false,
                        isEditable: false,
                      };
                    }
                  }
                  return box;
                }),
                projectNameBox: page.projectNameBox.map((box) => {
                  if (box.isTextareaActive && box.textBoxRef.current) {
                    const toolsContainer = document.getElementById(
                      `projectNameBox-${box.id}`
                    );
                    if (
                      !box.textBoxRef.current.contains(event.target) &&
                      (!toolsContainer ||
                        !toolsContainer.contains(event.target))
                    ) {
                      return {
                        ...box,
                        isTextareaActive: false,
                        isEditable: false,
                      };
                    }
                  }
                  return box;
                }),
              };
            }
            return page;
          })
        );
      };

      editorPages.forEach((editorPage) => {
        updateState(editorPages, setEditorPages, editorPage.id);
      });

      tablePages.forEach((tablePage) => {
        updateState(tablePages, setTablePages, tablePage.id);
      });

    },
    [activePage]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleAdminTemplate = async (id, isNeeded) => {
    try {
      const response = await AXIOS.get(`/user-template/get-admin-template`, {
        params: {
          templateId: id,
          cityName: cityName ? cityName : "base template",
          isNeeded: isNeeded,
        },
      });
      if (response.status === 200) {
        const templateData = response.data.Templates[0];

        if (templateData) {
          initializeTemplateData(
            templateData,
            setSubTotalTables,
            setAreaTables,
            setEditorPages,
            setTablePages,
            setActivePage,
            initializeEditors,
            setActiveBox,
            initializeTextBox,
            setInitialPages,
            setInitialBoxes,
            setPageContentData,
            setColor,
            setLoading,
            setProjectBox,
            setInitialProjectBoxes,
            initializeProjectNameBox
          );
          setTemplatePopup(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleColumn = (columnName) => {
    setSelectedColumns((prev) => {
      const newSelection = new Set(prev);
      const existingColumn = Array.from(newSelection).find(
        (col) => col.name === columnName
      );

      if (existingColumn) {
        // If column is already selected, remove it
        newSelection.delete(existingColumn);

        newSelection.forEach((col) => {
          col.select = false; // Set select to false for all columns
        });

        if (newSelection.size < 2) {
          setError("min");
        } else {
          setError("");
        }
      } else {
        // If column is not selected, add it
        if (newSelection.size >= 3) {
          setError("max");
        } else {
          newSelection.add({ name: columnName, select: false });
          setError("");
        }
      }

      return newSelection;
    });
  };

  const applyColumns = () => {
    // Create a new Set from selectedColumns with all select properties set to true
    const updatedColumns = new Set(
      Array.from(selectedColumns).map((col) => ({ ...col, select: true }))
    );

    // Set the updated columns to selectedColumns
    setSelectedColumns(updatedColumns);

    // Get the column names and update appliedColumns
    const columnNames = Array.from(updatedColumns).map((col) => col.name);
    setAppliedColumns(new Set(columnNames));
  };
  

  const handleKeyDown = (e, activeBox) => {
    // Allow long press for Ctrl but prevent repeated actions for specific keys
    const ctrlPressed = e.ctrlKey;
    const keyPressed = e.key.toLowerCase();

    // If the key is already processed, do nothing
    // if (ctrlPressed && pressedKeys.has(keyPressed)) {
    //   return;
    // }

    // console.log("activeBox",activeBox);
    

    // Add the key to the pressedKeys set
    setPressedKeys((prev) => new Set(prev).add(keyPressed));

    // Handle specific key actions
    if (ctrlPressed) {
      switch (keyPressed) {
        case "c":
          handleCopy(e, activeBox.box);
          break;
        case "v":
          handlePaste(e,activeBox.copiedBox);
          break;
        case "x":
          handleCut(e, activeBox.box);
          break;
        case "z":
          handleUndo(e);
          break;
        default:
          // Allow Ctrl to be held down without action
          break;
      }
    }
  };

  const handleKeyUp = (e) => {
    const keyReleased = e.key.toLowerCase();

    // Remove the key from the pressedKeys set when released
    setPressedKeys((prev) => {
      const newSet = new Set(prev);
      newSet.delete(keyReleased);
      return newSet;
    });
  };

  const handleCopy = (e, activeBox) => {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      // Allow normal copy behavior for selected text
      return;
    }

    e.preventDefault(); // Prevent default copy behavior


    if (activeBox) {
      const copiedBox = activeBox;
      setKeyBoardActions((prev) => ({ ...prev, copiedBox }));
    }
  };

  const handlePaste = (e,copiedBox) => {
    e.preventDefault(); // Prevent default paste behavior
    if (copiedBox) {
      PasteTextBox(activePage.id,copiedBox);
    }
  };

  const handleCut = (e, activeBox) => {
    e.preventDefault(); // Prevent default cut behavior

    if (activeBox) {
      const copiedBox = {
        ...activeBox,
        textBoxRef: React.createRef(),
        quillRef: React.createRef(),
      };

      if (activeBox.textBoxRef.current) {
        copiedBox.textBoxRef.current = activeBox.textBoxRef.current;
      }
      if (activeBox.quillRef.current) {
        copiedBox.quillRef.current = activeBox.quillRef.current;
      }

      setKeyBoardActions((prev) => ({ ...prev, copiedBox }));
      handleDeleteTextBox(activePage.id, copiedBox, activeBox.name);
    }
  };

  const handleUndo = (e) => {
    e.preventDefault(); // Prevent default undo behavior

    setKeyBoardActions((prevState) => {
      // Destructure undoBox from the previous state
      const { undoBox } = prevState;
      

      // If undoBox is empty, return the same state
      if (!undoBox) {
        return prevState;
      }

      // const lastUndo = undoBox[undoBox.length - 1];

      // undoTextBox(lastUndo.page, lastUndo.pageId, lastUndo.box);
      undoTextBox(undoBox.name, undoBox.pageId, undoBox.box);

      return {
        ...prevState,
        undoBox: {
          page: "",
          pageId: "",
          box: "",
        },
      };
    });
  };

  const PasteTextBox = (pageId, copiedBox) => {
    const activeEditor = editorPages.find((page) => page.id === pageId);
    const activeTable = tablePages.find((page) => page.id === pageId);

    // Default position
    let position = { x: 0, y: 0 };

    if (activeEditor) {
      position = getPositionAndDimensions(activeEditor);
    } else if (activeTable) {
      position = getPositionAndDimensions(activeTable);
    }

    let box = {
      id: uuidv4(),
      visible: copiedBox.visible,
      content: copiedBox.content,
      textBoxRef: React.createRef(),
      quillRef: React.createRef(),
      position: position, // Set the box's position to cursor location
      dragging: copiedBox.dragging,
      offset: copiedBox.offset,
      isTextareaActive: copiedBox.isTextareaActive,
      resize: copiedBox.resize,
      isEditable: copiedBox.isEditable,
      image: copiedBox.image,
      width: copiedBox.width,
      height: copiedBox.height,
      zIndex: copiedBox.zIndex,
      fontSize: copiedBox.fontSize,
    };

    const updatePages = (setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                [copiedBox.name]: [...page[copiedBox.name], box],
              }
            : page
        )
      );

    };

    if (activeEditor) updatePages(setEditorPages);
    if (activeTable) updatePages(setTablePages);
    // Copy content and tags from the copiedBox after rendering
    setTimeout(() => {
      if (copiedBox.textBoxRef?.current && box.textBoxRef?.current) {
        box.textBoxRef.current.innerHTML =
          copiedBox.textBoxRef.current.innerHTML; // Copy all tags and content
      }
      if (copiedBox.quillRef?.current && box.quillRef?.current) {
        box.quillRef.current.root.innerHTML = copiedBox.quillRef.current.root.innerHTML; 
        
      }

      if (copiedBox.name==='textBox') {
        setActiveBox(box);
      }else{
        setProjectBox(box);
      }
    }, 0);
  };

  const undoTextBox = (name, pageId, copiedBox) => {
    const activeEditor = editorPages.find((page) => page.id === pageId);
    const activeTable = tablePages.find((page) => page.id === pageId);

    let box = {
      id: uuidv4(),
      visible: copiedBox.visible,
      content: copiedBox.content,
      textBoxRef: React.createRef(),
      quillRef: React.createRef(),
      position: copiedBox.position,
      dragging: copiedBox.dragging,
      offset: copiedBox.offset,
      isTextareaActive: copiedBox.isTextareaActive,
      resize: copiedBox.resize,
      isEditable: copiedBox.isEditable,
      image: copiedBox.image,
      width: copiedBox.width,
      height: copiedBox.height,
      zIndex: copiedBox.zIndex,
      fontSize: copiedBox.fontSize,
    };

    const updatePages = (setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                [name]: [...page[name], box],
              }
            : page
        )
      );

    };

    if (activeEditor) updatePages(setEditorPages);
    if (activeTable) updatePages(setTablePages);
    // Copy content and tags from the copiedBox after rendering
    setTimeout(() => {
      if (copiedBox.textBoxRef?.current && box.textBoxRef?.current) {
        box.textBoxRef.current.innerHTML =
          copiedBox.textBoxRef.current.innerHTML; // Copy all tags and content
      }
      if (copiedBox.quillRef?.current && box.quillRef?.current) {
        box.quillRef.current.root.innerHTML = copiedBox.quillRef.current.root.innerHTML
      }

      if (name==='textBox') {
        setActiveBox(box);
      }else{
        setProjectBox(box)
      }
    }, 0);
  };

  const handleMovePage = (pageName, index, direction) => {
    const updatePages = (pages, setPages) => {
      const updatedPages = [...pages];

      if (direction === "up" && index > 0) {
        // Swap current page with the previous page
        [updatedPages[index], updatedPages[index - 1]] = [
          updatedPages[index - 1],
          updatedPages[index],
        ];
      } else if (direction === "down" && index < updatedPages.length - 1) {
        // Swap current page with the next page
        [updatedPages[index], updatedPages[index + 1]] = [
          updatedPages[index + 1],
          updatedPages[index],
        ];
      }

      setPages(updatedPages);
    };

    if (pageName === "editor") {
      updatePages(editorPages, setEditorPages);
    } else if (pageName === "table") {
      updatePages(tablePages, setTablePages);
    }
  };

  const handleCopyPage = (pageName, index, copiedPage) => {
    let newPage;
  
    const copyPage = (pages, setPages) => {
      // Create a new page with copied properties
      newPage = {
        id: uuidv4(),
        editorRef: React.createRef(),
        quillRef: React.createRef(),
        // Map through textBox and projectNameBox to ensure deep copy and updating
        textBox: copiedPage.textBox.map((box) => {
          return {
            id: uuidv4(), // New ID for each copied box
            visible: box.visible,
            content: box.content,
            textBoxRef: React.createRef(),
            quillRef: React.createRef(),
            position: { ...box.position }, // Ensure position is copied
            dragging: box.dragging,
            offset: box.offset,
            isTextareaActive: box.isTextareaActive,
            resize: box.resize,
            isEditable: box.isEditable,
            image: box.image,
            width: box.width,
            height: box.height,
            zIndex: box.zIndex,
            fontSize: box.fontSize,
          };
        }),
        projectNameBox: copiedPage.projectNameBox.map((box) => {
          return {
            id: uuidv4(), // New ID for each copied box
            visible: box.visible,
            content: box.content,
            textBoxRef: React.createRef(),
            quillRef: React.createRef(),
            position: { ...box.position }, // Ensure position is copied
            dragging: box.dragging,
            offset: box.offset,
            isTextareaActive: box.isTextareaActive,
            resize: box.resize,
            isEditable: box.isEditable,
            image: box.image,
            width: box.width,
            height: box.height,
            zIndex: box.zIndex,
            fontSize: box.fontSize,
          };
        }),
      };
  
      const updatedPages = [
        ...pages.slice(0, index + 1),
        newPage,
        ...pages.slice(index + 1),
      ];
  
      
      setPages(updatedPages);
      

    };
  
    // Call the copyPage function for either editor or table page
    if (pageName === "editor") {
      copyPage(editorPages, setEditorPages);
    } else if (pageName === "table") {
      copyPage(tablePages, setTablePages);
    }

    setTimeout(() => {

      // Copy innerHTML and Quill content for the editor and quill references
      if (copiedPage.editorRef?.current && newPage.editorRef?.current) {
        newPage.editorRef.current.innerHTML = copiedPage.editorRef.current.innerHTML;
      }

      if (copiedPage.quillRef?.current && newPage.quillRef?.current) {
        newPage.quillRef.current.root.innerHTML = copiedPage.quillRef.current.root.innerHTML;
      }

      // Copy content for each textBox (update newPage.textBox)
      copiedPage.textBox.forEach((copiedBox, i) => {
        const newBox = newPage.textBox[i]; // Get the corresponding new box
        if (copiedBox.textBoxRef?.current && newBox.textBoxRef?.current) {
          newBox.textBoxRef.current.innerHTML = copiedBox.textBoxRef.current.innerHTML; // Copy innerHTML content
        }

        if (copiedBox.quillRef?.current && newBox.quillRef?.current) {
         
          newBox.quillRef.current.root.innerHTML = copiedBox.quillRef.current.root.innerHTML; // Copy Quill content
        }
      });

      // Copy content for each projectNameBox (update newPage.projectNameBox)
      copiedPage.projectNameBox.forEach((copiedBox, i) => {
        const newBox = newPage.projectNameBox[i]; // Get the corresponding new box
        if (copiedBox.textBoxRef?.current && newBox.textBoxRef?.current) {
          newBox.textBoxRef.current.innerHTML = copiedBox.textBoxRef.current.innerHTML; // Copy innerHTML content
        }

        if (copiedBox.quillRef?.current && newBox.quillRef?.current) {
          newBox.quillRef.current.root.innerHTML = copiedBox.quillRef.current.root.innerHTML; // Copy Quill content
        }
      });
      setActivePage(newPage)
      setInitialBoxes(newPage.textBox)
      setInitialProjectBoxes(newPage.projectNameBox)
    }, 100);
  };

  

  if (loading) {
    return <Loading />;
  }

  if (isMobile) {
    return <MobileScreeMessage />;
  }

  return (
    <div>
      {isMedia && (
        <PageEditorMedia
          setIsMedia={setIsMedia}
          isMedia={isMedia}
          activePage={activePage}
          handleDeleteTextBox={handleDeleteTextBox}
          textBoxImage={textBoxImage}
        />
      )}

      {finishes && (
        <FinishesTypesPopup
          setFinishes={setFinishes}
          editableDescriptions={editableDescriptions}
          setEditableDescriptions={setEditableDescriptions}
          fetchQuotations={fetchQuotations}
          tableData={tableData}
        />
      )}

      {templatePopup && (
        <section className="version-delete-popup">
          <div className="version-delete-container">
            <IoMdClose
              className="close-popup"
              onClick={() => setTemplatePopup(false)}
            />
            <div className="version-delete-content">
              <p className="version-delete-label">{`What sections do you need from Template ${
                templatePopup === "template01"
                  ? 1
                  : templatePopup === "template02"
                  ? 2
                  : templatePopup === "template03"
                  ? 3
                  : 4
              } ?`}</p>
              <div className="version-button-group">
                <button
                  className="version-delete-button"
                  onClick={() => handleAdminTemplate(templatePopup, "yes")}
                >
                  PDf Content with Table
                </button>
                <button
                  className="version-cancel-button"
                  onClick={() => handleAdminTemplate(templatePopup, "no")}
                >
                  PDF Content Only
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      <figure>
        <img
          src={landingPageImg}
          alt="Cover img"
          className="partner-cover-img"
        />
      </figure>

      <section
        className={`quotation-template ${isLandscape ? "landscape" : ""}`}
      >
        <article
          className={`quotation-template-container ${
            isScreenSmall
              ? !isSidebarOpen
                ? "position-adjustment-container"
                : ""
              : ""
          }`}
        >
          {/* Pdf text entering space  */}
          <section className="editor-section">
            {editorPages.map((editor, editorIndex) => (
              <div
                key={editor.id}
                className="pdf-text-editor"
                onClick={() => handleActivePage(editor)}
                spellCheck={false}
              >
                <div
                  className="pdf-text-editor-space"
                  style={{ backgroundColor: color.background_color }}
                >
                  <div className="ql-container ql-snow">
                    {editor.textBox &&
                      editor.textBox.map((box) => (
                        <div
                          className="draggable-container ql-container ql-snow"
                          onMouseDown={(e) =>
                            !box.isEditable &&
                            handleMouseDown(editor.id, box.id, "textBox", e)
                          }
                          onMouseMove={(e) =>
                            !box.isEditable &&
                            handleMouseMove(editor.id, box.id, "textBox", e)
                          }
                          onMouseUp={(e) =>
                            !box.isEditable &&
                            handleMouseUp(editor.id, box.id, "textBox")
                          }
                          
                          key={box.id}
                          style={{ height: "auto" }}
                          onClick={() => {
                            if (box.id !== activeBox?.id) { 
                              
                              setActiveBox(box);
                            }

                            if (box.id !== keyBordActions.box?.id) {
                              setKeyBoardActions((prev)=>({...prev,box:{...box,name:'textBox'}}))
                            }
                            
                          }}
                        >
                          {!box.isEditable && box.image && <div style={{
                            position:'absolute',
                            left: box.position.x,
                              top: box.position.y,
                              width: box.width ? box.width : "",
                              height: box.height ? box.height : "",
                              marginTop:'-5px',
                              zIndex: `${box.zIndex ? box.zIndex+1 : 2}`,
                              cursor:'grab'

                          }}
                          onDoubleClick={(e) =>{
                            makeBoxEditable(editor.id, box.id, "textBox")
                            setEditorPages((prevEditors) =>
                                prevEditors.map((ed) =>
                                  ed.id === editor.id
                                    ? {
                                        ...ed,
                                        textBox: ed.textBox.map((tb) =>
                                          tb.id === box.id
                                            ? { ...tb, isTextareaActive: true }
                                            : tb
                                        ),
                                      }
                                    : ed
                                )
                              )
                          }}
                          
                          >
                           
                          </div>}

                          <div
                            style={{
                              left: box.position.x,
                              top: box.position.y,
                              width: box.width ? box.width : "",
                              height: box.height ? box.height : "",
                              resize: box.isEditable
                                ? box.resize || "none"
                                : "none",
                              border:
                                box.content && box.isTextareaActive
                                  ? `2px solid ${
                                      !box.isEditable ? "black" : "#f9912d"
                                    }`
                                  : (box.content.trim().length <= 1 &&
                                      !box.image) ||
                                    (box.isTextareaActive && box.image)
                                  ? `2px solid ${
                                      !box.isEditable ? "black" : "#f9912d"
                                    }`
                                  : "none",

                              padding: "0px",
                              zIndex: `${box.zIndex ? box.zIndex : 1}`,
                              fontSize: box.fontSize
                                ? `${box.fontSize}px`
                                : "13px",
                            
                            }}
                            ref={box.textBoxRef}
                            className={`pdf-text-box ${
                              !box.image && "ql-editor ql-blank"
                            }`}
                            spellinitialpages="false"
                            onDoubleClick={(e) =>
                              makeBoxEditable(editor.id, box.id, "textBox")
                            }
                            
                            onMouseMove={(e) => {
                              
                              handleResize(editor.id, box.id, "textBox");
                            }}
                            onMouseUp={() =>
                              handleResize(editor.id, box.id, "textBox")
                            }
                            onFocus={() =>
                              setEditorPages((prevEditors) =>
                                prevEditors.map((ed) =>
                                  ed.id === editor.id
                                    ? {
                                        ...ed,
                                        textBox: ed.textBox.map((tb) =>
                                          tb.id === box.id
                                            ? { ...tb, isTextareaActive: true }
                                            : tb
                                        ),
                                      }
                                    : ed
                                )
                              )
                            }
                            onInput={() =>
                              !box.image &&
                              handleTextBoxChange(editor.id, box.id, "textBox")
                            }
                          ></div>
                          <div
                            id={`textBox-${box.id}`}
                            className="text-box-tools-container"
                            style={{
                              left: box.position.x,
                              top: box.position.y,
                              display: ` ${
                                box.isTextareaActive ? "block" : "none"
                              }`,
                              marginLeft: box.width,
                              backgroundColor: color.background_color,
                              zIndex: `${box.zIndex ? box.zIndex : 1}`,
                              border: "1.5px solid rgb(222, 213, 213)",
                              borderRadius: "0 10px 10px 0px",
                            }}
                          >
                            <div>
                              {!box.image && (
                                <div>
                                  <button className="ql-bold"></button>

                                  <button className="ql-italic"></button>

                                  <button className="ql-underline"></button>
                                  <select className="ql-color"></select>

                                  <select className="ql-background"></select>

                                  <button
                                    className="ql-list"
                                    value="ordered"
                                  ></button>

                                  <button
                                    className="ql-list"
                                    value="bullet"
                                  ></button>

                                  <button
                                    className="ql-align"
                                    value=""
                                  ></button>

                                  <button
                                    className="ql-align"
                                    value="center"
                                  ></button>

                                  <button
                                    className="ql-align"
                                    value="right"
                                  ></button>

                                  <MdDelete
                                    className="textbox-delete-icon"
                                    onClick={() =>
                                      handleDeleteTextBox(
                                        editor.id,
                                        box,
                                        "textBox"
                                      )
                                    }
                                  />

                                  <div className="text-box-font-size">
                                    <HiMinus
                                      className="font-size-icon"
                                      onClick={() =>
                                        handleFontsize(
                                          "editor",
                                          editor.id,
                                          box.id,
                                          "minus",
                                          "textBox"
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      value={box.fontSize}
                                      placeholder="0"
                                      onInput={(e) =>
                                        handleFontSizeInput(
                                          "editor",
                                          editor.id,
                                          box.id,
                                          e.target.value.replace(/[^\d]/g, ""),
                                          "textBox"
                                        )
                                      }
                                    />

                                    <FaPlus
                                      className="font-size-icon"
                                      onClick={() =>
                                        handleFontsize(
                                          "editor",
                                          editor.id,
                                          box.id,
                                          "plus",
                                          "textBox"
                                        )
                                      }
                                    />
                                  </div>

                                  <select className="ql-font">
                                    <option value="mirza">Mirza</option>
                                    <option value="roboto">Roboto</option>
                                    <option value="serif">Serif</option>
                                  </select>
                                </div>
                              )}
                              {box.image && (
                                <div>
                                  <FaPlus
                                    className="image-z-index-icon"
                                    onClick={() =>
                                      handleImageZIndex(
                                        "editor",
                                        editor.id,
                                        box.id,
                                        "plus"
                                      )
                                    }
                                  />
                                  <HiMinus
                                    className="image-z-index-icon"
                                    onClick={() =>
                                      handleImageZIndex(
                                        "editor",
                                        editor.id,
                                        box.id,
                                        "minus"
                                      )
                                    }
                                  />
                                  <MdDelete
                                    className="image-z-index-icon image-textbox-delete-icon"
                                    onClick={() =>
                                      handleDeleteTextBox(
                                        editor.id,
                                        box,
                                        "textBox"
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}

                    {editor.projectNameBox &&
                      editor.projectNameBox.map((box) => (
                        <div
                          className="draggable-container ql-container ql-snow"
                          onMouseDown={(e) =>
                            !box.isEditable &&
                            handleMouseDown(
                              editor.id,
                              box.id,
                              "projectNameBox",
                              e
                            )
                          }
                          onMouseMove={(e) =>
                            !box.isEditable &&
                            handleMouseMove(
                              editor.id,
                              box.id,
                              "projectNameBox",
                              e
                            )
                          }
                          onMouseUp={(e) =>
                            !box.isEditable &&
                            handleMouseUp(editor.id, box.id, "projectNameBox")
                          }
                          
                          key={box.id}
                          style={{ height: "auto" }}
                          onClick={() => {
                            // First, set the project box
                           
                              if (box.id !== projectBox?.id) { 
                                setProjectBox(box);
                              }

                              if (box.id !== keyBordActions.box?.id) {
                                setKeyBoardActions((prev)=>({...prev,box:{...box,name:'projectNameBox'}}))
                              }
                           

                            // Then update the editor pages
                            setEditorPages((prevEditors) =>
                              prevEditors.map((ed) =>
                                ed.id === editor.id
                                  ? {
                                      ...ed,
                                      projectNameBox: ed.projectNameBox.map(
                                        (tb) =>
                                          tb.id === box.id
                                            ? { ...tb, isTextareaActive: true }
                                            : tb
                                      ),
                                    }
                                  : ed
                              )
                            );
                          }}
                        >
                          <div
                            style={{
                              left: box.position.x,
                              top: box.position.y,
                              width: `${
                                box.fontSize * 15 < 700
                                  ? box.fontSize * 15
                                  : 700
                              }px`,
                              height: `${
                                box.fontSize * 2.3 < 95
                                  ? box.fontSize * 2.3
                                  : 95
                              }px`,
                              resize: "none",
                              border:
                                box.content &&
                                box.isTextareaActive &&
                                !box.image
                                  ? `2px solid ${
                                      !box.isEditable ? "black" : "black"
                                    }`
                                  : box.content.trim().length <= 1 &&
                                    !box.image &&
                                    `2px solid ${
                                      !box.isEditable ? "black" : "black"
                                    }`,
                              padding: "0px",
                              paddingTop: "10px",
                              zIndex: `${box.zIndex ? box.zIndex : 1}`,
                              fontSize: box.fontSize
                                ? `${box.fontSize < 50 ? box.fontSize : 50}px`
                                : "13px",
                              caretColor: "transparent", // Hide text cursor
                              userSelect: "none", // Disable text selection
                            }}
                            ref={box.textBoxRef}
                            className={`pdf-text-box ${
                              !box.image && "ql-editor ql-blank"
                            }`}
                            contentEditable={true}
                            spellCheck="false"
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              // Prevent all typing and deletion
                              if (
                                e.key !== "ArrowLeft" &&
                                e.key !== "ArrowRight" &&
                                e.key !== "ArrowUp" &&
                                e.key !== "ArrowDown"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onInput={(e) => {
                              // Prevent any changes to the content
                              e.target.innerHTML = box.content;
                            }}
                            onDoubleClick={(e) =>
                              makeBoxEditable(editor.id, box.id, "projectNameBox")
                            }
                            onMouseMove={(e) => {
                             
                              handleResize(editor.id, box.id, "projectNameBox");
                            }}
                            onMouseUp={() =>
                              handleResize(editor.id, box.id, "projectNameBox")
                            }
                          ></div>

                          <div
                            id={`projectNameBox-${box.id}`}
                            className="text-box-tools-container"
                            style={{
                              left: box.position.x,
                              top: box.position.y,
                              display: ` ${
                                box.isTextareaActive ? "block" : "none"
                              }`,
                              marginLeft: box.width,
                              backgroundColor: color.background_color,
                              zIndex: `${box.zIndex ? box.zIndex : 1}`,
                              border: "1.5px solid rgb(222, 213, 213)",
                              borderRadius: "0 10px 10px 0px",
                            }}
                          >
                            <div>
                              {!box.image && (
                                <div>
                                  <button className="ql-bold"></button>

                                  <button className="ql-italic"></button>

                                  <button className="ql-underline"></button>
                                  <select className="ql-color"></select>

                                  <select className="ql-background"></select>

                                  <button
                                    className="ql-align"
                                    value=""
                                  ></button>

                                  <button
                                    className="ql-align"
                                    value="center"
                                  ></button>

                                  <button
                                    className="ql-align"
                                    value="right"
                                  ></button>

                                  <MdDelete
                                    className="textbox-delete-icon"
                                    onClick={() =>
                                      handleDeleteTextBox(
                                        editor.id,
                                        box,
                                        "projectNameBox"
                                      )
                                    }
                                  />

                                  <div className="text-box-font-size">
                                    <HiMinus
                                      className="font-size-icon"
                                      onClick={() =>
                                        handleFontsize(
                                          "editor",
                                          editor.id,
                                          box.id,
                                          "minus",
                                          "projectNameBox"
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      value={
                                        box.fontSize < 50 ? box.fontSize : 50
                                      }
                                      placeholder="0"
                                      onInput={(e) =>
                                        handleFontSizeInput(
                                          "editor",
                                          editor.id,
                                          box.id,
                                          e.target.value.replace(/[^\d]/g, ""),
                                          "projectNameBox"
                                        )
                                      }
                                    />

                                    <FaPlus
                                      className="font-size-icon"
                                      onClick={() =>
                                        handleFontsize(
                                          "editor",
                                          editor.id,
                                          box.id,
                                          "plus",
                                          "projectNameBox"
                                        )
                                      }
                                    />
                                  </div>

                                  <select className="ql-font">
                                    <option value="mirza">Mirza</option>
                                    <option value="roboto">Roboto</option>
                                    <option value="serif">Serif</option>
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    <div
                      ref={editor.editorRef}
                      className="pdf-text-enter-space ql-editor ql-blank"
                    ></div>
                  </div>
                </div>
                <div className="page-tool-icons ">
                  <HiOutlineMinusSm
                    className="page-minus-icon"
                    onClick={() => handleRemovePage("editor", editor.id)}
                  />
                  {editorIndex !== 0 && (
                    <TiArrowUpThick
                      className="page-minus-icon"
                      onClick={() =>
                        handleMovePage("editor", editorIndex, "up")
                      }
                    />
                  )}
                  {editorPages.length - 1 !== editorIndex && (
                    <TiArrowDownThick
                      className="page-minus-icon"
                      onClick={() =>
                        handleMovePage("editor", editorIndex, "down")
                      }
                    />
                  )}
                  <FaRegCopy
                    className="page-minus-icon"
                    onClick={() => handleCopyPage("editor", editorIndex,editor)}
                  />
                </div>
              </div>
            ))}

            <div className="add-page-container">
              <button
                className="add-page-button"
                onClick={() => handleAddPage("editor")}
              >
                Add Page
              </button>
            </div>
          </section>

          {/* quotation table  */}
          <section className="quotation-table">
            <QuotationTable
              areaTables={areaTables}
              setAreaTables={setAreaTables}
              rateTables={rateTables}
              setRateTables={setRateTables}
              subTotalTables={subTotalTables}
              setSubTotalTables={setSubTotalTables}
              editableDescriptions={editableDescriptions}
              background_color={color.background_color}
              table_header={color.table_header}
              table_font={color.table_font}
              table_border={color.table_border}
              table_amount={color.table_amount}
              amount_font={color.amount_font}
              itemAmountRow = {color.itemAmountRow}
              selectedFontFamily={selectedFontFamily}
              isScreenSmall={isScreenSmall}
              isSidebarOpen={isSidebarOpen}
              appliedColumns={appliedColumns}
              rates={rates}
              setRates={setRates}
              finishesData={finishesData}
              setFinishesData={setFinishesData}
              userCategories={userCategories}
            />
          </section>

          {tablePages.map((page, pageIndex) => (
            <div
              key={page.id}
              className="pdf-text-editor"
              onClick={() => handleActivePage(page)}
              spellCheck={false}
            >
              <div
                className="pdf-text-editor-space"
                style={{ backgroundColor: color.background_color }}
              >
                <div className="ql-container ql-snow">
                  {page.textBox &&
                    page.textBox.map((box) => (
                      <div
                        className="draggable-container ql-container ql-snow"
                        onMouseDown={(e) =>
                          !box.isEditable &&
                          handleMouseDown(page.id, box.id, "textBox", e)
                        }
                        onMouseMove={(e) =>
                          !box.isEditable &&
                          handleMouseMove(page.id, box.id, "textBox", e)
                        }
                        onMouseUp={(e) =>
                          !box.isEditable &&
                          handleMouseUp(page.id, box.id, "textBox")
                        }
                        
                        key={box.id}
                        style={{ height: "auto" }}
                         onClick={() => {
                            if (box.id !== activeBox?.id) {  // Check if box.id is not equal to activeBox.id
                              setActiveBox(box);
                            }
                            if (box.id !== keyBordActions.box?.id) {
                              setKeyBoardActions((prev)=>({...prev,box:{...box,name:'textBox'}}))
                            }
                          }}
                      >
                         {!box.isEditable && box.image && <div style={{
                            position:'absolute',
                            left: box.position.x,
                              top: box.position.y,
                              width: box.width ? box.width : "",
                              height: box.height ? box.height : "",
                              marginTop:'-5px',
                              zIndex: `${box.zIndex ? box.zIndex+1 : 2}`,
                              cursor:'grab'

                          }}
                          onDoubleClick={(e) =>{
                            makeBoxEditable(page.id, box.id, "textBox")
                            setTablePages((prevEditors) =>
                              prevEditors.map((ed) =>
                                ed.id === page.id
                                  ? {
                                      ...ed,
                                      textBox: ed.textBox.map((tb) =>
                                        tb.id === box.id
                                          ? { ...tb, isTextareaActive: true }
                                          : tb
                                      ),
                                    }
                                  : ed
                              )
                            )
                          }}
                          
                          >
                           
                          </div>}
                        <div
                          style={{
                            left: box.position.x,
                            top: box.position.y,
                            width: box.width ? box.width : "",
                            height: box.height ? box.height : "",
                            resize: box.isEditable
                              ? box.resize || "none"
                              : "none",
                            border:
                              box.content && box.isTextareaActive
                                ? `2px solid ${
                                    !box.isEditable ? "black" : "#f9912d"
                                  }`
                                : (box.content.trim().length <= 1 &&
                                    !box.image) ||
                                  (box.isTextareaActive && box.image)
                                ? `2px solid ${
                                    !box.isEditable ? "black" : "#f9912d"
                                  }`
                                : "none",
                            padding: "0px",
                            zIndex: `${box.zIndex ? box.zIndex : 1}`,
                            fontSize: box.fontSize
                              ? `${box.fontSize}px`
                              : "13px",
                          }}
                          ref={box.textBoxRef}
                          className={`pdf-text-box ${
                            !box.image && "ql-editor ql-blank"
                          }`}
                          spellinitialpages="false"
                          onDoubleClick={(e) =>
                            makeBoxEditable(page.id, box.id, "textBox")
                          }
                          onMouseMove={(e) => {
                            handleResize(page.id, box.id, "textBox");
                          }}
                          onMouseUp={() =>
                            handleResize(page.id, box.id, "textBox")
                          }
                          onFocus={() =>
                            setTablePages((prevEditors) =>
                              prevEditors.map((ed) =>
                                ed.id === page.id
                                  ? {
                                      ...ed,
                                      textBox: ed.textBox.map((tb) =>
                                        tb.id === box.id
                                          ? { ...tb, isTextareaActive: true }
                                          : tb
                                      ),
                                    }
                                  : ed
                              )
                            )
                          }
                          onInput={() =>
                            handleTextBoxChange(page.id, box.id, "textBox")
                          }
                        ></div>
                        <div
                          id={`textBox-${box.id}`}
                          className="text-box-tools-container"
                          style={{
                            left: box.position.x,
                            top: box.position.y,
                            display: ` ${
                              box.isTextareaActive ? "block" : "none"
                            }`,
                            marginLeft: box.width,
                            backgroundColor: color.background_color,
                            zIndex: `${box.zIndex ? box.zIndex : 1}`,
                            border: "1.5px solid rgb(222, 213, 213)",
                            borderRadius: "0 10px 10px 0px",
                          }}
                        >
                          <div>
                            {!box.image && (
                              <div>
                                <button className="ql-bold"></button>

                                <button className="ql-italic"></button>

                                <button className="ql-underline"></button>
                                <select className="ql-color"></select>

                                <select className="ql-background"></select>

                                <button
                                  className="ql-list"
                                  value="ordered"
                                ></button>

                                <button
                                  className="ql-list"
                                  value="bullet"
                                ></button>

                                <button className="ql-align" value=""></button>

                                <button
                                  className="ql-align"
                                  value="center"
                                ></button>

                                <button
                                  className="ql-align"
                                  value="right"
                                ></button>

                                <MdDelete
                                  className="textbox-delete-icon"
                                  onClick={() =>
                                    handleDeleteTextBox(page.id, box, "textBox")
                                  }
                                />
                                <div className="text-box-font-size">
                                  <HiMinus
                                    className="font-size-icon"
                                    onClick={() =>
                                      handleFontsize(
                                        "table",
                                        page.id,
                                        box.id,
                                        "minus",
                                        "textBox"
                                      )
                                    }
                                  />
                                  <input
                                    type="text"
                                    value={box.fontSize}
                                    placeholder="0"
                                    onInput={(e) =>
                                      handleFontSizeInput(
                                        "table",
                                        page.id,
                                        box.id,
                                        e.target.value.replace(/[^\d]/g, ""),
                                        "textBox"
                                      )
                                    }
                                  />
                                  <FaPlus
                                    className="font-size-icon"
                                    onClick={() =>
                                      handleFontsize(
                                        "table",
                                        page.id,
                                        box.id,
                                        "plus",
                                        "textBox"
                                      )
                                    }
                                  />
                                </div>

                                <select className="ql-font">
                                  <option value="mirza">Mirza</option>
                                  <option value="roboto">Roboto</option>
                                  <option value="serif">Serif</option>
                                </select>
                              </div>
                            )}
                            {box.image && (
                              <div>
                                <FaPlus
                                  className="image-z-index-icon"
                                  onClick={() =>
                                    handleImageZIndex(
                                      "table",
                                      page.id,
                                      box.id,
                                      "plus"
                                    )
                                  }
                                />
                                <HiMinus
                                  className="image-z-index-icon"
                                  onClick={() =>
                                    handleImageZIndex(
                                      "table",
                                      page.id,
                                      box.id,
                                      "minus"
                                    )
                                  }
                                />
                                <MdDelete
                                  className="image-z-index-icon image-textbox-delete-icon"
                                  onClick={() =>
                                    handleDeleteTextBox(page.id, box, "textBox")
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                  {page.projectNameBox &&
                    page.projectNameBox.map((box) => (
                      <div
                        className="draggable-container ql-container ql-snow"
                        onMouseDown={(e) =>
                          !box.isEditable &&
                          handleMouseDown(page.id, box.id, "projectNameBox", e)
                        }
                        onMouseMove={(e) =>
                          !box.isEditable &&
                          handleMouseMove(page.id, box.id, "projectNameBox", e)
                        }
                        onMouseUp={(e) =>
                          !box.isEditable &&
                          handleMouseUp(page.id, box.id, "projectNameBox")
                        }
                       
                        key={box.id}
                        style={{ height: "auto" }}
                        onClick={() => {
                          // First, set the project box
                         if (box.id !== projectBox?.id) {  // Check if box.id is not equal to activeBox.id
                                setProjectBox(box);
                              }

                             if (box.id !== keyBordActions.box?.id) {
                                setKeyBoardActions((prev)=>({...prev,box:{...box,name:'projectNameBox'}}))
                             }

                          // Then update the editor pages
                          setTablePages((prevEditors) =>
                            prevEditors.map((ed) =>
                              ed.id === page.id
                                ? {
                                    ...ed,
                                    projectNameBox: ed.projectNameBox.map(
                                      (tb) =>
                                        tb.id === box.id
                                          ? { ...tb, isTextareaActive: true }
                                          : tb
                                    ),
                                  }
                                : ed
                            )
                          );
                        }}
                      >
                        <div
                          style={{
                            left: box.position.x,
                            top: box.position.y,
                            width: `${
                              box.fontSize * 15 < 700 ? box.fontSize * 15 : 700
                            }px`,
                            height: `${
                              box.fontSize * 2.3 < 95 ? box.fontSize * 2.3 : 95
                            }px`,
                            resize: "none",
                            border:
                              box.content && box.isTextareaActive && !box.image
                                ? `2px solid ${
                                    !box.isEditable ? "black" : "black"
                                  }`
                                : box.content.trim().length <= 1 &&
                                  !box.image &&
                                  `2px solid ${
                                    !box.isEditable ? "black" : "#black"
                                  }`,
                            padding: "0px",
                            paddingTop: "10px",
                            zIndex: `${box.zIndex ? box.zIndex : 1}`,
                            fontSize: box.fontSize
                              ? `${box.fontSize < 50 ? box.fontSize : 50}px`
                              : "13px",
                            caretColor: "transparent", // Hide text cursor
                            userSelect: "none", // Disable text selection
                          }}
                          ref={box.textBoxRef}
                          className={`pdf-text-box ${
                            !box.image && "ql-editor ql-blank"
                          }`}
                          contentEditable={true}
                          spellCheck="false"
                          suppressContentEditableWarning={true}
                          onKeyDown={(e) => {
                            // Prevent all typing and deletion
                            if (
                              e.key !== "ArrowLeft" &&
                              e.key !== "ArrowRight" &&
                              e.key !== "ArrowUp" &&
                              e.key !== "ArrowDown"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onInput={(e) => {
                            // Prevent any changes to the content
                            e.target.innerHTML = box.content;
                          }}
                          onDoubleClick={(e) =>
                            makeBoxEditable(page.id, box.id, "projectNameBox")
                          }
                          onMouseMove={(e) => {
                            
                            handleResize(page.id, box.id, "projectNameBox");
                          }}
                          onMouseUp={() =>
                            handleResize(page.id, box.id, "projectNameBox")
                          }
                        ></div>
                        <div
                          id={`projectNameBox-${box.id}`}
                          className="text-box-tools-container"
                          style={{
                            left: box.position.x,
                            top: box.position.y,
                            display: ` ${
                              box.isTextareaActive ? "block" : "none"
                            }`,
                            marginLeft: box.width,
                            backgroundColor: color.background_color,
                            zIndex: `${box.zIndex ? box.zIndex : 1}`,
                            border: "1.5px solid rgb(222, 213, 213)",
                            borderRadius: "0 10px 10px 0px",
                          }}
                        >
                          <div>
                            {!box.image && (
                              <div>
                                <button className="ql-bold"></button>

                                <button className="ql-italic"></button>

                                <button className="ql-underline"></button>
                                <select className="ql-color"></select>

                                <select className="ql-background"></select>

                                <button
                                  className="ql-list"
                                  value="ordered"
                                ></button>

                                <button
                                  className="ql-list"
                                  value="bullet"
                                ></button>

                                <button className="ql-align" value=""></button>

                                <button
                                  className="ql-align"
                                  value="center"
                                ></button>

                                <button
                                  className="ql-align"
                                  value="right"
                                ></button>

                                <MdDelete
                                  className="textbox-delete-icon"
                                  onClick={() =>
                                    handleDeleteTextBox(
                                      page.id,
                                      box,
                                      "projectNameBox"
                                    )
                                  }
                                />
                                <div className="text-box-font-size">
                                  <HiMinus
                                    className="font-size-icon"
                                    onClick={() =>
                                      handleFontsize(
                                        "table",
                                        page.id,
                                        box.id,
                                        "minus",
                                        "projectNameBox"
                                      )
                                    }
                                  />
                                  <input
                                    type="text"
                                    value={box.fontSize}
                                    placeholder="0"
                                    onInput={(e) =>
                                      handleFontSizeInput(
                                        "table",
                                        page.id,
                                        box.id,
                                        e.target.value.replace(/[^\d]/g, ""),
                                        "projectNameBox"
                                      )
                                    }
                                  />
                                  <FaPlus
                                    className="font-size-icon"
                                    onClick={() =>
                                      handleFontsize(
                                        "table",
                                        page.id,
                                        box.id,
                                        "plus",
                                        "projectNameBox"
                                      )
                                    }
                                  />
                                </div>

                                <select className="ql-font">
                                  <option value="mirza">Mirza</option>
                                  <option value="roboto">Roboto</option>
                                  <option value="serif">Serif</option>
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  <div
                    ref={page.editorRef}
                    className="pdf-text-enter-space ql-editor ql-blank"
                    spellCheck={false}
                  ></div>
                </div>
              </div>
              <div className="page-tool-icons ">
                <HiOutlineMinusSm
                  className="page-minus-icon"
                  onClick={() => handleRemovePage("table", page.id)}
                />
                {pageIndex !== 0 && (
                  <TiArrowUpThick
                    className="page-minus-icon"
                    onClick={() => handleMovePage("table", pageIndex, "up")}
                  />
                )}
                {tablePages.length - 1 !== pageIndex && (
                  <TiArrowDownThick
                    className="page-minus-icon"
                    onClick={() => handleMovePage("table", pageIndex, "down")}
                  />
                )}
                 <FaRegCopy
                    className="page-minus-icon"
                    onClick={() => handleCopyPage("table", pageIndex,page)}
                  
                  />
              </div>
            </div>
          ))}

          <div className="add-page-container quotation-add-page-container">
            <button
              className="add-page-button"
              onClick={() => handleAddPage("table")}
            >
              Add Page
            </button>
          </div>
        </article>

        {/* side bar section  */}
        <aside
          className={`pdf-editor-side-bar-container ${
            isScreenSmall
              ? isSidebarOpen
                ? "sidebar-open"
                : "sidebar-closed"
              : ""
          }`}
          style={{
            transform: isScreenSmall
              ? isSidebarOpen
                ? "translateX(0)"
                : "translateX(94%)"
              : "translateX(0%)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <div className="pdf-editor-side-bar">
            <div className="sidebar-toggle-button" onClick={toggleSidebar}>
              {isSidebarOpen ? <FaArrowRightLong /> : <FaArrowLeftLong />}
            </div>
            <div className="pdf-template-editor-section">
              <p
                className="pdf-editor-finishes-rates"
                onClick={() => {
                  setFinishes(true);
                }}
              >
                Finishes and Rates
              </p>
              <div className="pdf-editor-template-section">
                <div className="pdf-editor-template-header">
                  <p className="pdf-editor-template-title">Template</p>
                  <IoMdRefresh
                    className="pdf-editor-refresh-icon"
                    onClick={tableData}
                  />
                </div>
                <nav className="pdf-editor-template-list">
                  <p
                    className="pdf-editor-template-item"
                    onClick={() => setTemplatePopup("template01")}
                  >
                    T-01
                  </p>
                  <p
                    className="pdf-editor-template-item"
                    onClick={() => setTemplatePopup("template02")}
                  >
                    T-02
                  </p>
                  <p
                    className="pdf-editor-template-item"
                    onClick={() => setTemplatePopup("template03")}
                  >
                    T-03
                  </p>
                  <p
                    className="pdf-editor-template-item"
                    onClick={() => setTemplatePopup("template04")}
                  >
                    T-04
                  </p>
                </nav>
              </div>
            </div>

            <div className="pdf-editor-sidebar-section">
              {/* Text header section */}
              {editorPages.concat(tablePages).map((page) => (
                <div
                  className="pdf-editor-text-header"
                  key={page.id}
                  style={{
                    display: activePage.id === page.id ? "block" : "none",
                  }}
                >
                  <p className="pdf-editor-text-title">Page Editor</p>
                  <div id={`toolbar-${page.id}`}>
                    <div className="color-picker-section">
                      <div className="color-picker-container">
                        <div
                          className="color-picker-icon-section"
                          onClick={() => handleDivClick("background_color")}
                        >
                          <BiSolidColorFill className="color-picker-icon" />
                          <input
                            name="background_color"
                            type="color"
                            value={color.background_color}
                            onChange={handleChange}
                            id="background_color"
                            ref={inputRefs.background_color}
                          />
                        </div>
                        <p className="color-picker-text">Page Background</p>
                      </div>
                    </div>
                    <button className="ql-bold"></button>
                    <button className="ql-italic"></button>
                    <button className="ql-underline"></button>
                    <button className="ql-customTextBox">
                      <ImTextWidth />
                    </button>
                    <button className="ql-customProjectNameBox">
                      <FaProductHunt />
                    </button>
                    <button className="ql-customImage">
                      <FaRegImage />
                    </button>
                    <select className="ql-font" defaultValue="Aref Ruqaa">
                      <option value="Aref Ruqaa">Aref Ruqaa</option>
                      <option value="mirza">Mirza</option>
                      <option value="roboto">Roboto</option>
                      <option value="serif">Serif</option>
                    </select>
                    <select
                      title="Size"
                      className="ql-size"
                      style={{ width: "45px" }}
                    >
                      <option value="10px">10</option>
                      <option value="13px">13</option>
                      <option value="18px">18</option>
                      <option value="24px">24</option>
                      <option value="32px">32</option>
                      <option value="48px">48</option>
                    </select>
                    <button className="ql-list" value="bullet"></button>
                    <select className="ql-color"></select>
                    <select className="ql-background"></select>
                    <button className="ql-align" value=""></button>
                    <button className="ql-align" value="center"></button>
                    <button className="ql-align" value="right"></button>
                    <button className="ql-list" value="ordered"></button>
                    
                    <button className="ql-indent" value="-1"></button>
                    <button className="ql-indent" value="+1"></button>
                    {/* <button className="ql-clean"></button> */}
                  </div>
                </div>
              ))}

              {/* Quotation header section */}
              <div className="pdf-editor-customize-header">
                <p className="pdf-editor-customize-title">Quotation Editor</p>

                <div className="quotation-editor-tools">
                  {/* table header and font color change */}
                  <div className="table-color-picker-section">
                    <div className="table-font-family-section">
                      <select
                        id="font-family"
                        value={selectedFontFamily}
                        onChange={handleFontFamilyChange}
                      >
                        <option value="" disabled>
                          Select font family
                        </option>
                        {fontFamilies.map((fontFamily, index) => (
                          <option key={index} value={fontFamily}>
                            {fontFamily}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* table Border and background color change */}
                  <div className="table-color-picker-section">
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => handleDivClick("table_header")}
                      >
                        <RiFontFamily className="color-picker-icon" />
                        <input
                          name="table_header"
                          type="color"
                          value={color.table_header}
                          onChange={handleChange}
                          id="table_header"
                          ref={inputRefs.table_header}
                        />
                      </div>
                      <p className="color-picker-text">Header</p>
                    </div>
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => handleDivClick("table_font")}
                      >
                        <RiFontFamily className="color-picker-icon" />
                        <input
                          name="table_font"
                          type="color"
                          value={color.table_font}
                          onChange={handleChange}
                          id="table_font"
                          ref={inputRefs.table_font}
                        />
                      </div>
                      <p className="color-picker-text">Font</p>
                    </div>
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => handleDivClick("table_border")}
                      >
                        <BiSolidColorFill className="color-picker-icon" />
                        <input
                          name="table_border"
                          type="color"
                          value={color.table_border}
                          onChange={handleChange}
                          id="table_border"
                          ref={inputRefs.table_border}
                        />
                      </div>
                      <p className="color-picker-text">Border</p>
                    </div>
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => handleDivClick("table_amount")}
                      >
                        <BiSolidColorFill className="color-picker-icon" />
                        <input
                          name="table_amount"
                          type="color"
                          value={color.table_amount}
                          onChange={handleChange}
                          id="table_amount"
                          ref={inputRefs.table_amount}
                        />
                      </div>
                      <p className="color-picker-text">Amount</p>
                    </div>
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => handleDivClick("amount_font")}
                      >
                        <RiFontFamily className="color-picker-icon" />
                        <input
                          name="amount_font"
                          type="color"
                          value={color.amount_font}
                          onChange={handleChange}
                          id="amount_font"
                          ref={inputRefs.amount_font}
                        />
                      </div>
                      <p className="color-picker-text">Amount Font</p>
                    </div>
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => setColor((prev)=>({...prev, itemAmountRow: !prev.itemAmountRow }))}
                       
                      >
                        <MdOutlineTableRows className="color-picker-icon"/>
                        
                      </div>
                      <p className={`color-picker-text ${color.itemAmountRow?'active-item-text':""}`} >Active row</p>
                    </div>
                  
                  </div>
                </div>
              </div>

              <div className="pdf-editor-customize-header">
                <p className="pdf-editor-customize-title">
                  Quotation Selection
                </p>
                <div className="quotation-editor-tools pdf-column-selection-container">
                  <ul className="column-selection-list">
                    {["Height", "Width", "Area", "Unit", "Rate"].map(
                      (column) => (
                        <li
                          key={column}
                          className={`column-selection-item ${
                            Array.from(selectedColumns).some(
                              (col) => col.name === column
                            )
                              ? "selected"
                              : ""
                          } ${
                            Array.from(selectedColumns).some(
                              (col) => col.name === column && col.select
                            )
                              ? "applied"
                              : ""
                          }`}
                          onClick={() => toggleColumn(column)}
                        >
                          {column}
                        </li>
                      )
                    )}
                  </ul>

                  <p
                    className="column-selection-button"
                    onClick={() => {
                      if (selectedColumns.size > 1) {
                        applyColumns();
                      }
                    }}
                  >
                    Apply
                  </p>
                  {error === "max" && (
                    <p style={{ textAlign: "center" }} className="error">
                      Max 3 can be selected.
                    </p>
                  )}
                  {error === "min" && (
                    <p style={{ textAlign: "center" }} className="error">
                      At least 2 must be selected.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* save button  */}
          <div className="pdf-editor-side-bar-button">
            {!updateSave && <button onClick={handleSave}>Save</button>}
            {updateSave === 'process' && <button >Saving</button>}
            {updateSave === 'saved' && <p>Saved Successfully</p>}
            {isWarning && (
              <div className="error" style={{ textAlign: "center" }}>
                Kindly resolve all warnings before proceeding with saving.
              </div>
            )}
          </div>
        </aside>
      </section>
    </div>
  );
}

export default QuotationTemplate;
