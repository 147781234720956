import React from "react";
import { TiLocation } from "react-icons/ti";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdOutlineMail } from "react-icons/md";
import "./contactUs.css";
import AXIOS from "../../services/axios";

function ContactUs() {


  return (
    <article className="contact-container">

      {/* form for user enquiry  */}
      <section className="contact-form">
        <h1 className="contact-title">
          Contact <span>Us :</span>
        </h1>
        <form className="contact-form-fields">
          <div className="contact-form-field">
            <label htmlFor="name">Name</label>
            <input id="name" type="text" placeholder="Enter name" required />
          </div>
          <div className="contact-form-field">
            <label htmlFor="email">Email</label>
            <input id="email" type="text" placeholder="Enter email" required />
          </div>
          <div className="contact-form-field">
            <label htmlFor="enquiry">Enquiry</label>
            <textarea
              id="enquiry"
              type="text"
              placeholder="Type your enquiry"
              required
            />
          </div>
        </form>
        <button className="contact-form-submit">Submit</button>
      </section>

      {/* Company details  */}
      <section className="contact-details">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8381613279116!2d77.6354879735881!3d12.918121216051501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13d7385c5f73%3A0x112720f993d3477!2sThe%20Signature%20Spaces!5e0!3m2!1sen!2sin!4v1715072901835!5m2!1sen!2sin"
          className="company-location-map"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="contact-info">
          <div className="contact-info-item">
            <TiLocation className="contact-icon"/>
            <p>
              Ground Floor, #5 MCHS, 14th Main Rd, Sector 4, HSR Layout,
              Bengaluru, Karnataka 560102
            </p>
          </div>
          <div className="contact-info-item">
            <BiSolidPhoneCall className="contact-icon" />
            <p>8448856696</p>
          </div>
          <div className="contact-info-item">
            <MdOutlineMail className="contact-icon" />
            <p>enquiry@interioverse.com</p>
          </div>
        </div>
      </section>
    </article>
  );
}

export default ContactUs;
