import Quill from 'quill';

class CustomImage {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;

    // Add handler to toolbar
    const toolbar = this.quill.getModule('toolbar');
    if (toolbar) {
      toolbar.addHandler('customImage', this.showImage.bind(this));
    }
  }

  showImage() {
    const selection = this.quill.getSelection();
    if (selection) {
      const range = this.quill.getSelection(true);
      this.options.showImage(range);
    }
  }
}

Quill.register('modules/customImage', CustomImage);

export default CustomImage;
